import PropTypes from 'prop-types';
import React from 'react';
import { Link } from "react-router-dom";

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  useMediaQuery,
  Box,
  // Menu,
  // MenuItem,
  // Typography,
  Button
} from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
// import HeaderContent from './HeaderContent';
import IconButton from '../../components/@extended/IconButton';

// assets
import { SettingOutlined } from '@ant-design/icons';


// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {
  // const [anchorElNav, setAnchorElNav] = React.useState(null);

  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  // header content
  // const headerContent = useMemo(() => <HeaderContent />, []);

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';


  // common header
  const mainHeader = (
    <Toolbar
      id='HeaderToolbar'
      sx={{
        paddingTop: '0px',
        paddingBottom: '0px',
        minHeight: '50px',
        // width: 1
      }}
    >
      <Link
        to="/settings"
        style={{ textDecoration: 'none' }}
      >
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          color="secondary"
          variant="light"
          sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
        >
          <SettingOutlined />
        </IconButton>
      </Link>
      {
        // headerContent
      }
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingLeft: '20px'
      // boxShadow: theme.customShadows.z1
    }
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          <Box id='AppBarStyledBox' sx={{ flex: 1, display: { xs: 'none', md: 'flex' } }}>

            <Box sx={{ flexGrow: 1 }}>
            <Link
              to="/"
              style={{ textDecoration: 'none' }}
            >
              <Button
                key={'home'}
                // onClick={handleCloseNavMenu}
                sx={{ my: 1, color: 'black', display: 'block' }}
              >
                Keywords
              </Button>
            </Link>

            </Box>

            {
              mainHeader
            }

          </Box>

        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func
};

export default Header;
