import PropTypes from 'prop-types';
import React from 'react';


// material-ui
import {
  useTheme
} from '@mui/material/styles';
import {
  Box,
  Stack,
} from '@mui/material';

// assets
import {
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';

// ==============================|| HEADER SORT ||============================== //

export const HeaderSort = ({ column, sort }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ display: 'inline-flex' }}>
      <Box>{column.render('Header')}</Box>
      {!column.disableSortBy && (
        <Stack sx={{ color: 'secondary.light' }} {...(sort && { ...column.getHeaderProps(column.getSortByToggleProps()) })}>
          <CaretUpOutlined
            style={{
              fontSize: '0.625rem',
              color: column.isSorted && !column.isSortedDesc ? theme.palette.text.secondary : 'inherit'
            }}
          />
          <CaretDownOutlined
            style={{
              fontSize: '0.625rem',
              marginTop: -2,
              color: column.isSortedDesc ? theme.palette.text.secondary : 'inherit'
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

HeaderSort.propTypes = {
  column: PropTypes.any,
  sort: PropTypes.bool
};


