import React, {
  useState,
  useEffect,
  // useCallback
  useContext
} from 'react'
import PropTypes from 'prop-types';

import {
  // getAuth,
  //signInWithEmailAndPassword,
  // signOut
} from 'firebase/auth';
// import { useAuthState } from 'react-firebase-hooks/auth';
import { AdminAuthContext } from '../helpers/AdminAuthProvider';

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebase } from '../code/firebase';
import { processKeywordsUserData } from '../code/ecomacy';

import _ from 'lodash';

import {
  useTheme,
  styled,
} from '@mui/material/styles';

import {
  Box,
  Button,
  CircularProgress,
  Tab,
  Tabs,
  Typography
  //Tab,
  // Tabs,
  // Grid
} from '@mui/material';

import Container from '@mui/material/Container';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';

import { SettingOutlined, FileImageOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';

import AdminCustomersTab from '../components/AdminCustomersTab';
import AdminPlansSetupTab from '../components/AdminPlansSetupTab';

// import GoogleMapPane from './GoogleMapPane'
import LoadingPane from './LoadingPane'



function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


const AdminPane = (props) => {
  const theme = useTheme();

  const { adminUserData, setAdminUserData } = useContext(AdminAuthContext);
  const { customersData, setCustomersData } = useContext(AdminAuthContext);
  // const { customersEcData, setCustomersEcData } = useContext(AdminAuthContext);
  // const { customersDataSynced, setCustomersDataSynced } = useContext(AdminAuthContext);

  // const { customersDataOrigObj } = useContext(AdminAuthContext);
  const { customersDataOrig, setCustomersDataOrig } = useContext(AdminAuthContext);
  const { saveAdminCustomer } = useContext(AdminAuthContext);

  const [tabValue, setTabValue] = useState(1);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


  /*
    useEffect(() => {
      if (!_.isEmpty(keywordsDocData)) {
        fetchLocationData()
          // make sure to catch any error
          .catch(console.error);
      }

    }, [keywordsDocData])


    const fetchLocationData = useCallback(async () => {

      if (ecomacyUserData.loaded === false) {
        // ecomacy API location data
        // const returnData = await fetch('https://yourapi.com');
        const result = await getEcomacyLocationData(userData)

        if (result.status === 200) {
          ecomacyUserDataObj = {
            data: result.data,
            error: {},
            status: result.status,
            loaded: true
          }
          setEcomacyUserData(ecomacyUserDataObj)
        }
        else {
          console.log('Error: ', result.error)
          ecomacyUserDataObj = {
            data: {},
            error: result.error,
            status: result.status,
            loaded: true
          }
          setEcomacyUserData(ecomacyUserDataObj)
        }

      }
    }, [])


    useEffect(() => {
      if (ecomacyUserData.loaded === true) {
        // compare keywordsDocData with ecomacyUserData to make sure address matches etc
        const result = compareKeywordsEcomacyData(ecomacyUserData, keywordsDocData)

        // temp data
        // result.match = false

        setDataMatch(result)
        setDataMatchProcessed(true)
      }

    }, [ecomacyUserData])
  */


  //  if (error) {
  //    return (
  //      <LoadingPane
  //        text={'Error: ' + JSON.stringify(error)}
  //        showSpinner={false}
  //      />
  //    )
  //  }
  //  if (loading) {
  //    return (
  //      <LoadingPane
  //        text={'Loading Keywords...'}
  //        showSpinner={true}
  //        progress={50}
  //
  //      />
  //    )
  //  }





  function preventDefault(event) {
    event.preventDefault();
  }


  /*
  const handleEnabledSwitch = (item, index) => {

    let fullData = customersData.data
    console.log('fullData[index] before', fullData[index])

    // update item
    fullData[index].enabled = !item.enabled
    console.log('fullData[index] after', fullData[index])
    setCustomersData({
      ...customersData,
      data: fullData
    })

  }
*/

  const handleEnabledSwitch = (item, itemId) => {

    // let fullData = customersData.data
    // console.log('fullData[index] before', fullData[index])


    // let dataItem = _.cloneDeep(customersDataOrig[itemId])
    let dataItem = customersDataOrig[itemId]

    // update item
    dataItem.enabled = !item.enabled

    // console.log('fullData[index] after', fullData[index])

    setCustomersDataOrig({
      ...customersDataOrig,
      [itemId]: dataItem
    })

  }


  const getMembershipVal = (row) => {

    let returnVal = ''
    let membershipMonthly = null
    if (row && row.data && row.data.monthly) {
      membershipMonthly = row.data.monthly
    }

    let memberships = []
    if (adminUserData && adminUserData.userDoc && adminUserData.userDoc.memberships) {
      memberships = adminUserData.userDoc.memberships
    }

    if (memberships.length > 0 && membershipMonthly) {
      // find in array where membershipMonthly === monthly
      var found = _.findIndex(memberships, { monthly: membershipMonthly });
      if (found > -1) {
        returnVal = found
      }
    }

    return returnVal;
  }

  const handleOnChangeMembership = (event, item, itemId) => {

    console.log('event:', event.target.value)
    console.log('item:', item)
    console.log('index:', itemId)
    // let fullData = customersData.data

    let memberships = []
    if (adminUserData && adminUserData.userDoc && adminUserData.userDoc.memberships) {
      memberships = adminUserData.userDoc.memberships
    }

    // update item
    const val = event.target.value

    let selectedMembership = null
    if (memberships && memberships[val]) {
      selectedMembership = memberships[val]
    }

    // update item
    // let dataItem = _.cloneDeep(customersDataOrig[itemId])
    let dataItem = customersDataOrig[itemId]


    // init data if it doesn't exist
    if (!dataItem.data) {
      dataItem.data = {}
    }

    // set val
    dataItem.data = {
      ...dataItem.data,
      ...selectedMembership
    }

    setCustomersDataOrig({
      ...customersDataOrig,
      [itemId]: dataItem
    })

  }

  const handleOnChangeMultiple = (event, item, itemId) => {

    console.log('event:', event.target.value)
    console.log('item:', item)
    console.log('index:', itemId)
    // let fullData = customersData.data

    // update item
    const val = event.target.value

    // update item
    // let dataItem = _.cloneDeep(customersDataOrig[itemId])
    let dataItem = customersDataOrig[itemId]


    // init data if it doesn't exist
    if (!dataItem.data) {
      dataItem.data = {}
    }

    // set val
    dataItem.data.multiple = event.target.value

    // setCustomersData({
    //   ...customersData,
    //   data: fullData
    // })

    setCustomersDataOrig({
      ...customersDataOrig,
      [itemId]: dataItem
    })

  }

  /*
    const handleOnChangeBusinessName = (event, item, index) => {

      console.log('event:', event.target.value)
      console.log('item:', item)
      console.log('index:', index)

      const val = event.target.value
      let fullData = _.cloneDeep(customersData.data)

      // update item
      let dataItem = fullData[index]

      // init data if it doesn't exist
      // if (!dataItem.data) {
      //   dataItem.data = {}
      // }

      // set val
      dataItem.businessname = val

      setCustomersData({
        ...customersData,
        data: fullData
      })

    }
  */

  const handleOnChangeBusinessName = (event, item, itemId) => {

    console.log('event:', event.target.value)
    console.log('item:', item)
    console.log('index:', itemId)

    const val = event.target.value
    // let fullData = _.cloneDeep(customersData.data)

    // update item
    // let dataItem = _.cloneDeep(customersDataOrig[itemId])
    let dataItem = customersDataOrig[itemId]

    // set val
    dataItem.businessname = val

    setCustomersDataOrig({
      ...customersDataOrig,
      [itemId]: dataItem
    })

  }

  const SaveCancelButtons = (saveCancelButtonProps) => {

    const { row, index, itemId } = saveCancelButtonProps

    // console.log('itemId:', itemId)

    const [saving, setSaving] = useState(false)

    const handleSave = async () => {

      try {
        setSaving(true)

        const res = await saveAdminCustomer(row)
        console.log('save index:', index)
        console.log('save row:', row)
        // update customersDataOrig.current[i] to customersData.data[i]

        setSaving(false)

      } catch (err) {
        console.error(err);
        setSaving(false)
        return {
          error: err
        }

      }

    }




    const handleShowRef = () => {
      console.log('showref itemId:', itemId)

      /*
            let fullData = customersData.data
            const fullDataItem = fullData[index]
            const fullDataItemId = fullDataItem.id
            // update item
            // let dataItem = fullData[index]

            // replace item

            // const test = fullData[index]
            // const test2 = customersDataOrig[index]

            // set customersData.data[i] to customersDataOrig[id]

            if (fullDataItemId && fullDataItemId !== '') {
              if (customersDataOrig[fullDataItemId]) {
                // fullData[index] = customersDataOrig[fullDataItemId]
                // debugger
                console.log('--------------------------------')
                console.log('XXX fullData:', customersData)
                console.log('XXX fullData[index]:', fullData[index])
                console.log('XXX customersDataOrig[fullDataItemId]:', customersDataOrig[fullDataItemId])
                console.log('--------------------------------')
              }
            }
      */


      if (customersData && customersData.data) {
        // look for itemId in customersData.data array
        const customersDataItem = _.find(customersData.data, { id: itemId });
        console.log('YYY customersData.data:', customersData.data)
        console.log('YYY customersDataItem:', customersDataItem)
        console.log('YYY customersDataOrig[itemId]:', customersDataOrig[itemId])

      }
    }

    /*
        const handleCancel = () => {
          console.log('cancel index:', index)

          let fullData = _.cloneDeep(customersData.data)
          const fullDataItem = fullData[index]
          const fullDataItemId = fullDataItem.id
          // update item
          // let dataItem = fullData[index]

          // replace item

          // const test = fullData[index]
          // const test2 = customersDataOrig[index]

          // set customersData.data[i] to customersDataOrig[id]

          if (fullDataItemId && fullDataItemId !== '') {
            if (customersDataOrig[fullDataItemId]) {
              const clone = _.cloneDeep(customersDataOrig[fullDataItemId])
              fullData[index] = clone
              // debugger
              console.log('--------------------------------')
              console.log('XXX customersDataOrig[fullDataItemId]:', customersDataOrig[fullDataItemId])
              console.log('XXX fullData[index]:', fullData[index])
              console.log('--------------------------------')
            }
          }

          setCustomersData({
            ...customersData,
            data: fullData
          })

        }
    */
    const handleCancel = () => {
      console.log('cancel index:', index)

      let fullData = _.cloneDeep(customersData.data)
      const fullDataItem = fullData[index]
      const fullDataItemId = fullDataItem.id
      // update item
      // let dataItem = fullData[index]

      // replace item

      // const test = fullData[index]
      // const test2 = customersDataOrig[index]

      // set customersData.data[i] to customersDataOrig[id]

      //      if (fullDataItemId && fullDataItemId !== '') {
      //        if (customersDataOrig[fullDataItemId]) {
      //          const clone = _.cloneDeep(customersDataOrig[fullDataItemId])
      //          fullData[index] = clone
      //          // debugger
      //          console.log('--------------------------------')
      //          console.log('XXX customersDataOrig[fullDataItemId]:', customersDataOrig[fullDataItemId])
      //          console.log('XXX fullData[index]:', fullData[index])
      //          console.log('--------------------------------')
      //        }
      //      }
      //
      //      setCustomersData({
      //        ...customersData,
      //        data: fullData
      //      })

      const rowId = row.id
      // if (index === 0) {
      //   debugger
      // }

      // find item in customersData
      if (customersData && customersData.data) {
        // look for itemId in customersData.data array
        const customersDataItem = _.find(customersData.data, { id: itemId });
        if (customersDataItem) {
          // clone item
          const cloneItem = _.cloneDeep(customersDataItem)
          // set state
          setCustomersDataOrig({
            ...customersDataOrig,
            [itemId]: cloneItem
          })
          // const isEqual = _.isEqual(row, customersDataItem)
          // disabled = isEqual
        }
      }

    }


    const isDisabled = () => {

      let disabled = true
      const test = row
      const test1 = customersDataOrig[itemId]

      const rowId = row.id
      // if (index === 0) {
      //   debugger
      // }

      // find item in customersData
      if (customersData && customersData.data) {
        // look for itemId in customersData.data array
        const customersDataItem = _.find(customersData.data, { id: itemId });
        if (customersDataItem) {
          const isEqual = _.isEqual(row, customersDataItem)
          disabled = isEqual
        }
      }

      if (saving) {
        disabled = true
      }

      return disabled

    }


    return (
      <Box
        sx={{
          display: 'flex',
          // flexDirection: 'row',
          justifyContent: 'flex-end',
          // alignItems: 'center',
        }}
      >
        {
          //          <Button
          //            // variant="contained"
          //            variant="outlined"
          //            color="secondary"
          //            size="small"
          //            onClick={handleShowRef}
          //            sx={{ mr: 2 }}
          //          >
          //            Show ref
          //          </Button>
        }



        <Button
          // variant="contained"
          variant="outlined"
          color="secondary"
          size="small"
          onClick={handleCancel}
          disabled={isDisabled()}
          sx={{ mr: 2 }}
        >
          Cancel
        </Button>



        <Box sx={{ position: 'relative' }}>
          <Button
            variant="contained"
            // sx={buttonSx}
            // disabled={loading}
            size="small"
            onClick={handleSave}
            disabled={isDisabled()}
          >
            Save
          </Button>
          {saving && (
            <CircularProgress
              size={20}
              sx={{
                // color: green[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-10px',
                marginLeft: '-10px',
              }}
            />
          )}
        </Box>

        {
          //  <Button
          //    variant="contained"
          //    // variant="outlined"
          //    color="primary"
          //    size="small"
          //    onClick={handleSave}
          //    disabled={isDisabled()}
          //  >
          //    Save
          //  </Button>
        }


      </Box >
    )

    //    let fullData = customersData.data
    //
    //    // update item
    //    fullData[index].enabled = !item.enabled
    //
    //    setCustomersData({
    //      ...customersData,
    //      data: fullData
    //    })

  }

  console.log('customersData.data:', customersData.data);
  console.log('customersDataOrig:', customersDataOrig);

  return (

    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2,
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#fff',
          border: `1px solid ${theme.palette.divider}`,
          paddingTop: 1,
          paddingBottom: 4,
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} width={'100%'}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" variant="fullWidth">
            <Tab label="Customers" icon={<UserOutlined />} iconPosition="start" {...a11yProps(0)} />
            <Tab label="Settings" icon={<SettingOutlined />} iconPosition="start" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}
          sx={{
            '& .MuiBox-root': { width: '100%', padding: 0 }
          }}
        >
          <AdminCustomersTab />
        </TabPanel>
        <TabPanel value={tabValue} index={1}
          sx={{
            width: '500px !important',
            'root': { width: '500px !important', padding: 0 }
          }}
        >
          <AdminPlansSetupTab />
        </TabPanel>

        {
          // <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
          //   See more orders
          // </Link>
        }

      </Box>
    </Container>
  );

};


export default React.memo(AdminPane)