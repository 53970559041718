import React, {
  // useState,
  // useEffect
} from 'react'
// import PropTypes from 'prop-types';

// import { useTheme } from '@mui/material/styles';
import {
  Container,
  Box,
  CircularProgress,
  Button
  // Tab,
  // Tabs,
  // Grid
} from '@mui/material';
// import Container from '@mui/material/Container';

import { Link } from "react-router-dom";


const LoadingPane = (props) => {

  const { type } = props

  //   let text = ''
  //   if (props.text) {
  //     text = props.text
  //   }



  let showSpinner = true
  if (props.showSpinner === true || props.showSpinner === false) {
    showSpinner = props.showSpinner
  }

  let progress = 100
  if (props.progress) {
    progress = props.progress
  }


  const typeRender = () => {

    if ('noKeywords' === type) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >

          <Box>
            No Keywords added. Please complete the setup process.
          </Box>
          <Box

          >

            <Link
              to="/settings"
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="contained"
                // startIcon={<CenterFocusStrongIcon />}
                // sx={recenterButtonSx}
                sx={{
                  mt: 2,
                  //   // ml: 1,
                  //   height: '40px',
                  //   fontWeight: 700
                }}
              // onClick={recenterMapOnClick}
              >
                Go to Settings
              </Button>
            </Link>
          </Box>

        </Box>

      )
    }

  }

  // const theme = useTheme();

  return (
    <Container maxWidth="lg"
      sx={{
        minHeight: `calc(100vh - 53px)`,
        height: `calc(100vh - 53px)`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >

        {showSpinner ?
          <CircularProgress variant="determinate" value={progress} />
          :
          <></>
        }


        <Box
          sx={{
            mt: 1,
            fontSize: '20px',
            fontWeight: 700
          }}
        >
          {typeRender()}
        </Box>
      </Box>

    </Container>
  )

};


export default React.memo(LoadingPane)