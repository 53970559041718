import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react'
import PropTypes from 'prop-types';

import {
  // getAuth,
  //signInWithEmailAndPassword,
  // signOut
} from 'firebase/auth';
// import { useAuthState } from 'react-firebase-hooks/auth';

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebase } from '../code/firebase';
import { processKeywordsUserData } from '../code/ecomacy';

import { AiAuthContext } from '../helpers/AiAuthProvider';

import _ from 'lodash';

import {
  useTheme,
  // styled
} from '@mui/material/styles';

import {
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
  IconButton,
  Grid,
  Paper,
} from '@mui/material';

import TextField from '@mui/material/TextField';

import SendIcon from '@mui/icons-material/Send';
import RefreshIcon from '@mui/icons-material/Refresh';
import SyncIcon from '@mui/icons-material/Sync';

import Container from '@mui/material/Container';
import GridKeywordTimelinePane from './GridKeywordTimelinePane'
import LoadingPane from './LoadingPane'

// import MainCard from './MainCard';
// import { getDfsLocationData } from '../code/ecomacy'



// import './GridKeywordsPane.css'
// const auth = getAuth(firebase)

const AiChatPanel = (props) => {
  const { userData } = props
  const theme = useTheme();

  const { testVal, setTestVal } = useContext(AiAuthContext);


  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);

  const { aiChatInput, setAiChatInput } = useContext(AiAuthContext);

  const { postAiMessage } = useContext(AiAuthContext);

  // const { aiChatMessagesData, setAiChatMessagesData } = useContext(AiAuthContext);

  const { aiConversationsSelected, setAiConversationsSelected } = useContext(AiAuthContext);
  const { aiConversation, setAiConversation } = useContext(AiAuthContext);
  const { aiConversationMessageCount } = useContext(AiAuthContext);
  const { getAiConversation } = useContext(AiAuthContext);
  const { duplicateAiConversation } = useContext(AiAuthContext);
  const { aiConversationDuplicatedRefresh, setAiConversationDuplicatedRefresh } = useContext(AiAuthContext);

  const [init, setInit] = useState(true)

  const messageCount = useRef(0);
  // console.log('AiChatPanel aiSettingsData', aiSettingsData);

  // initial load
  useEffect(() => {
    if (
      init &&
      aiConversationsSelected
    ) {
      // debugger
      fetchAiConversationData()
        // make sure to catch any error
        .catch(console.error);
      setInit(false)
    }
  }, [init, aiConversationsSelected])

  // useEffect(() => {
  //   if (!init) {
  //     fetchAiConversationData()
  //       // make sure to catch any error
  //       .catch(console.error);
  //   }
  // }, [aiSettingsData])

  // aiConversationsSelected is changed
  useEffect(() => {
    if (
      !init &&
      aiConversationsSelected
    ) {
      // debugger
      fetchAiConversationData()
        // make sure to catch any error
        .catch(console.error);
      setInit(false)
    }
  }, [aiConversationsSelected])

  const fetchAiConversationData = useCallback(async () => {
    // BT need conversation id
    const test = aiConversationsSelected
    const result = await getAiConversation(userData, aiConversationsSelected)
    // debugger
    if (result && result.data) {
      //   setAiConversation(result.data)
      if (result.data.messages && result.data.messages.length > 0) {
        // setAiChatMessagesData(result.data.messages)
        messageCount.current = result.data.messages.length;
      }
    }
  }, [getAiConversation])


  // Option 1
  // const wrapper = useRef(document.createElement('div'));
  // const wrapper = useRef(document.getElementById("chatMessagesScrollWrapper"));
  // const el = wrapper.current;
  // const scrollToBottom = useCallback(() => {
  //   el.scrollIntoView(false);
  // }, [el]);
  const scrollToBottom = () => {
    var element = document.getElementById("chatMessagesScrollWrapper");
    element.scrollTop = element.scrollHeight;
  }

  useEffect(() => {
    if (aiConversation && aiConversation.messages && aiConversation.messages.length !== messageCount) {
      // update message count ref
      messageCount.current = aiConversation.messages.length;
      // scroll to bottom
      scrollToBottom();
    }
  }, [aiConversation, scrollToBottom]);


  const handleOnChangeChatInput = (e) => {
    // console.log('handleOnChangeChatInput:', e.target.value);
    setAiChatInput(e.target.value);
  }

  const handleOnSubmitSend = async () => {
    // call api
    setAiChatInput('');

    // "message": {
    //   "role": "assistant",
    //   "content": "\n\nHello there, how may I assist you today?"
    // },

    const userMessageData = {
      message: {
        role: 'user',
        content: aiChatInput,
      }
    }

    // let newMessageData = [...aiChatMessagesData];
    // newMessageData.push(userMessageData);
    // setAiChatMessagesData(newMessageData);

    // let newMessageData = [];
    // if (aiConversation && aiConversation.messages && aiConversation.messages.length > 0) {
    //   newMessageData = [...aiConversation.messages];
    // }

    // SF
    const result = await postAiMessage(userMessageData)

    //    if (result && result.data && result.data.message) {
    //      let incomingMessage = {
    //        message: result.data.message
    //      }
    //
    //      const newMessageIncomingData = [...newMessageData];
    //      newMessageIncomingData.push(incomingMessage);
    //      setAiChatMessagesData(newMessageIncomingData);
    //    }


    // SF: messages stuff
    console.log('handleOnSubmitSend:', userMessageData);
  }

  const handleOnClickReset = async () => {
    console.log('handleOnClickReset');
    // const result = await duplicateAiSettings()
    const result = await duplicateAiConversation()

    if (result && result.data && result.data.aiconversationid && result.data.aiconversationid !== '') {
      const val = result.data.aiconversationid

      // trigger useEffect in AiHistoryPanel to activate latest conversation
      setAiConversationDuplicatedRefresh(true)
    }

  }

  const isSendDisabled = () => {
    let disabled = true;
    if (aiChatInput && aiChatInput !== '') {
      disabled = false;
    }
    return disabled
  }
  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // }));

  // console.log('AiChatPanel aiChatMessagesData', aiChatMessagesData);

  if (aiConversation) {
    // console.log('AiChatPanel aiConversation', aiConversation);

    return (
      <Box
        name={'chatPanel'}
        height={'100%'}
        // bgcolor="#cc0000"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // overflow: 'hidden',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'grey.300',
          borderRadius: '4px',
          overflow: 'hidden',
        }}

      >

        <Box
          name={'chatMessages'}
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
            height: '100%',
          }}
        >

          {
            // <Typography>Ai Chat Panel</Typography>
          }


          <Box
            // component={wrapper}
            // ref={wrapper} // Option 1: use ref to scroll to bottom
            id={'chatMessagesScrollWrapper'}
            sx={{
              maxHeight: '100%',
              // flexGrow: 1,
              overflow: 'auto',
              // display: 'flex', // Option 2: reverse column so that the last message is at the top
              // flexDirection: "column-reverse" // Option 2: would need to reverse the order of the messages
            }}
          >

            <Grid container
              // component={wrapper}
              // ref={wrapper} // Option 1: use ref to scroll to bottom
              sx={{
                // maxHeight: '100%',
                // flexGrow: 1,
                //  overflow: 'auto',
                // display: 'flex', // Option 2: reverse column so that the last message is at the top
                // flexDirection: "column-reverse" // Option 2: would need to reverse the order of the messages
              }}
            >
              {
                // aiChatMessagesData.map((m, i) => {
              }
              {aiConversation && aiConversation.messages && aiConversation.messages.map((m, i) => {
                // const test = m
                return (
                  <Grid item xs={12} key={i}>
                    <Box
                      // key={"message" + i}
                      sx={{
                        px: 2,
                        py: 2,
                        backgroundColor: m.role === 'assistant' ? '#efefef' : '#fff',
                      }}
                    >
                      {
                        <Typography
                          sx={{ fontSize: '15px' }}
                          key={"m" + i}
                          value={i}
                        >
                          {m.content}
                        </Typography>
                      }
                    </Box>
                  </Grid>
                )
              }
              )}

            </Grid>
          </Box>

        </Box>


        <Box
          name={'chatInput'}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            p: 4,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >

          <Box
            sx={{
              flexGrow: 1,
              // display: 'flex',
              // flexDirection: 'column',
              // p: 4
              pr: 2
            }}
          >
            <TextField
              // margin="normal"
              // required
              fullWidth
              // id="email"
              // label="Business Name"
              name="chatipnput"
              // autoComplete="businessname"
              // autoFocus
              value={aiChatInput}
              // onChange={(text) => handleOnChangeChatInput(text, row, itemId)}
              onChange={handleOnChangeChatInput}
              onKeyPress={(event) => {
                if (event.key === 'Enter')
                  // console.log('Enter Pressed')
                  handleOnSubmitSend()
              }}

            />
          </Box>

          <Box
            sx={{
              // flex: 1,
            }}
          >
            <IconButton
              aria-label="Send"
              color="primary"
              variant="light"
              onClick={handleOnSubmitSend}
              disabled={isSendDisabled()}
            // sx={{
            //   color: theme.palette.primary.contrastText,
            //   bgcolor: theme.palette.primary.main,
            //   "&:hover": {
            //     color: theme.palette.primary.contrastText,
            //     bgcolor: theme.palette.primary.dark,
            //   },
            // }}
            >
              <SendIcon />
            </IconButton>

          </Box>
          <Box
            sx={{
              ml: 2,
              // flex: 1,

            }}
          >
            <IconButton
              aria-label="Send"
              color="primary"
              variant="light"
              onClick={handleOnClickReset}
            // disabled={isSendDisabled()}
            >
              <SyncIcon />
            </IconButton>

          </Box>


        </Box >
      </Box>

    );
  }


  if (init) {
    return <LoadingPane />
  }

  if (!init) {
    return (
      <Box>
        <Typography>Select a conversation</Typography>
      </Box>
    )
  }


};


export default React.memo(AiChatPanel)