import React, { createContext, useState, useRef } from 'react';
// import auth from '@react-native-firebase/auth';
import { getUserToken } from '../code/firebase'

import {
  getAuth,
  connectAuthEmulator,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut
} from "firebase/auth"

import {
  // collection,
  doc,
  setDoc,
  onSnapshot,
  // getDoc,
  getDocs,
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  limit,
  addDoc,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore'

import _ from 'lodash';

import { getFunctions, httpsCallable } from "firebase/functions"
import { useAuthState } from 'react-firebase-hooks/auth';

import { getUrlParams } from '../code/ecomacy'
import { firebase } from '../code/firebase';
import { sort } from 'mathjs';
// import { Timestamp } from '@google-cloud/firestore';

const axios = require('axios')

const userData = getUrlParams()

let location_id = ''
if (userData.location_id !== '') {
  location_id = userData.location_id
}
let email = ''
if (userData.email !== '') {
  email = userData.email
}

const auth = getAuth(firebase)
const functions = getFunctions(firebase, 'europe-west1')
const db = getFirestore(firebase)

/**
 * This provider is created
 * to access user in admin area
 */

const initialState = {
  // login: () => {},
  // logout: () => {},
  // authenticate: () => {},
  // getUserToken: () => {},
};


export const AiAuthContext = createContext(initialState);
// export const AuthContext = createContext({} as ContextState);
// const Context = createContext({} as ContextState);

export const AiAuthProvider = ({ children }) => {
  // const [user, setUser] = useState(null);
  const [testVal, setTestVal] = useState('');

  const [aiSettingsDocs, setAiSettingsDocs] = useState(null);

  const [aiSettingsSelected, setAiSettingsSelected] = useState(null);
  const [aiSettingsData, setAiSettingsData] = useState(null);
  const aiSettingsDataRef = useRef(null);
  const [aiSettingsDataUpdated, setAiSettingsDataUpdated] = useState(null);

  // const [aiChatMessagesData, setAiChatMessagesData] = useState([]);
  const [aiStrategySelected, setAiStrategySelected] = useState(null);
  const [aiStrategySelectedRefresh, setAiStrategySelectedRefresh] = useState(false);

  const [aiModelSelected, setAiModelSelected] = useState(null);
  const [aiModelSelectedRefresh, setAiModelSelectedRefresh] = useState(false);
  // const [aiModel, setAiModel] = useState({})

  const [aiConversationsDocs, setAiConversationsDocs] = useState(null);
  const [aiConversationsDocsRefresh, setAiConversationsDocsRefresh] = useState(false);

  const [aiConversationsSelected, setAiConversationsSelected] = useState(null);
  const [aiConversation, setAiConversation] = useState(null);
  const aiConversationMessageCount = useRef(0);
  const [aiChatInput, setAiChatInput] = useState('');

  const [aiConversationDuplicatedRefresh, setAiConversationDuplicatedRefresh] = useState(false);

  const [aiShowDescriptionPanel, setAiShowDescriptionPanel] = useState(false);


  const [user, loadingUser, errorUser] = useAuthState(auth);

  /*
  const [customerUserData, setAiUserData] = useState({
    data: null,
    error: null,
    loaded: false,
  });

  const [customersData, setAisData] = useState({
    data: null,
    // dataOrig: null,
    error: null,
    loaded: false,
    // synced: false
  });

  const [user, loadingUser, errorUser] = useAuthState(auth);

  const [rawData, setRawData] = useState({
    customerData: null,
    accountStartTs: null,
    billingStartTs: null,
    tokensIncluded: null,
    tokensExtra: null,
    monthly: null,
    multiple: null,
    tokenPrice: null,
    runningTotal: null,
    tokensLeftThisMonth: null,
    allCustomerData: false,

    saveKeywords: null,
    keywordsCount: null,
    schedule: null,
    scheduleDay: null,
    scheduleHour: null,
    scheduleInterval: null,
    allKeywordsData: false,

    // saveGridTemplates: null,
    gridCount: null,
    allGridData: false
  })

  const rawDataRef = useRef({});

  const [tokenData, setTokenData] = useState({
    iterationsMonthly: null,
    tokensLeftThisMonth: null,
    tokensNeededSingle: null,
    totalSingle: null,
    tokensNeededMonthly: null,
    totalMonthly: null,
    tokensLeftAfterDeduction: null,
  })
*/

  const getAiUserDoc = async (uid) => {
    try {
      let customerDoc = null
      let customerLogSearchDoc = null

      const qc = query(collection(db, "customers"), where("locationid", "==", uid));

      const snapshotCustomer = await getDocs(qc);

      if (snapshotCustomer.empty) {
        const err = {
          val: 'no User Doc found'
        }
        return {
          error: err
        }
      }

      snapshotCustomer.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        customerDoc = doc.data()
      });

      if (customerDoc) {
        if (!customerDoc.data) {
          customerDoc.data = {}
        }
        const qls = query(collection(
          db, "logsearches"),
          where("eclocationid", "==", uid),
          orderBy("created", "desc"),
          limit(1)
        );

        const snapshotLogSearch = await getDocs(qls);

        // if (snapshotLogSearch.empty) {
        // }

        snapshotLogSearch.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          customerLogSearchDoc = doc.data();
        });
      }

      if (customerLogSearchDoc && customerLogSearchDoc.runningtotal) {
        customerDoc.data.runningtotal = customerLogSearchDoc.runningtotal
      }

      return customerDoc

    } catch (err) {
      console.error(err);
      return {
        error: err
      }

    }
  }



  const postEcomacyAiMessageFromApi = async data => {
    let returnData = {
      status: 0,
      data: {},
      error: {},
    };
    try {
      //const jwt = data.jwt;
      let payload = data.payload;

      // temp val
      payload.locationid = 'yEz3dNwupKApQykDTKPU'

      let URL = 'https://api.ecomacy.com'
      // console.log('process.env.REACT_APP_ENV:', process.env.REACT_APP_ENV)
      if (process.env.REACT_APP_ENV === 'dev') {
        URL = 'http://localhost:8080'
      }
      // SF
      // URL = 'https://ai.ecomacy.com/settings/yEz3dNwupKApQykDTKPU'
      // URL = 'https://ai.ecomacy.com'


      if (URL) {
        // const apiUrlPath = URL.concat('/acl/', payload.eapi);
        const apiUrlPath = URL.concat('/message');
        // const response = await axios.get(URL + '/apguser', payload, {
        // const response = await axios.post(apiUrlPath, {
        //   headers: {
        //     //  Authorization: jwt,
        //   },
        // });

        const response = await axios.post(
          apiUrlPath,
          payload,
          {
            headers: {
              // "Authorization": jwt
            }
          }
        )

        // console.log('response:', response);
        // cLog(response.data)
        if (response.status === 200) {
          returnData.status = response.status;
          returnData.data = response.data;
        } else {
          returnData.status = response.status;
          returnData.error = response.error;
        }
        // console.log('6 requestMfaFromApi URL', URL);

        return returnData;
      } else {
        returnData.status = 490;
        returnData.error = 'REACT_APP_API_URL url missing from env';
        return returnData;
      }
    } catch (err) {
      // debugger
      console.error('response error', err);
      returnData.status = 491;
      returnData.error = err;
      return returnData;
    }
  };


  const requestEcomacyAiSettingsFromApi = async data => {
    let returnData = {
      status: 0,
      data: {},
      error: {},
    };
    try {
      //const jwt = data.jwt;
      let payload = data.payload;

      // temp val
      // payload.locationid = 'yEz3dNwupKApQykDTKPU'

      let URL = 'https://api.ecomacy.com'
      // console.log('process.env.REACT_APP_ENV:', process.env.REACT_APP_ENV)
      if (process.env.REACT_APP_ENV === 'dev') {
        URL = 'http://localhost:8080'
      }

      // SF
      // URL = 'https://ai.ecomacy.com/settings/yEz3dNwupKApQykDTKPU'
      URL = 'https://ai.ecomacy.com'


      if (URL) {
        // const apiUrlPath = URL.concat('/acl/', payload.eapi);
        const apiUrlPath = URL.concat('/settings/', payload.locationid);
        // const response = await axios.get(URL + '/apguser', payload, {
        const response = await axios.get(apiUrlPath, {
          headers: {
            //  Authorization: jwt,
          },
        });

        // console.log('response:', response);
        // cLog(response.data)
        if (response.status === 200) {
          returnData.status = response.status;
          returnData.data = response.data;
        } else {
          returnData.status = response.status;
          returnData.error = response.error;
        }
        // console.log('6 requestMfaFromApi URL', URL);

        return returnData;
      } else {
        returnData.status = 490;
        returnData.error = 'REACT_APP_API_URL url missing from env';
        return returnData;
      }
    } catch (err) {
      // debugger
      console.error('response error', err);
      returnData.status = 491;
      returnData.error = err;
      return returnData;
    }
  };


  const processAiSettingsData = (settingsData, id) => {
    let returnData = {}
    let dataFound = false

    if (settingsData) {
      returnData = {...settingsData}
      dataFound = true
    }

    if (dataFound) {
      // set aisettingsid
      returnData.aisettingsid = id

      // update name
      returnData.data.name = returnData.data.name + ' Copy'
    }

    return returnData
  }

  const processAiConversationsData = (conversationData, id) => {
    let returnData = {}
    let dataFound = false

    if (conversationData) {
      returnData = {...conversationData}
      dataFound = true
    }

    if (dataFound) {
      // set aisettingsid
      returnData.aiconversationid = id

      // clear messages array
      returnData.messages = []

      // add created date
      const date = generateFirestoreDate()
      returnData.createdon = date
      // update name
      // returnData.data.name = returnData.data.name + ' Copy'
    }

    return returnData
  }

  const generateFirestoreDate = () => {
  // generate firestore timestamp
  // firebase.firestore.FieldValue.serverTimestamp()
  // firebase.firestore.Timestamp.now()
  // const timestamp = 1676475521;
  // const timestampDate = new Date(timestamp*1000);
  // const timestampFirestore = admin.firestore.Timestamp.fromDate(timestampDate)
  // const timestampFirestore = admin.firestore.Timestamp.now()
  // const timestampFirestore = firebase.firestore.Timestamp.now()
    const timestampFirestore = serverTimestamp();

    return timestampFirestore
  }
  const delay = (milliseconds) => new Promise(resolve => setTimeout(resolve, milliseconds))

  return (
    <AiAuthContext.Provider
      value={{
        testVal,
        setTestVal,
        aiSettingsDocs,
        setAiSettingsDocs,
        aiSettingsSelected,
        setAiSettingsSelected,
        aiSettingsData,
        setAiSettingsData,
        aiSettingsDataRef,
        aiSettingsDataUpdated,
        setAiSettingsDataUpdated,
        aiStrategySelected,
        setAiStrategySelected,
        aiStrategySelectedRefresh,
        setAiStrategySelectedRefresh,
        aiModelSelected,
        setAiModelSelected,
        aiModelSelectedRefresh,
        setAiModelSelectedRefresh,
        // aiChatMessagesData,
        //setAiChatMessagesData,
        aiConversationsDocs,
        setAiConversationsDocs,
        aiConversationsDocsRefresh,
        setAiConversationsDocsRefresh,
        aiConversationsSelected,
        setAiConversationsSelected,
        aiConversation,
        setAiConversation,
        aiConversationMessageCount,
        aiChatInput,
        setAiChatInput,
        aiConversationDuplicatedRefresh,
        setAiConversationDuplicatedRefresh,
        aiShowDescriptionPanel,
        setAiShowDescriptionPanel,
        // setAiUserData,
        //  getAiUserData: async () => {
        //
        //    try {
        //      let userDoc = {}
        //      if (user && user.uid) {
        //        // 1: Get UserDoc
        //        const userDoc = await getAiUserDoc(user.uid)
        //        if (userDoc && userDoc.error === undefined) {
        //          // user doc found
        //          setAiUserData({
        //            ...customerUserData,
        //            data: userDoc,
        //            loaded: true,
        //          })
        //
        //        }
        //        else if (userDoc && userDoc.error) {
        //          //
        //          setAiUserData({ ...customerUserData, loaded: true, error: userDoc.error })
        //        }
        //        else {
        //          // no doc found
        //          setAiUserData({
        //            ...customerUserData, loaded: true, error: {
        //              val: 'no doc found'
        //            }
        //          })
        //        }
        //      }
        //    } catch (err) {
        //      console.error(err);
        //      // setAdminUserData({ ...adminUserData, loaded: true, error: err })
        //    }
        //  },
        getAiSettingsDocs: async (userData) => {
          return new Promise((resolve, reject) => {

            try {

              let locationid = ''
              if (userData && userData.locationid) {
                locationid = userData.locationid
              }


              if (locationid === '') {
                // no locationid
                reject({
                  error: 'no locationid'
                })
              }

              if (locationid !== '') {

                const q = query(
                  collection(db, "aisettings"),
                  where("locationid", "==", locationid),
                 //  orderBy("createdon", "desc")
                  );
                const unsub = onSnapshot(q, (querySnapshot) => {
                  const aisettingsDocsArr = [];
                  querySnapshot.forEach((doc) => {
                    const docData = doc.data();
                    const smallData = {
                      aisettingsid: docData.aisettingsid,
                      name: docData.data.name,
                    }
                    // aisettingsDocsArr.push(doc.data().name);
                    aisettingsDocsArr.push(smallData);
                  });

                  setAiSettingsDocs(aisettingsDocsArr)

                  resolve({
                    data: aisettingsDocsArr
                  })

                });
              }

            } catch (err) {
              console.error(err);
              debugger
              reject({
                error: err
              })

            }
          })
        },
        getAiSettings: async (userData, selectedSettings) => {
          return new Promise((resolve, reject) => {

            try {

              // let aiconversationid = 'MIfmb2exQdkMvTQKzuEK'
              let aisettingsid = ''
              if (selectedSettings && selectedSettings.aisettingsid) {
                aisettingsid = selectedSettings.aisettingsid
              }
              // debugger
              if (aisettingsid !== '') {

                const unsub = onSnapshot(doc(db, "aisettings", aisettingsid), (doc) => {
                  console.log("Current data: ", doc.data());
                  // const docData = doc.data()
                  // set aiConversation state that listens to changes
                  setAiSettingsData(doc.data())

                  resolve({
                    data: doc.data()
                  })
                });
              }

            } catch (err) {
              console.error(err);
              debugger
              reject({
                error: err
              })

            }
          })
        },
        duplicateAiSettings: async (message) => {
          let returnData = {
          };
          try {

            // Get the collection reference
            const collectionRef = collection(db, 'aisettings');

            // Generate "locally" a new document for the given collection reference
            const docRef = doc(collectionRef);

            // Get the new document Id
            const id = docRef.id;

            const processedData = processAiSettingsData(aiSettingsData, id)

            //  Sets the new document with its uuid as property
            // await setDoc(docRef, { uuid: documentUuid, ... })
            const result = await setDoc(docRef, processedData)

            returnData.data = {aisettingsid: id}
            //            const ref = db.collection('your_collection_name').doc();
            //
            //            const newDoc = await addDoc(collection(db, "aisettings"), message);
            //            console.log("Document written with ID: ", newDoc.id);
            //            return newDoc.id
            return returnData;

          } catch (err) {
            console.error(err);
            returnData.status = 501;
            returnData.error = {
              val: 'Ecomacy customers data fetch failed',
            };
            // return returnData;
          }
        },
        getAiConversationsDocs: async (userData) => {
          return new Promise((resolve, reject) => {

            try {

              let locationid = ''
              if (userData && userData.locationid) {
                locationid = userData.locationid
              }

              let aisettingsid = ''
              if (
                aiSettingsDataUpdated &&
                aiSettingsDataUpdated.aisettingsid &&
                aiSettingsDataUpdated.aisettingsid !== ''
                ) {
                  aisettingsid = aiSettingsDataUpdated.aisettingsid
              }

              if (locationid === '') {
                // no locationid
                reject({
                  error: 'no locationid'
                })
              }

              if (aisettingsid === '') {
                // no aisettingsid
                reject({
                  error: 'no aisettingsid'
                })
              }

              if (locationid !== '' && aisettingsid !== '') {
                const q = query(
                  collection(db, "aiconversations"),
                  where("locationid", "==", locationid),
                  where("aisettingsid", "==", aisettingsid),
                  orderBy("createdon", "desc"),
                  );
                const unsub = onSnapshot(q, (querySnapshot) => {
                  const aiconversationsDocsArr = [];
                  querySnapshot.forEach((doc) => {
                    const docData = doc.data();
                    const smallData = {
                      aiconversationid: docData.aiconversationid,
                      createdon: docData.createdon,
                      // name: docData.data.name,
                    }
                    // aisettingsDocsArr.push(doc.data().name);
                    aiconversationsDocsArr.push(smallData);
                  });

                  setAiConversationsDocs(aiconversationsDocsArr)

                  resolve({
                    data: aiconversationsDocsArr
                  })

                });
              }

            } catch (err) {
              console.error(err);
              debugger
              reject({
                error: err
              })

            }
          })
        },
        getAiConversation: async (userData, conversationsSelected) => {
          return new Promise((resolve, reject) => {

            try {
              // BT need conversation id
              // let aiconversationid = 'MIfmb2exQdkMvTQKzuEK'
              let aiconversationid = ''
              if (conversationsSelected && conversationsSelected.aiconversationid && conversationsSelected.aiconversationid !== '') {
                aiconversationid = conversationsSelected.aiconversationid
              }
              // debugger
              if (aiconversationid !== '') {
                const unsub = onSnapshot(doc(db, "aiconversations", aiconversationid), (doc) => {
                  console.log("Current data: ", doc.data());
                  // const docData = doc.data()
                  // set aiConversation state that listens to changes
                  setAiConversation(doc.data())
                  // if (doc.messages && doc.messages.length > 0) {
                  //   aiConversationMessageCount.current = doc.messages.length;
                  // }
                  resolve({
                    data: doc.data()
                  })
                });
              }

            } catch (err) {
              console.error(err);
              debugger
              reject({
                error: err
              })

            }
          })
        },
        duplicateAiConversation: async (message) => {
          let returnData = {
          };
          try {

            // Get the collection reference
            const collectionRef = collection(db, 'aiconversations');

            // Generate "locally" a new document for the given collection reference
            const docRef = doc(collectionRef);

            // Get the new document Id
            const id = docRef.id;

            const processedData = processAiConversationsData(aiConversation, id)

            //  Sets the new document with its uuid as property
            const result = await setDoc(docRef, processedData)
            returnData.data = {aiconversationid: id}

            return returnData;

          } catch (err) {
            console.error(err);
            returnData.status = 501;
            returnData.error = {
              val: 'Ecomacy customers data fetch failed',
            };
            // return returnData;
          }
        },
        postAiMessage: async (message) => {
          let returnData = {
            status: 200,
            data: {},
            error: {},
          };
          try {
            // SF
            let locationid = 'yEz3dNwupKApQykDTKPU'
            if (locationid && locationid !== '') {
              // if (userData && userData.eapi && userData.eapi !== '') {

              // call fn API to get data

              // const userToken = await getUserToken();
              // const jwt = 'Bearer ' + userToken;

              // console.log('4 getEcomacyLocations:', jwt);
              const data = {
                // jwt: jwt,
                payload: {
                  aisettingsid: '0Zcn8PYl3Dv1iZUw69C8',
                  message, message,
                  // eapi: userData.eapi,
                },
              };
              // console.log('getEcomacyLocations data:', data);

              let result = await postEcomacyAiMessageFromApi(data);
              returnData.status = result.status

              if (result.status === 200 && result.data) {
                const apiData = result.data
                returnData.status = apiData.status
                if (apiData.data) {
                  returnData.data = apiData.data
                }
                else if (apiData.error) {
                  returnData.error = apiData.error
                }
              }
              else {
                returnData.error = result.error.code
              }
              // let result = {status: 'success'};
              return returnData;

            }
          } catch (err) {
            console.error(err);
            returnData.status = 501;
            returnData.error = {
              val: 'Ecomacy customers data fetch failed',
            };
            // return returnData;
          }
        },
      }}>
      {children}
    </AiAuthContext.Provider>
  );
};

// export default AdminAuthProvider;
