import React, {
  useState,
  useEffect,
  useContext,
  // useCallback
} from 'react'
import PropTypes from 'prop-types';

import {
  // getAuth,
  //signInWithEmailAndPassword,
  // signOut
} from 'firebase/auth';
// import { useAuthState } from 'react-firebase-hooks/auth';

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebase } from '../code/firebase';
import { processKeywordsUserData } from '../code/ecomacy';

// third-party
import { FixedSizeList } from 'react-window';


import { AiAuthContext } from '../helpers/AiAuthProvider';

import _ from 'lodash';

import {
  useTheme,
  // styled
} from '@mui/material/styles';

import {
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
  Grid,
  Paper,
  Slider,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import AddIcon from '@mui/icons-material/Add';

import Container from '@mui/material/Container';
import GridKeywordTimelinePane from './GridKeywordTimelinePane'
import LoadingPane from './LoadingPane'

// import MainCard from './MainCard';
// import { getDfsLocationData } from '../code/ecomacy'

// import './GridKeywordsPane.css'
// const auth = getAuth(firebase)


const modelList = [
  {
    key: 'gpt35turbo',
    model: 'gpt-3.5-turbo'
  },
  {
    key: 'gpt35turbo0301',
    model: 'gpt-3.5-turbo-0301'
  },
]

const AiHistoryPanel = (props) => {
  const theme = useTheme();

  const { userData } = props

  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);
  const { aiSettingsDataUpdated, setAiSettingsDataUpdated } = useContext(AiAuthContext);
  const { aiModelSelected, setAiModelSelected } = useContext(AiAuthContext);

  const { aiConversationsDocs, setAiConversationsDocs } = useContext(AiAuthContext);
  const { aiConversationsDocsRefresh, setAiConversationsDocsRefresh } = useContext(AiAuthContext);

  const { aiConversationsSelected, setAiConversationsSelected } = useContext(AiAuthContext);
  const { getAiConversationsDocs } = useContext(AiAuthContext);
  const { aiConversationDuplicatedRefresh, setAiConversationDuplicatedRefresh } = useContext(AiAuthContext);


  const [init, setInit] = useState(true)

  const [model, setModel] = useState({})

  // console.log('AiModelPanel aiSettingsData', aiSettingsData);

  useEffect(() => {

    // declare the data fetching function
    const fetchAiConversationsDocs = async () => {
      // const test = aiSettingsDataUpdated;
      const returnData = await getAiConversationsDocs(userData);
      if (returnData && returnData.data) {
        // set in getAiSettingsDocs function
        // setAiConversationsDocs(returnData.data)

        // if aiconversations exist, set the first one as selected
        if (returnData.data.length > 0) {
          setAiConversationsSelected(returnData.data[0])
        }

        setInit(false)
      }
      else {
        setInit(false)
      }
    }

    if (init) {
      // call the function
      fetchAiConversationsDocs(userData)
        // make sure to catch any error
        .catch(console.error);
    }
  }, [init])



  // triggered when aiSettingsDataUpdated changes
  // & aiConversationsDocsRefresh is true
  useEffect(() => {

    // declare the data fetching function
    const fetchAiConversationsDocs = async () => {
      const returnData = await getAiConversationsDocs(userData);
      if (returnData && returnData.data) {
        // set in getAiSettingsDocs function
        // setAiConversationsDocs(returnData.data)

        // if aiconversations exist, set the first one as selected
        if (returnData.data.length > 0) {
          setAiConversationsSelected(returnData.data[0])
        }

      }
    }

    if (!init && aiConversationsDocsRefresh) {
      // debugger
      // call the function
      fetchAiConversationsDocs(userData)
        // make sure to catch any error
        .catch(console.error);

      setAiConversationsDocsRefresh(false)
    }
  }, [aiSettingsDataUpdated])
  // }, [aiConversationsDocsRefresh])


  // triggered when aiconversation is duplicated
  // & aiConversationsDocsRefresh is true
  useEffect(() => {

    // declare the data fetching function
    const fetchAiConversationsDocs = async () => {
      const returnData = await getAiConversationsDocs(userData);
      if (returnData && returnData.data) {
        // set in getAiSettingsDocs function
        // setAiConversationsDocs(returnData.data)

        // if aiconversations exist, set the first one as selected
        if (returnData.data.length > 0) {
          setAiConversationsSelected(returnData.data[0])
        }

      }
    }

    if (!init && aiConversationDuplicatedRefresh) {
      debugger
      // call the function
      fetchAiConversationsDocs(userData)
        // make sure to catch any error
        .catch(console.error);

      setAiConversationDuplicatedRefresh(false)
    }
  }, [aiConversationDuplicatedRefresh])


  function renderRow(props) {
    const { index, style } = props;

    return (
      <ListItem style={style} key={index} component="div" disablePadding divider>
        <ListItemButton
          onClick={handleListItemButtonOnClick()}
        >
          <ListItemText primary={`Item ${index + 1}`} />
        </ListItemButton>
      </ListItem>
    );
  }


  const handleListItemButtonOnClick = (selectedConversationDoc) => {
    setAiConversationsSelected(selectedConversationDoc)
  }

  if (init) {
    return <LoadingPane />
  }

  if (aiConversationsDocs) {
    const conversationsCount = aiConversationsDocs.length

    //    <Box
    //    sx={{
    //      width: '100%',
    //      // height={400}
    //      height: '100%',
    //      bgcolor: 'background.paper',
    //      overflow: 'hidden'
    //    }}
    //  >

    return (


      <Box
        name={'historyPanel'}
        height={'100%'}
        // bgcolor="#cc0000"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // overflow: 'hidden',
        }}

      >

        <Box
          name={'historyList'}
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
            height: '100%',
          }}
        >

          {
            // <Typography>Ai Chat Panel</Typography>
          }


          <Box
            // component={wrapper}
            // ref={wrapper} // Option 1: use ref to scroll to bottom
            id={'historyScrollWrapper'}
            sx={{
              maxHeight: '100%',
              // flexGrow: 1,
              overflow: 'auto',
              // display: 'flex', // Option 2: reverse column so that the last message is at the top
              // flexDirection: "column-reverse" // Option 2: would need to reverse the order of the messages
            }}
          >

            <List sx={{ p: 0 }}>
              {
                aiConversationsDocs.map((c, index) => {
                  // const dateTest = c.createdon.toDate()
                  let dateString = ''
                  if (c.createdon && c.createdon.seconds) {
                    const date = new Date(c.createdon.seconds * 1000);
                    dateString = date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear();
                  }

                  return (
                    <ListItem
                      key={c.aiconversationid}
                      disablePadding
                      divider
                      sx={{
                        backgroundColor: aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? theme.palette.primary.main : 'transparent',
                        color: aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? theme.palette.primary.contrastText : 'inherit'
                      }}
                    >
                      <ListItemButton
                        onClick={() => handleListItemButtonOnClick(c)}
                      >
                        <ListItemText primary={dateString} />
                      </ListItemButton>
                    </ListItem>
                  )
                })
              }

              {
                //  <ListItem disablePadding divider><ListItemButton><ListItemText primary='hello' /></ListItemButton></ListItem>
              }

            </List>
          </Box>
        </Box>
      </Box>

    );
    // return (
    //   <Box
    //     sx={{
    //       width: '100%',
    //       // height={400}
    //       height: '100%',
    //       bgcolor: 'background.paper'
    //     }}
    //   >
    //     <FixedSizeList
    //       // height={400}
    //       height='100%'
    //       width="100%"
    //       itemSize={46}
    //       itemCount={200}
    //       overscanCount={5}
    //     >
    //       {renderRow}
    //     </FixedSizeList>
    //   </Box>
    // );
  }

  if (!aiConversationsDocs) {
    return (
      <Box>
        <Typography>
          No conversations found
        </Typography>
      </Box >
    );
  }


};


export default React.memo(AiHistoryPanel)