import React, { useEffect, useState, useContext } from 'react';

// import { firebase } from '../code/firebase';

import _ from 'lodash';
// import moment from 'moment'

import {
  Grid,
  Box,
  Typography,
  useTheme,
  CircularProgress
} from '@mui/material';

// third-party
import {
  // SearchOutlined,
  EyeOutlined,
  FacebookOutlined
} from '@ant-design/icons';


// import AnalyticEcommerce from './cards/statistics/AnalyticEcommerce';
import AnalyticTokensUsed from './cards/statistics/AnalyticTokensUsed';
import AnalyticTokensNeeded from './cards/statistics/AnalyticTokensNeeded';
import RoundIconCard from './cards/statistics/RoundIconCard';
import HoverSocialCard from './cards/statistics/HoverSocialCard';

import { CustomerAuthContext } from '../helpers/CustomerAuthProvider';
const { DateTime, Interval } = require("luxon");


const SettingsCalcPane = (props) => {
  const theme = useTheme();

  const { customerUserData, setCustomerUserData } = useContext(CustomerAuthContext);
  const [init, setInit] = useState(true)
  const [loading, setLoading] = useState(false)

  const { rawData, setRawData } = useContext(CustomerAuthContext);
  const { rawDataRef } = useContext(CustomerAuthContext);

  const { tokenData, setTokenData } = useContext(CustomerAuthContext);
  // const { rawDataRef } = useContext(CustomerAuthContext);

  // const [userData, setUserData] = useState(tempUserData)
  // const [loaded, setLoaded] = useState(true)

  useEffect(() => {

    if (init) {
      const result = calculateRawData()
      setRawData(result)
      rawDataRef.current = result

      const tResult = calculateTokenData(result)
      setTokenData(tResult)
      setInit(false)
    }
    // if (map) {
    //   updateBounds(map)
    //   // handleRecenterMapReset()
    // }
  }, [init])


  useEffect(() => {

    if (!init) {
      // compare rawData to rawDataRef
      const isEqual = _.isEqual(rawData, rawDataRef.current)
      if (!isEqual) {
        rawDataRef.current = rawData
        //run
        const tResult = calculateTokenData(rawData)
        setTokenData(tResult)
        setLoading(false)
      }
      else {
        setLoading(false)
      }
    }
    else {
      // debugger
    }
  }, [rawData])

  useEffect(() => {

    let recalc = false
    if (!init) {
      // check keyword count
      let rdKeywordsLength = 0;
      if (rawData.saveKeywords && rawData.saveKeywords.keywords) {
        rdKeywordsLength = rawData.saveKeywords.keywords.length
      }

      let keywordsLength = 0;
      if (props.saveKeywords && props.saveKeywords.keywords) {
        keywordsLength = props.saveKeywords.keywords.length
      }

      if (rdKeywordsLength !== keywordsLength) {
        recalc = true
      }

      // check schedule
      let rdSchedule = null
      if (rawData.saveKeywords && rawData.saveKeywords.schedule) {
        rdSchedule = rawData.saveKeywords.schedule
      }

      let schedule = null
      if (props.saveKeywords && props.saveKeywords.schedule) {
        schedule = props.saveKeywords.schedule
      }

      const isScheduleEqual = _.isEqual(rdSchedule, schedule)
      if (!isScheduleEqual) {
        recalc = true
      }

      if (recalc) {
        setLoading(true)
        const result = calculateRawData()
        setRawData(result)
        // rawDataRef.current = result
      }
    }
  }, [props.saveKeywords])

  useEffect(() => {

    let recalc = false

    if (!init) {
      // compare props.saveGridTemplates.grid size to rawData.saveGridTemplates.grid size

      // check keyword count
      let rdGridSize = 0;
      if (rawData.saveGridTemplates && rawData.saveGridTemplates.grid) {
        rdGridSize = _.size(rawData.saveGridTemplates.grid)
      }

      let gridSize = 0;
      if (props.saveGridTemplates && props.saveGridTemplates.grid) {
        gridSize = _.size(props.saveGridTemplates.grid)
      }

      if (rdGridSize !== gridSize) {
        recalc = true
      }

      if (recalc) {
        setLoading(true)
        const result = calculateRawData()
        setRawData(result)
        //rawDataRef.current = result
      }
    }
  }, [props.saveGridTemplates])

  const calculateRawData = () => {

    let rawData = {
      customerData: null,
      accountStartTs: null,
      billingStartTs: null,
      tokensIncluded: null,
      tokensExtra: null,
      monthly: null,
      multiple: null,
      tokenPrice: null,
      runningTotal: null,
      tokensLeftThisMonth: null,
      allCustomerData: false,

      saveKeywords: null,
      keywordsCount: null,
      schedule: null,
      scheduleDay: null,
      scheduleHour: null,
      scheduleInterval: null,
      allKeywordsData: false,

      saveGridTemplates: null,
      gridCount: null,
      allGridData: false
    }


    // setLoading(true);
    // let customerData = rawData.customerData
    let customerData = null
    if (customerUserData && customerUserData.data && customerUserData.data.data) {
      customerData = customerUserData.data.data
    }
    rawData.customerData = customerData

    //// customerUserData

    // accountstart: nt {seconds: 1674914754, nanoseconds: 633000000}
    // allocatedtokens: 5000
    // billingstart: nt {seconds: 1675209600, nanoseconds: 413000000}
    // monthlyprice: 20
    // multiple: 10
    // tokenprice: 0.012

    // let accountStartTs = null
    if (customerData && customerData.accountstart && customerData.accountstart.seconds) {
      rawData.accountStartTs = customerData.accountstart.seconds
    }

    // let billingStartTs = null
    if (customerData && customerData.billingstart && customerData.billingstart.seconds) {
      rawData.billingStartTs = customerData.billingstart.seconds
    }

    // let tokensIncluded = null
    if (customerData && customerData.tokensincluded) {
      rawData.tokensIncluded = customerData.tokensincluded
    }

    // let tokensExtra = null
    if (customerData && customerData.tokensextra) {
      rawData.tokensExtra = customerData.tokensextra
    }

    // let monthly = null
    if (customerData && customerData.monthly) {
      rawData.monthly = customerData.monthly
    }

    // let multiple = null
    if (customerData && customerData.multiple) {
      rawData.multiple = customerData.multiple
    }

    // let tokenPrice = null
    if (customerData && customerData.tokenprice) {
      rawData.tokenPrice = customerData.tokenprice
    }

    // let runningTotal = null
    if (customerData && customerData.runningtotal) {
      rawData.runningTotal = customerData.runningtotal
    }

    if (rawData.tokensIncluded && rawData.runningTotal) {
      rawData.tokensLeftThisMonth = rawData.tokensIncluded - rawData.runningTotal
    }


    // let allCustomerData = false
    if (
      rawData.accountStartTs
      && rawData.billingStartTs
      && rawData.tokensIncluded
      && rawData.tokensExtra
      && rawData.monthly
      && rawData.multiple
      && rawData.tokenPrice
      && rawData.runningTotal
    ) {
      rawData.allCustomerData = true
    }

    let saveKeywords = null
    if (props.saveKeywords) {
      saveKeywords = props.saveKeywords
    }

    rawData.saveKeywords = saveKeywords

    // let keywordsCount = null
    if (saveKeywords && saveKeywords.keywords) {
      rawData.keywordsCount = saveKeywords.keywords.length
    }

    let schedule = null
    if (saveKeywords && saveKeywords.schedule) {
      schedule = saveKeywords.schedule
    }
    rawData.schedule = schedule


    // let scheduleDay = null
    if (schedule && schedule.day) {
      rawData.scheduleDay = parseInt(schedule.day)
    }

    // let scheduleHour = null
    if (schedule && schedule.hour) {
      rawData.scheduleHour = parseInt(schedule.hour)
    }

    // let scheduleInterval = null
    if (schedule && schedule.interval) {
      rawData.scheduleInterval = schedule.interval
    }

    // let allKeywordsData = false
    if (
      rawData.keywordsCount
      && rawData.scheduleDay !== null
      && rawData.scheduleHour !== null
      && rawData.scheduleInterval
    ) {
      rawData.allKeywordsData = true
    }


    let saveGridTemplates = null
    if (props.saveGridTemplates) {
      saveGridTemplates = props.saveGridTemplates
    }

    rawData.saveGridTemplates = props.saveGridTemplates

    // let gridCount = null
    if (saveGridTemplates && saveGridTemplates.grid) {
      rawData.gridCount = _.size(saveGridTemplates.grid)
    }

    // let allGridData = false
    if (rawData.gridCount) {
      rawData.allGridData = true
    }

    // console.log('------------------------')
    // console.log('accountStartTs:', accountStartTs)
    // console.log('billingStartTs:', billingStartTs)
    // console.log('tokensIncluded:', tokensIncluded)
    // console.log('tokensExtra:', tokensExtra)
    // console.log('monthly:', monthly)
    // console.log('multiple:', multiple)
    // console.log('tokenPrice:', tokenPrice)
    // console.log('runningTotal:', runningTotal)
    // console.log('keywordsCount:', keywordsCount)
    // console.log('gridCount:', gridCount)
    // console.log('tokensLeftThisMonth:', tokensLeftThisMonth)
    // console.log('------------------------')

    return rawData

    // setLoading(false)
  }

  const calculateTokenData = (rawData) => {

    let tokenData = {
      iterationsMonthly: null,
      tokensLeftThisMonth: null,
      tokensNeededSingle: null,
      totalSingle: null,
      tokensNeededMonthly: null,
      totalMonthly: null,
      tokensLeftAfterDeduction: null,
    }

    if (rawData.allCustomerData && rawData.allKeywordsData && rawData.allGridData) {

      const accountStartTs = rawData.accountStartTs
      const billingStartTs = rawData.billingStartTs
      const tokensIncluded = rawData.tokensIncluded
      const tokensExtra = rawData.tokensExtra
      const monthly = rawData.monthly
      const multiple = rawData.multiple
      const tokenPrice = rawData.tokenPrice
      const runningTotal = rawData.runningTotal
      const keywordsCount = rawData.keywordsCount
      const gridCount = rawData.gridCount
      const tokensLeftThisMonth = rawData.tokensLeftThisMonth

      const scheduleDay = rawData.scheduleDay
      const scheduleHour = rawData.scheduleHour
      const scheduleInterval = rawData.scheduleInterval

      // const tokensLeftThisMonth = tokensIncluded - runningTotal


      // let tokensNeededThisMonth = 0

      console.log('------------------------')
      console.log('accountStartTs:', accountStartTs)
      console.log('billingStartTs:', billingStartTs)
      console.log('tokensIncluded:', tokensIncluded)
      console.log('tokensExtra:', tokensExtra)
      console.log('monthly:', monthly)
      console.log('multiple:', multiple)
      console.log('tokenPrice:', tokenPrice)
      console.log('runningTotal:', runningTotal)
      console.log('keywordsCount:', keywordsCount)
      console.log('gridCount:', gridCount)
      console.log('tokensLeftThisMonth:', tokensLeftThisMonth)
      console.log('------------------------')

      // debugger
      let currentMoment = DateTime.now()          // 7th feb
      //  // currentMoment = moment('2023-01-31')  // 31st jan
      //  const currentMonth = currentMoment.month()
      //  // const currentDay = currentDate.day();
      const currentDate = currentMoment.day;

      //
      let billingStartMoment = DateTime.fromMillis(billingStartTs * 1000);
      // round to 12am
      billingStartMoment = billingStartMoment.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

      // const billingStartMonth = billingStartMoment.month;
      // const billingStartDay = billingStartMoment.day;
      // const billingStartHour = billingStartMoment.hour;
      // const billingStartMinute = billingStartMoment.minute;

      // add 31 days
      let billingEndMoment = billingStartMoment.plus({ days: 31 }).minus({ minutes: 1 })

      console.log('billingStartMoment:', billingStartMoment.c)
      console.log('billingEndMoment:', billingEndMoment.c)
      const billinginterval = Interval.fromDateTimes(billingStartMoment, billingEndMoment);


      console.log('billingStartFormat:', billingStartMoment.toLocaleString(DateTime.DATETIME_FULL))
      console.log('billingNextFormat:', billingEndMoment.toLocaleString(DateTime.DATETIME_FULL))


      // const tokensIncluded = 2000
      // const tokensExtra = 4 // $4 per 1000
      // const monthly = 40
      // const multiple = 10
      // const tokenPrice = 0.012
      // const runningTotal = 1350
      // const keywordsCount = 3
      // const gridCount = 121

      let iterationsMonthly = 0
      // let tokensNeededBillingCurrent = 0

      if ('monthly' === scheduleInterval) {
        iterationsMonthly = 1
      }
      else if ('weekly' === scheduleInterval) {
        const subIntervalsWeeks = billinginterval.splitBy({ days: 1 });
        // scheduleDay
        for (let i = 0; i < subIntervalsWeeks.length; i++) {
          const item = subIntervalsWeeks[i]
          // const d = item.e.c;
          const start = item.start;
          const dayNum = start.weekday

          // const end = item.end.c;
          if (dayNum === scheduleDay) {
            iterationsMonthly = iterationsMonthly + 1
          }
        }
      }
      else if ('daily' === scheduleInterval) {
        const subIntervalsDays = billinginterval.splitBy({ days: 1 });

        // scheduleDay
        iterationsMonthly = subIntervalsDays.length
      }
      else if ('hourly' === scheduleInterval) {
        // sheduleHour
        const subIntervalsDays = billinginterval.splitBy({ days: 1 });

        for (let i = 0; i < subIntervalsDays.length; i++) {
          const item = subIntervalsDays[i]
          // const d = item.e.c;
          const start = item.start;
          const end = item.end.minus({ minutes: 1 });

          const dayInterval = Interval.fromDateTimes(start, end);
          const subIntervalsHours = dayInterval.splitBy({ hours: 1 });
          for (let j = 0; j < subIntervalsHours.length; j++) {
            iterationsMonthly = iterationsMonthly + 1
          }
        }

      }

      const tokensNeededMonthly = gridCount * keywordsCount * iterationsMonthly
      const tokensNeededSingle = gridCount * keywordsCount * 1

      const totalMonthly = (tokenPrice * multiple) * gridCount * keywordsCount * iterationsMonthly
      const totalSingle = (tokenPrice * multiple) * gridCount * keywordsCount * 1

      // if negative more credits are needed
      const tokensLeftAfterDeduction = tokensLeftThisMonth - tokensNeededMonthly


      tokenData = {
        iterationsMonthly: iterationsMonthly,
        tokensLeftThisMonth: tokensLeftThisMonth,
        tokensNeededSingle: tokensNeededSingle,
        totalSingle: totalSingle,
        tokensNeededMonthly: tokensNeededMonthly,
        totalMonthly: totalMonthly,
        tokensLeftAfterDeduction: tokensLeftAfterDeduction,
      }


      console.log('iterationsMonthly:', tokenData.iterationsMonthly)
      console.log('tokensLeftThisMonth:', tokenData.tokensLeftThisMonth)
      console.log('tokensNeededSingle:', tokenData.tokensNeededSingle)
      console.log('totalSingle:', tokenData.totalSingle)

      console.log('tokensNeededMonthly:', tokenData.tokensNeededMonthly)
      console.log('totalMonthly:', tokenData.totalMonthly)

      console.log('tokensLeftAfterDeduction:', tokenData.tokensLeftAfterDeduction)

    }
    else {
      debugger
    }

    return tokenData

  }

  if (init || loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100px'
        }}
      >
        <CircularProgress
          size={24}
          sx={{
            // color: theme.palette.success.main,
            //position: 'absolute',
            //top: '50%',
            //left: '50%',
            marginTop: '24px',
            marginLeft: '24px',
          }}
        />

      </Box>
    )
  }

  const CalcAnalyticTokensUsed = () => {

    let hasData = true
    let tokensIncluded = null
    if (rawData && rawData.tokensIncluded) {
      tokensIncluded = rawData.tokensIncluded
    }
    else {
      hasData = false
    }

    let tokensLeftThisMonth = null
    if (tokenData && tokenData.tokensLeftThisMonth) {
      tokensLeftThisMonth = tokenData.tokensLeftThisMonth
    }
    else {
      hasData = false
    }

    return (
      <AnalyticTokensUsed
        title="Tokens Left"
        tokensIncluded={tokensIncluded}
        tokensLeftThisMonth={tokensLeftThisMonth}
        percentage={59.3}
        extra="35,000"
        hasData={hasData}
      />
    )
  }


  const CalcAnalyticTokensNeeded = ({ isSingle }) => {

    let hasData = true
    let tokensIncluded = null
    if (rawData && rawData.tokensIncluded) {
      tokensIncluded = rawData.tokensIncluded
    }
    else {
      hasData = false
    }

    let iterationsMonthly = null
    if (tokenData && tokenData.iterationsMonthly) {
      iterationsMonthly = tokenData.iterationsMonthly
    }
    else {
      hasData = false
    }

    let tokensLeftAfterDeduction = null
    if (tokenData && tokenData.tokensLeftAfterDeduction) {
      tokensLeftAfterDeduction = tokenData.tokensLeftAfterDeduction
    }
    else {
      hasData = false
    }

    let tokensLeftThisMonth = null
    if (tokenData && tokenData.tokensLeftThisMonth) {
      tokensLeftThisMonth = tokenData.tokensLeftThisMonth
    }
    else {
      hasData = false
    }

    let tokensNeededMonthly = null
    if (tokenData && tokenData.tokensNeededMonthly) {
      tokensNeededMonthly = tokenData.tokensNeededMonthly
    } else {
      hasData = false
    }

    let tokensNeededSingle = null
    if (tokenData && tokenData.tokensNeededSingle) {
      tokensNeededSingle = tokenData.tokensNeededSingle
    }
    else {
      hasData = false
    }

    let totalMonthly = null
    if (tokenData && tokenData.totalMonthly) {
      totalMonthly = tokenData.totalMonthly
    } else {
      hasData = false
    }

    let totalSingle = null
    if (tokenData && tokenData.totalSingle) {
      totalSingle = tokenData.totalSingle
    } else {
      hasData = false
    }

    let title = ''
    if (isSingle) {
      title = 'Single Cost'
    } else {
      title = 'Monthly Cost'
    }

    return (
      <AnalyticTokensNeeded
        title={title}
        tokensIncluded={tokensIncluded}
        iterationsMonthly={iterationsMonthly}
        tokensLeftAfterDeduction={tokensLeftAfterDeduction}
        tokensLeftThisMonth={tokensLeftThisMonth}
        tokensNeededMonthly={tokensNeededMonthly}
        tokensNeededSingle={tokensNeededSingle}
        totalMonthly={totalMonthly}
        totalSingle={totalSingle}
        percentage={59.3}
        extra="35,000"
        hasData={hasData}
        isSingle={isSingle}
      />
    )
  }

  if (tokenData) {
    return (
      <>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <CalcAnalyticTokensUsed

          />
          {
            // <AnalyticEcommerce title="Total Page Views" count="4,42,236" percentage={59.3} extra="35,000" />
          }
          {
            // <AnalyticEcommerce title="Total Users" count="78,250" percentage={70.5} color="success" extra="8,900" />
          }
          {
            // <AnalyticEcommerce title="Total Order" count="18,800" percentage={27.4} isLoss color="warning" extra="1,943" />
          }

        </Grid>


        <Grid item xs={12} sm={12} md={4} lg={4}>
          <CalcAnalyticTokensNeeded
            isSingle={true}
          />

          {
            //  <RoundIconCard
            //    primary="Impressions"
            //    secondary="1,563"
            //    content="May 23 - June 01 (2018)"
            //    iconPrimary={EyeOutlined}
            //    color="primary.main"
            //    bgcolor="primary.lighter"
            //  />
          }


        </Grid>


        <Grid item xs={12} sm={12} md={4} lg={4}>
          {
            // <HoverSocialCard primary="Facebook Users" secondary="1165 +" iconPrimary={FacebookOutlined} color={theme.palette.primary.main} />
          }
          <CalcAnalyticTokensNeeded
            isSingle={false}
          />
        </Grid>
      </>
    )
  }

  return (
    <Box width >
      <Typography>Loading...</Typography>
    </Box>
  )

};


export default React.memo(SettingsCalcPane)