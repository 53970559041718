import React, {useEffect, useState} from 'react';

// import ReactDOM from "react-dom/client";
import {
  // BrowserRouter,
  Routes,
  Route,
  // useNavigate
} from "react-router-dom";

import MainLayout from '../layout/MainLayout'
//import TestPane from '../components/TestPane'
// import GridKeywordsContainer from '../components/GridKeywordsContainer'
// import SettingsContainer from '../components/SettingsContainer'
// import GridKeywordsPane from '../components/GridKeywordsPane'

import AiContainer from '../components/AiContainer'


// ==============================|| ROUTING RENDER ||============================== //

export default function AiRoutes(props) {

  // const userData = props.userData
  // const userData = {}

  useEffect(() => {
    // debugger
  }, [])

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route
          index
         element={<AiContainer />}
         // element={<SettingsContainer props={userData} />}
        />
      </Route>
    </Routes>
  )

  // return (
  //   <Routes>
  //     <Route path="/" element={<App />}>
  //       <Route index element={<Home />} />
  //       <Route path="teams" element={<Teams />}>
  //         <Route path=":teamId" element={<Team />} />
  //         <Route path="new" element={<NewTeamForm />} />
  //         <Route index element={<LeagueStandings />} />
  //       </Route>
  //     </Route>
  //   </Routes>
  // )
}
