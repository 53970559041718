// import './App.css';
// import './GoogleMapPane.css';

import React, {
  useState,
  useEffect
} from 'react'
// import _ from 'lodash';


// import { LatLng, computeOffset } from 'spherical-geometry-js';
import {
  Box,
  CircularProgress,
  // Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';


const GridItemInfo = (props) => {
  const theme = useTheme();

  let userData = {}
  if (props.userData) {
    userData = props.userData
  }

  let keywordsDocData = {}
  if (props.keywordsDocData) {
    keywordsDocData = props.keywordsDocData
  }

  let id = ''
  if (props.id && props.id !== '') {
    id = props.id
  }

  let activeMarker = ''
  if (props.activeMarker && props.activeMarker !== '') {
    activeMarker = props.activeMarker
  }

  let scanId = ''
  if (props.scanId && props.scanId !== '') {
    scanId = props.scanId
  }

  let gridItemsData = {}
  if (props.gridItemsData) {
    gridItemsData = props.gridItemsData
  }

  // const gridItemInfoStyle = props.gridItemInfoStyle

  let loading = true

  let gItemData = {}
  if (gridItemsData[scanId]) {
    gItemData = gridItemsData[scanId]
    loading = false
  }

  const [gridItemData, setGridItemData] = useState(gItemData)
  const [loaded, setLoaded] = useState(!loading)


  useEffect(() => {
    // const test = gridItemsData
    // const test1 = gItemData
    // if (loaded === false) {
    if (activeMarker === id && gridItemsData[scanId] && loaded === false) {
      setGridItemData(gItemData)
      setLoaded(true)
    }
  }, [gItemData])

  // useEffect(() => {
  //   // const test = gridItemsData
  //   // const test1 = gItemData
  //   // if (loaded === false) {
  //   if (gridItemsData[scanId] && loaded === false) {
  //     setGridItemData(gItemData)
  //     setLoaded(true)
  //   }
  // }, [gridItemsData])


  const gridItemContent = () => {

    // build Top 4 table
    let list4 = []
    if (gridItemData.list4 && gridItemData.list4.length > 0) {
      list4 = gridItemData.list4
    }
    if (
      gridItemData
      && gridItemData.data
      && gridItemData.data.result
      && gridItemData.data.result[0]
    ) {
      let userLocationFound = false
      const limit = 4

      const result = gridItemData.data.result[0]
      if (result.items && result.items.length > 0) {
        const items = result.items
        if (items.length > 0) {

          let userPlaceId = ''
          if (userData && userData.googleplaceid) {
            userPlaceId = userData.googleplaceid
          }

          list4 = []
          for (var i = 0; i < items.length; i++) {
            const item = items[i]

            let place_id = ''
            if (item.place_id) {
              place_id = item.place_id
            }

            let userLocation = false

            if (place_id !== '' && userPlaceId === place_id) {
              userLocation = true
              userLocationFound = true
            }

            const itemData = {
              id: i + 1,
              name: item.title,
              website: item.url,
              userLocation: userLocation
              // place_id: item.placeId
            }

            if (i < limit) {
              list4.push(itemData)
            }
            else if (userLocation === true) {
              // remove last item from list4
              list4.pop()
              list4.push(itemData)
            }
          }

          if (userLocationFound === false) {

            let userBusinessName = ''
            if (keywordsDocData.data && keywordsDocData.data.businessname) {
              userBusinessName = keywordsDocData.data.businessname
            }
            let userWebsite = ''
            if (keywordsDocData.data && keywordsDocData.data.website) {
              userWebsite = keywordsDocData.data.website
            }

            const userItemData = {
              id: 101,
              name: userBusinessName,
              website: userWebsite,
              userLocation: true
              // place_id: item.placeId
            }

            list4.pop()
            list4.push(userItemData)

          }
        }
        // console.log('DATA', JSON.stringify(items))
      }
    }


    return (

      <Box
        sx={{
          // width: gridItemInfoStyle.width + 'px',
          width: '400px',
          // backgroundColor: 'orange',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          // p: 1,
          // pt: 2,
          // pb: 1,
          // pl: 1,
          pr: 2,
          height: '100%'
        }}
      >

        {list4.map(function (listItem, i) {

          let name = listItem.name
          const userLocation = listItem.userLocation
          // if (name.length > 90) {
          //   // get first 89 characters
          //   const newName = name.substr(0, 89)
          //   name = newName + '...'
          // }

          // const pos = i + 1

          const ratingPos = listItem.id

          // grey 8c8c8c
          let fillColor = 'rgba(140, 140, 140, 1)'
          // green 2FA626
          if (ratingPos >= 1 && ratingPos <= 3) {
            fillColor = 'rgba(47, 166, 38, 1)'
          }
          // orange FF8C00
          if (ratingPos >= 4 && ratingPos <= 10) {
            fillColor = 'rgba(255, 140, 0, 1)'
          }

          let pos = '' + listItem.id
          if (listItem.id > 20) {
            pos = '21+'
          }

          return (
            <Box
              key={pos}
              sx={{
                display: 'flex',
                fontSize: '15px',
                // p: 1,
                // pt: 1,
                // pb: 1,
                pb: '12px',
                // pl: 2,
                // pr: 2,
                // bgcolor: '#cc0000'
                //bgcolor: 'background.paper',
                // borderRadius: 1
              }}
            >
              <Box
                sx={{
                  // bgcolor: 'blue'
                }}
              >
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '24px',
                  height: '24px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  // backgroundColor: theme.palette.success.main,
                  backgroundColor: fillColor,
                  borderRadius: '3px',
                  fontSize: pos === '21+' ? '12px' : '14px',

                }}>
                  {pos}
                </Box>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  // bgcolor: 'purple',
                  pl: '10px',
                  pt: '3px',
                  color: userLocation === true ? '#fff' : theme.palette.grey[400],
                  fontWeight: 400 + ' !important'
                }}>
                {name}
              </Box>
            </Box>
          )


        })}



      </Box>



    )
  }

  return (

    <Box
      name={'GridItemInfo'}
      sx={{
        width: '400px',
        // width: gridItemInfoStyle.width + 'px',
        // height: gridItemInfoStyle.height + 'px',
        backgroundColor: 'black',
        color: 'white',
        borderRadius: '5px',
      }}
    >
      {loaded && gridItemsData[scanId] ?
        gridItemContent()
        :
        <Box
          sx={{
            height: '145px',
            // height: '80px',
            // width: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pb: '12px',
            pr: '12px'
          }}
        >

          {
//             <Typography
//               sx={{
//                 color: '#fff'
//               }}
//             >
//               Loading...
//             </Typography>

              <CircularProgress
                size={24}
                sx={{
                  color: theme.palette.success.main,
                }}
              />
          }

        </Box>

      }
    </Box>

  )
}


export default React.memo(GridItemInfo)