import React, { useState, useEffect, useContext } from 'react'
// import PropTypes from 'prop-types';
import {
  getAuth,
  //signInWithEmailAndPassword,
  // signOut
} from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
// import { firebase } from '../code/firebase';
import { getUrlParams } from '../code/ecomacy'

// import { useTheme } from '@mui/material/styles';

import { AiAuthContext } from '../helpers/AiAuthProvider';

// import Container from '@mui/material/Container';
import AiPane from './AiPane'
import LoadingPane from './LoadingPane'

// import MainCard from './MainCard';
// import './GridKeywordsPane.css'
// import { CustomerAuthContext } from '../helpers/CustomerAuthProvider';


const AiContainer = (routerData) => {

  // const theme = useTheme();
  // const auth = getAuth(firebase)

  // const [user, loadingUser, errorUser] = useAuthState(auth);

  const { testVal, setTestVal } = useContext(AiAuthContext);

  const { aiSettingsDocs, setAiSettingsDocs } = useContext(AiAuthContext);
  const { getAiSettingsDocs } = useContext(AiAuthContext);

  const { aiSettingsSelected, setAiSettingsSelected } = useContext(AiAuthContext);
  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);
  const { aiSettingsDataRef } = useContext(AiAuthContext);
  const { aiSettingsDataUpdated, setAiSettingsDataUpdated } = useContext(AiAuthContext);


  const { getAiSettings } = useContext(AiAuthContext);

  const [init, setInit] = useState(true)
  const [loading, setLoading] = useState(false)

  const [initSettings, setInitSettings] = useState(true)

  // const userDataParams = getUrlParams()
  const userData = {
    locationid: 'yEz3dNwupKApQykDTKPU',
  }
  useEffect(() => {


    // declare the data fetching function
    const fetchAiSettingsDocs = async () => {


      const returnData = await getAiSettingsDocs(userData);
      if (returnData && returnData.data) {
        // set in getAiSettingsDocs function
        // setAiSettingsData(returnData.data)

        // if aisettings exist, set the first one as selected
        if (returnData.data.length > 0) {
          setAiSettingsSelected(returnData.data[0])
        }

        setInit(false)
      }
      else {
        setInit(false)
      }
    }

    if (init) {
      // call the function
      fetchAiSettingsDocs()
        // make sure to catch any error
        .catch(console.error);
    }
  }, [init])



  useEffect(() => {
    // declare the data fetching function
    const fetchAiSettingsData = async () => {
      const returnData = await getAiSettings(userData, aiSettingsSelected);
      if (returnData && returnData.data) {
        // debugger
        // setAiSettingsData(returnData.data)
        // set ref for comparisons after settings save
        aiSettingsDataRef.current = returnData.data
        setAiSettingsDataUpdated(returnData.data)
        setInitSettings(false)
      }
      else {
        setInitSettings(false)
      }
    }

    if (
      !init &&
      initSettings &&
      aiSettingsSelected !== null
    ) {

      // call the function
      fetchAiSettingsData()
        // make sure to catch any error
        .catch(console.error);
    }
  }, [aiSettingsSelected, initSettings])


  //  useEffect(() => {
  //    // declare the data fetching function
  //    const fetchAiSettingsData = async () => {
  //      const returnData = await getAiSettings({});
  //      if (returnData && returnData.data) {
  //        setAiSettingsData(returnData.data)
  //        setAiSettingsDataUpdated(returnData.data)

  //        setInit(false)
  //      }
  //      else {
  //        setInit(false)
  //      }
  //    }

  //    if (init) {
  //      // call the function
  //      fetchAiSettingsData()
  //        // make sure to catch any error
  //        .catch(console.error);
  //    }
  //  }, [init])

  //  const fetchAdminPlanData = useCallback(async () => {
  //    debugger
  //
  //    if (adminUserData.isAdmin === true && adminUserData.userDoc && customersData.loaded === true) {
  //      getAdminPlanData()
  //    }
  //
  //  }, [])

  if (aiSettingsData) {
    // console.log('AiContainer')
    return (
      <AiPane
        userData={userData}
      />
    )
  }


  return (
    <LoadingPane
    // userData={userData}
    />
  )





};


export default React.memo(AiContainer)