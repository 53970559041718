import { initializeApp } from "firebase/app"
import { getStorage } from 'firebase/storage'
import {
    // collection,
    // doc,
    // getDoc,
    // getDocs,
    getFirestore
} from 'firebase/firestore'
import { getAuth, connectAuthEmulator, signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth"
// import { getAuth, signInWithCustomToken } from "firebase/auth"
import { getFunctions, httpsCallable } from "firebase/functions"
import { getUrlParams } from './ecomacy'
const axios = require('axios')

const userData = getUrlParams()

let location_id = ''
if (userData.location_id !== '') {
    location_id = userData.location_id
}
let email = ''
if (userData.email !== '') {
    email = userData.email
}

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
}



// Initialise Firebase
const firebase = initializeApp(firebaseConfig)
const projectStorage = getStorage()
const db = getFirestore(firebase)
const auth = getAuth()

const functions = getFunctions(firebase, 'europe-west1')
// const functions = getFunctions(firebase)

// connect to local auth
// connectAuthEmulator(auth, "http://localhost:9099")
// connectAuthEmulator(auth, "http://localhost:5000")


const initialiseFirebase = async (req, res, next) => {
    authenticate()
}

// const test = async (req, res, next) => {
//     const docRef = doc(db, "keywords", 'HwxnNn22f6hjIk7h2vbQ')
//     const snapshot = await getDoc(docRef)
//     let snapshot_data = snapshot.data()
// }

// test()
const getAuthorisation = async (data) => {
    let returnData = {
        status: 0,
        data: {},
        error: {}
    }
    try {

        // const URL = process.env.REACT_APP_ECOMACYAPI
        let URL = 'https://api.ecomacy.com/'
        // console.log('process.env.REACT_APP_ENV:', process.env.REACT_APP_ENV)
        if (process.env.REACT_APP_ENV === 'dev') {
            URL = 'http://localhost:8080/'
        }

        console.log('requestLocationFromApi URL', URL)
        console.log('jwt token', data)
        console.log('URL', URL + 'data')
        const jwt = data.jwt
        const payload = data.payload
        const response = await axios.post(
            URL + 'location',
            payload,
            {
                headers: {
                    "Authorization": jwt
                }
            }
        )
        // cLog(response)
        // cLog(response.data)
        if (response.status === 200) {
            const responseData = response.data

            if (responseData.status === 200) {
                returnData.status = responseData.status
                returnData.data = responseData.data

            }
            else {
                returnData.status = responseData.status
                returnData.error = responseData.error
            }
        }
        else {
            returnData.status = response.status
            returnData.error = response.error
        }

        return returnData


    } catch (err) {
        console.error(err);
        returnData.status = 491
        returnData.error = err
    }
}


// auth user.
// if they change their email we will loose access
const authenticate = async (req, res, next) => {
    try {

        // if admin don't auto-login from url params
        if (location_id === 'admin') {
            return
        }

        const user = auth.currentUser;
        if (user) {
            console.log('email:', email)
            console.log('location_id:', location_id)
            console.log('user:', user)
            return user
        } else {
            console.log('email:', email)
            console.log('location_id:', location_id)

            //
            // call cloud function - seems to have cors issues
            //
            const authorise = httpsCallable(functions, 'authorise');
            const token = await authorise({ loc: location_id })
            console.log('token:', token)

            // const newuser = await signInWithEmailAndPassword(auth, email, location_id)
            const newuser = await signInWithCustomToken(auth, token.data)

            console.log('newuser:', newuser)
            return newuser
        }
    } catch (err) {
        console.error(err);
    }
}
// BT: better way to do this?
authenticate()


const getUserToken = async () => {
    try {
        // const currentUser = Firebase.auth().currentUser
        const currentUser = auth.currentUser;
        const token = await currentUser.getIdToken();

        return token
        // if (currentUser) {
        //     return currentUser
        // } else {
        //     const newuser = await signInWithEmailAndPassword(auth, email, location_id)
        //     return newuser
        // }
    } catch (err) {
        console.error(err);
    }
}




// testDb()

//
// save keywords, and grids
//

/*
const saveKeywordData = async (payload) => {
    try {

        // get new grid from firestore

        // loop over set and call dfs to strat search
        // rember to pass callback with key to update on returned call

        post_array.push({
            "language_code": "en",
            "location_code": 2840,
            "keyword": encodeURI("albert einstein")
        })

        const offerRef = db.collection('offers').doc(offerid)
        const doc = await offerRef.get();
        if (!doc.exists) {
            return ({
                error: 'No such document!'
            })
        }

        return {
            data: doc.data()
        }

    } catch (error) {
        return ({
            error: error
        })
    }
}
*/

export {
    auth,
    firebase,
    initialiseFirebase,
    projectStorage,
    db,
    getUserToken
}

