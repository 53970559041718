import React, { useState } from 'react';
// import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LocationIcon from '@mui/icons-material/NearMe';
import {
  Box,
  Typography,
} from '@mui/material';
import {
  processGridItemScanDataForTable
} from '../code/googleMapsGrid';

//import { useTheme } from '@mui/material/styles';
import GridItemTable from './GridItemTable'
import GridItemGoogleMap from './GridItemGoogleMap'

export default function GoogleMapPaneDialog(props) {
  // const theme = useTheme();

  const { openDialog, handleDialogClose, gridItemScanData, gridItemParams, userData, keywordsDocData } = props;

  let userPlaceId = ''
  if (userData && userData.googleplaceid) {
    userPlaceId = userData.googleplaceid
  }

  // process gridItemScanData
  const processedData = processGridItemScanDataForTable(gridItemScanData, gridItemParams, userPlaceId, keywordsDocData)
  // console.log('gridItemScanData:', JSON.stringify(gridItemScanData))

  // eslint-disable-next-line
  const [tableData, setTableData] = useState(processedData)

  let title = ''
  if (gridItemParams && gridItemParams.title) {
    title = gridItemParams.title
  }

  let lat = null
  let latRnd = null
  if (gridItemParams && gridItemParams.pos && gridItemParams.pos.lat) {
    lat = gridItemParams.pos.lat
    latRnd = lat.toFixed(7)
  }

  let lng = null
  let lngRnd = null
  if (gridItemParams && gridItemParams.pos && gridItemParams.pos.lng) {
    lng = gridItemParams.pos.lng
    lngRnd = lng.toFixed(7)
  }


  return (
    <Dialog
      onClose={handleDialogClose}
      open={openDialog}
      maxWidth={'md'}
      fullWidth
      sx={{
        minWidth: '500px',
        minHeight: '300px'

      }}
    >



      <Box
        id={'DialogHeader'}
        sx={{
          position: 'relative',
          zIndex: 2,
          display: 'flex',
          flexDirection: 'row',
          // mb: 2,
          // width: `calc(100% - 144px)`
        }}
      >

        <Box
          sx={{
            flexGrow: 1,
            pt: 3,
            pl: 3
          }}
        >
          <Typography
            variant='h3'
          >
            Results: {title !== '' ? '"' + title + '"' : ''}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            pt: 1,
            pr: 1
          }}
        >

          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              // position: 'absolute',
              // right: 8,
              // top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          // justifyContent: 'center',
          alignItems: 'center',
          // minHeight: '500px',
          pl: 3,
          pr: 3,
          mt: 1,
          mb: 2,
        }}
      >
        <LocationIcon
          sx={{
            fontSize: 18
          }}
        />
        Search Location - Latitude: {latRnd}, Longitude: {lngRnd}.
      </Box>

      <Box
        sx={{
          // minHeight: '500px',
          pl: 3,
          pr: 3,
          pb: 3,
        }}
      >



        <GridItemTable
          tableData={tableData}
          userPlaceId={userPlaceId}
          gridItemParams={gridItemParams}
        />

        <Box sx={{pt: 3}}></Box>

        <GridItemGoogleMap
          tableData={tableData}
          gridItemParams={gridItemParams}
          userPlaceId={userPlaceId}
        />

      </Box>

    </Dialog>
  );
}

