import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types';
import {
  getAuth,
  //signInWithEmailAndPassword,
  // signOut
} from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebase } from '../code/firebase';
import { getUrlParams } from '../code/ecomacy'

// import { useTheme } from '@mui/material/styles';
// import Container from '@mui/material/Container';

import SettingsKeywordsWrapper from './SettingsKeywordsWrapper'
import SettingsPaneLoading from './SettingsPaneLoading'

// import MainCard from './MainCard';

const SettingsContainer = (routerData) => {

  // const theme = useTheme();
  const auth = getAuth(firebase)

  const [user, loadingUser, errorUser] = useAuthState(auth);

  // BT: this should come from url
  // const tempUserId = "MbD0GzwDZONMouvApsbE3lgQEqy2"
  // const tempLocationId = "NG8AEc8nheLEMNYfo3dn"
  /*
  let userId = ''
  if (routerData.props && routerData.props.userId) {
    userId = routerData.props.userId
  }

  let locationId = ''
  if (routerData.props && routerData.props.locationId) {
    locationId = routerData.props.locationId
  }

  let lat = ''
  if (routerData.props && routerData.props.lat) {
    lat = routerData.props.lat
  }

  let lng = ''
  if (routerData.props && routerData.props.lng) {
    lng = routerData.props.lng
  }

  let website = ''
  if (routerData.props && routerData.props.website) {
    website = routerData.props.website
  }

  let postcode = ''
  if (routerData.props && routerData.props.postcode) {
    postcode = routerData.props.postcode
  }
  */
  let userDataObj = {
    userId: '',
    locationId: '',
    email: '',
    // lat: null,
    // lng: null,
    // website: '',
    // postcode: '',
  }
  const [userData, setUserData] = useState({
    userId: '',
    locationId: '',
    email: '',
    // lat: null,
    // lng: null,
    // website: '',
    // postcode: '',
  });

  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    if (
      userDataObj.userId !== ''
      && userDataObj.locationId !== ''
      // && userDataObj.email !== ''
      && userLoaded === false
    ) {
      setUserData(userDataObj)
      setUserLoaded(true)
    }
  }, [userDataObj])


  if (errorUser) {
    // return (
    //   <Container maxWidth={false}>
    //     <p>Error User: {errorUser}</p>
    //   </Container>
    // )
    return (
      <SettingsPaneLoading
        loading={''}
        error={'Error User: ' + errorUser}
      />
    )
  }

  if (loadingUser) {
    // return (
    //   <Container maxWidth={false}>
    //     Loading User...
    //   </Container>
    // )
    return (
      <SettingsPaneLoading
        loading={'Loading User'}
        loadingProgress={0}
        error={''}
      />
    )
  }

  if (user) {
    // userDataObj = {
    //   userId: userId,
    //   locationId: locationId,
    //   lat: lat,
    //   lng: lng,
    //   website: website,
    //   postcode: postcode,
    // }

    // get location_id from url
    const userDataParams = getUrlParams()

    // userDataObj = {
    //   userId: userId,
    //   locationId: locationId,
    //   lat: lat,
    //   lng: lng,
    //   website: website,
    //   postcode: postcode,
    // }

    // trigger state change
    userDataObj = {
      userId: user.uid,
      email: userDataParams.email,
      locationId: userDataParams.location_id
    }


    if (userData.userId !== '' && userData.locationId !== '') {
      return (
        <SettingsKeywordsWrapper
          userData={userData}
        />
      )
    }
    else {
      //  return (
      //    <Container  maxWidth={false}>
      //    State Updating...
      //  </Container>
      //  )
      return (
        <SettingsPaneLoading
          loading={''}
          error={'User Data Missing: ' + userData}
        />
      )
    }

  }
  else {
    // not able to log in contact support
    return (
      <SettingsPaneLoading
        loading={''}
        error={'Unable to log in. Please contact support'}
      />
    )
  }




};


export default React.memo(SettingsContainer)