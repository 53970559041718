import React, {
  // useState,
  // useEffect,
  // useCallback
} from 'react';

import ScrollTop from './components/ScrollTop';
import ThemeCustomization from './themes';

// import Routes from 'routes';
import Routes from './routes/Routes';
import AdminRoutes from './routes/AdminRoutes';
import AiRoutes from './routes/AiRoutes';

import { getUrlParams } from './code/ecomacy'

import { AdminAuthProvider } from './helpers/AdminAuthProvider';
import { CustomerAuthProvider } from './helpers/CustomerAuthProvider';
import { AiAuthProvider } from './helpers/AiAuthProvider';

const App = () => {

  // const [userData, setUserData] = useState(tempUserData)
  // const [loaded, setLoaded] = useState(true)
  const userDataParams = getUrlParams()

  if (userDataParams && userDataParams.location_id && userDataParams.location_id === 'admin') {
    return (
      <ThemeCustomization>
        <ScrollTop>
          <AdminAuthProvider>
            <AdminRoutes
            // userData={userData}
            />
          </AdminAuthProvider>
        </ScrollTop>
      </ThemeCustomization>
    )
  }
  else if (userDataParams && userDataParams.location_id && userDataParams.location_id === 'ai') {
    return (
      <ThemeCustomization>
        <ScrollTop>
          <AiAuthProvider>
            <AiRoutes
            // userData={userData}
            />
          </AiAuthProvider>
        </ScrollTop>
      </ThemeCustomization>
    )
  }
  else {
    return (
      <ThemeCustomization>
        <ScrollTop>
          <CustomerAuthProvider>
            <Routes
            // userData={userData}
            />
          </CustomerAuthProvider>
        </ScrollTop>
      </ThemeCustomization>
    )
  }


}

export default App;
