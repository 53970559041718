import React, {
  useState,
  useEffect,
  useContext,
  // useCallback
} from 'react'
import PropTypes from 'prop-types';

import {
  // getAuth,
  //signInWithEmailAndPassword,
  // signOut
} from 'firebase/auth';
// import { useAuthState } from 'react-firebase-hooks/auth';

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebase } from '../code/firebase';
import { processKeywordsUserData } from '../code/ecomacy';

import { AiAuthContext } from '../helpers/AiAuthProvider';

import _ from 'lodash';

import {
  useTheme,
  // styled
} from '@mui/material/styles';

import {
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
  Grid,
  Paper,
  Slider,
  TextField,
} from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


// ContentCopy
// FileCopy

import FileCopyIcon from '@mui/icons-material/FileCopy';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';

import Container from '@mui/material/Container';
import GridKeywordTimelinePane from './GridKeywordTimelinePane'
import LoadingPane from './LoadingPane'


const settingsList = [
  {
    key: 'gpt35turbo',
    model: 'gpt-3.5-turbo'
  },
  {
    key: 'gpt35turbo0301',
    model: 'gpt-3.5-turbo-0301'
  },
]

const AiSettingsPanel = (props) => {
  const theme = useTheme();

  const { testVal, setTestVal } = useContext(AiAuthContext);
  const { aiSettingsDocs, setAiSettingsDocs } = useContext(AiAuthContext);
  const { aiSettingsSelected, setAiSettingsSelected } = useContext(AiAuthContext);

  const { aiConversationsDocsRefresh, setAiConversationsDocsRefresh } = useContext(AiAuthContext);

  const { getAiSettings } = useContext(AiAuthContext);
  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);
  const { aiSettingsDataRef } = useContext(AiAuthContext);
  const { aiSettingsDataUpdated, setAiSettingsDataUpdated } = useContext(AiAuthContext);
  const { duplicateAiSettings } = useContext(AiAuthContext);

  const { aiShowDescriptionPanel, setAiShowDescriptionPanel } = useContext(AiAuthContext);

  const { aiModelSelectedRefresh, setAiModelSelectedRefresh } = useContext(AiAuthContext);
  const { aiStrategySelectedRefresh, setAiStrategySelectedRefresh } = useContext(AiAuthContext);

  const [aiSettingsDocsRefresh, setAiSettingsDocsRefresh] = useState('')
  const [init, setInit] = useState(true)



  const [model, setModel] = useState({})

  // console.log('AiModelPanel aiSettingsData', aiSettingsData);
  /*
    useEffect(() => {

      if (init) {
        // debugger
        // set menu
        // find aiSettingsDataUpdated
        let selectedModel = ''
        if (
          aiSettingsDataUpdated &&
          aiSettingsDataUpdated.data &&
          aiSettingsDataUpdated.data.model &&
          aiSettingsDataUpdated.data.model !== '') {

          selectedModel = aiSettingsDataUpdated.data.model

          var foundIdx = _.findIndex(modelList, { model: aiSettingsDataUpdated.data.model });
          if (foundIdx > -1) {
            const foundModel = modelList[foundIdx]
            setAiModelSelected(foundModel)
            setModel(aiSettingsDataUpdated[selectedModel])
          }
        }

        setInit(false)
      }
      // if (map) {
      //   updateBounds(map)
      //   // handleRecenterMapReset()
      // }
    }, [init])
  */

  // const userDataParams = getUrlParams()
  const userDataParams = {
    locationid: 'yEz3dNwupKApQykDTKPU',
  }

  useEffect(() => {
    if (init) {
      setInit(false)
    }
  }, [init])

  useEffect(() => {
    // declare the data fetching function
    const fetchAiSettingsData = async () => {
      const returnData = await getAiSettings(userDataParams, aiSettingsSelected);
      if (returnData && returnData.data) {
        // setAiSettingsData(returnData.data)
        setAiSettingsDataUpdated(returnData.data)
        // set ref for comparisons after settings save
        aiSettingsDataRef.current = returnData.data
        // trigger useEffect in AiModelPanel
        setAiModelSelectedRefresh(true)
        // trigger useEffect in AiQuestionsPanel
        setAiStrategySelectedRefresh(true)
      }
      // else {
      //   setInitSettings(false)
      // }
    }
    if (
      !init &&
      // initSettings &&
      aiSettingsSelected !== null
    ) {

      // call the function
      fetchAiSettingsData()
        // make sure to catch any error
        .catch(console.error);

        // trigger useEffect in AiHistoryPanel
        setAiConversationsDocsRefresh(true)
    }
  }, [aiSettingsSelected])


  useEffect(() => {
    if (!init && aiSettingsDocsRefresh !== '') {
      var foundIdx = _.findIndex(aiSettingsDocs, { aisettingsid: aiSettingsDocsRefresh });
      if (foundIdx > -1) {
        const foundSettings = aiSettingsDocs[foundIdx]
        setAiSettingsSelected(foundSettings)
      }
      setAiSettingsDocsRefresh('')
    }
  }, [aiSettingsDocsRefresh])


  const handleOnChangeSettings = (event) => {
    const val = event.target.value;

    var foundIdx = _.findIndex(aiSettingsDocs, { aisettingsid: val });
    if (foundIdx > -1) {
      const foundSettings = aiSettingsDocs[foundIdx]
      setAiSettingsSelected(foundSettings)
    }
  }


  const getSelectedSettingsId = () => {
    let selectedSettings = ''
    if (
      aiSettingsSelected &&
      aiSettingsSelected.aisettingsid &&
      aiSettingsSelected.aisettingsid !== '') {
      selectedSettings = aiSettingsSelected.aisettingsid
    }

    return selectedSettings
  }


  const handleOnClickDuplicate = async (event) => {
    console.log('handleOnClickDuplicate')
    const result = await duplicateAiSettings()

    if (result && result.data && result.data.aisettingsid && result.data.aisettingsid !== '') {
      const val = result.data.aisettingsid

      // trigger useEffect in AiSettingsPanel to load new settings
      setAiSettingsDocsRefresh(val)
    }

  }


  const isSaveDisabled = () => {
    // compare aiSettingsDataUpdated to aiSettingsData using lodash
    // if different, enable save button
    // if same, disable save button
    // debugger
    let isDisabled = true
    if (
      aiSettingsDataUpdated &&
      // aiSettingsDataUpdated.data &&
      aiSettingsData &&
      // aiSettingsData.data &&
      // !_.isEqual(aiSettingsDataUpdated.data, aiSettingsData.data)
      !_.isEqual(aiSettingsDataUpdated, aiSettingsData)
    ) {
      isDisabled = false
    }

    return isDisabled
  }

  const handleOnClickSave = (event) => {
    console.log('handleOnClickSave')
  }

  const handleOnChangeData = (event, key) => {

    const val = event.target.value

    console.log('event:', event.target.value)

    // if id is already taken then warn user
    if (key === 'aisettingsid') {
      // check if name id already exists
      var foundIdx = _.findIndex(aiSettingsDocs, { aisettingsid: val });
      if (foundIdx > -1) {
        // aisettingsid already exists
        // show warning
        // debugger
        // alert('Name ID already exists')
        // return
      }
    }

    let data = _.cloneDeep(aiSettingsDataUpdated.data)
    // update name
    data[key] = val

    setAiSettingsDataUpdated({
      ...aiSettingsDataUpdated,
      data: data
    })

  }

  const handleOnClickShowDescriptionPanel = () => {
    setAiShowDescriptionPanel(!aiShowDescriptionPanel)
  }

  return (



    <Box
      name="AiSettingsTopBar"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 70,
      }}
    >

      <Box
        sx={{
          // ml: 2,
          // mr: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: 100,
          }}
          // disabled={loading}
          // size="small"
          onClick={handleOnClickShowDescriptionPanel}
          // disabled={isDisabled()}
          // startIcon={<FileCopyIcon />}
        >
          {aiShowDescriptionPanel ? 'Hide Info' : 'Show Info'}
        </Button>
      </Box>
      <Box
        flexGrow={1}
        sx={{
          // ml: 2
        }}
      >
        {
          //  <TextField
          //    // margin="normal"
          //    required
          //    fullWidth
          //    // id="email"
          //    label="Description"
          //    name="description"
          //    autoComplete="description"
          //    // autoFocus
          //    value={aiSettingsDataUpdated.data.description}
          //    onChange={(e) => handleOnChangeData(e, 'description')}
          //  />
        }


      </Box>

      <Box
        width={300}
      >
        <TextField
          // margin="normal"
          required
          fullWidth
          // id="email"
          label="Name"
          name="name"
          autoComplete="name"
          // autoFocus
          value={aiSettingsDataUpdated.data.name}
          onChange={(e) => handleOnChangeData(e, 'name')}
        />
      </Box>

      <Box
        sx={{
          ml: 2,
          mr: 2,
        }}
      >
        <Button
          variant="contained"
          // sx={buttonSx}
          // disabled={loading}
          // size="small"
          onClick={handleOnClickDuplicate}
          // disabled={isDisabled()}
          startIcon={<FileCopyIcon />}
        >
          Duplicate
        </Button>
      </Box>

      <Box>
        <Button
          sx={{
            mr: 2
          }}
          variant="contained"
          // sx={buttonSx}
          // disabled={loading}
          // size="small"
          onClick={handleOnClickSave}
          disabled={isSaveDisabled()}
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
      </Box>

      <Box
        width={300}
      // sx={{
      //   display: 'flex',
      //   alignItems: 'center',
      // }}
      >
        <FormControl sx={{
          // mt: 3,
          // m: 1,
          // minWidth: 120
          width: '100%'
        }}>
          <InputLabel>Presets</InputLabel>
          <Select
            label="Presets"
            // value={'gpt35turbo'}
            value={getSelectedSettingsId()}
            onChange={(event) => handleOnChangeSettings(event)}
          >
            {
              aiSettingsDocs.map((m, index) => {
                return (
                  <MenuItem key={m.aisettingsid} value={m.aisettingsid}>{m.name}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
      </Box>
    </Box>



  );

};


export default React.memo(AiSettingsPanel)