import React from 'react';

import PropTypes from 'prop-types';

// material-ui
import { Box, Chip, Grid, Stack, Typography } from '@mui/material';

// project import
import MainCard from '../../MainCard';

// assets
import { FallOutlined, RiseOutlined } from '@ant-design/icons';

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

const AnalyticTokensUsed = ({
  color = 'primary',
  title,
  // count,
  percentage,
  // isLoss,
  extra,
  tokensIncluded,
  tokensLeftThisMonth,
  hasData
}) => {


  // title,
  const count = '123'
  // percentage,
  // isLoss,
  // extra

  if (hasData) {
    return (
      <MainCard contentSX={{ p: 2.25 }}>
        <Stack spacing={0.5}>
          <Typography
            variant="h6"
            color="inherit"
            sx={{ fontWeight: 'bold' }}
          >
            {title}
          </Typography>
          <Grid container alignItems="center">
            <Grid item>
              <Typography
                variant="h4"
                sx={{ color: `${color || 'primary'}.main` }}
              // color="inherit"
              // sx={{ color: `${color || 'primary'}.main` }}
              >
                {tokensLeftThisMonth}

                <Typography
                  variant="h6"
                  component="span"
                  // color="inherit"
                  color="textSecondary"
                >
                  {' / '} {tokensIncluded}
                </Typography>

              </Typography>

            </Grid>
            {
              // percentage && (
              //   <Grid item>
              //     <Chip
              //       variant="combined"
              //       color={color}
              //       icon={
              //         <>
              //           {!isLoss && <RiseOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />}
              //           {isLoss && <FallOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />}
              //         </>
              //       }
              //       label={`${percentage}%`}
              //       sx={{ ml: 1.25, pl: 1 }}
              //       size="small"
              //     />
              //   </Grid>
              // )
            }
          </Grid>
        </Stack>
        <Box sx={{ pt: 0 }}>
          <Typography variant="caption" color="textSecondary">
            You have used {' '}
            <Typography component="span" variant="caption" sx={{ color: `${color || 'primary'}.main` }}>
              {tokensIncluded - tokensLeftThisMonth}
            </Typography>{' '}
            of {' '}
            <Typography component="span" variant="caption" sx={{ color: `${color || 'primary'}.main` }}>
              {tokensIncluded}
            </Typography>{' '} monthly tokens
          </Typography>
        </Box>
      </MainCard>
    )
  }
  else {
    return (
      <MainCard contentSX={{ p: 2.25, height: '136px' }}>
        <Stack spacing={0.5}>
          <Typography variant="h6" color="inherit">
            {title}
          </Typography>
          <Grid container alignItems="center">
            <Grid item>
              <Typography
                variant="h4"
                color="textSecondary"
              // color="inherit"
              // sx={{ color: `${color || 'primary'}.main` }}
              >
                No Data
              </Typography>
            </Grid>
          </Grid>
        </Stack>

      </MainCard>
    )
  }



};

AnalyticTokensUsed.propTypes = {
  title: PropTypes.string,
  // count: PropTypes.number,
  percentage: PropTypes.number,
  isLoss: PropTypes.bool,
  color: PropTypes.string,
  extra: PropTypes.string,
  tokensIncluded: PropTypes.number,
  tokensLeftThisMonth: PropTypes.number,
  hasData: PropTypes.bool
};

export default AnalyticTokensUsed;
