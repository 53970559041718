import React, { createContext, useState, useRef } from 'react';
// import auth from '@react-native-firebase/auth';
import { getUserToken } from '../code/firebase'

import {
  getAuth,
  connectAuthEmulator,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut
} from "firebase/auth"

import {
  // collection,
  doc,
  setDoc,
  updateDoc,
  onSnapshot,
  // getDoc,
  getDocs,
  getFirestore,
  collection,
  query,
  where
} from 'firebase/firestore'

import _ from 'lodash';
import { nanoid } from 'nanoid'


import { getFunctions, httpsCallable } from "firebase/functions"
import { useAuthState } from 'react-firebase-hooks/auth';

import { getUrlParams } from '../code/ecomacy'
import { firebase } from '../code/firebase';

const axios = require('axios')

// BT: where to get this from?
const tokenPriceInit = 0.012;
const monthlyPriceMinInit = 20;

const userData = getUrlParams()

let location_id = ''
if (userData.location_id !== '') {
  location_id = userData.location_id
}
let email = ''
if (userData.email !== '') {
  email = userData.email
}

const auth = getAuth(firebase)
const functions = getFunctions(firebase, 'europe-west1')
const db = getFirestore(firebase)

/**
 * This provider is created
 * to access user in admin area
 */

const initialState = {
  // login: () => {},
  // logout: () => {},
  // authenticate: () => {},
  // getUserToken: () => {},
};


export const AdminAuthContext = createContext(initialState);
// export const AuthContext = createContext({} as ContextState);
// const Context = createContext({} as ContextState);

export const AdminAuthProvider = ({ children }) => {
  // const [user, setUser] = useState(null);
  const [testVal, setTestVal] = useState('');

  const [adminUserData, setAdminUserData] = useState({
    loaded: false,
    isAdmin: false,
    userDoc: {},
    memberships: [],
    error: null
  });

  const [adminMembershipsData, setAdminMembershipsData] = useState([]);
  const [customersEcData, setCustomersEcData] = useState({
    data: null,
    error: null,
    loaded: false,
  });

  const [customersData, setCustomersData] = useState({
    data: null,
    // dataOrig: null,
    error: null,
    loaded: false,
    // synced: false
  });

  const [adminPlanData, setAdminPlanData] = useState({
    data: {},
    error: null,
    loaded: false
  });

  // const customersDataOrigObj = useRef(null);
  const [customersDataOrig, setCustomersDataOrig] = useState(null);

  const [customersDataSynced, setCustomersDataSynced] = useState(false);

  const tokenPrice = useRef(tokenPriceInit);
  const monthlyPriceMin = useRef(monthlyPriceMinInit);

  const [user, loadingUser, errorUser] = useAuthState(auth);

  const getAdminUserDoc = async (uid) => {
    try {
      let userDoc = null
      debugger
      const q = query(collection(db, "users"), where("userid", "==", uid));

      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        return userDoc
      }
      snapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        // debugger
        userDoc = doc.data()
      });

      return userDoc

    } catch (err) {
      debugger
      console.error(err);
      return {
        error: err
      }

    }
  }

  const getAdminCustomerDocs = async (uid) => {
    return new Promise((resolve, reject) => {

      try {

        let customersDataOrigObject = {}
        const q = query(collection(db, "customers"), where("userid", "==", uid));
        // let customerDocs = []
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let customerDocs = []
          // const cities = [];
          querySnapshot.forEach((doc) => {
            // customerDocs.push(doc.data().name);
            const docData = doc.data()
            customerDocs.push(doc.data());

            // let customerDocClone = _.cloneDeep(docData)

            // customersDataOrigObject[docData.id] = docData
            // if (docData.id === '0PmBJQrhaR4GHno2mR5e') {
            // debugger
            // }
            // customersDataOrigObject[docData.id] = customerDocClone
          });
          // console.log("Current cities in CA: ", cities.join(", "));

          setCustomersData({
            ...customersData,
            data: customerDocs,
            // dataOrig: customerDocs,
            loaded: true,
          })

          // create customersDataOrig
          //// setCustomersDataOrig(customersDataOrigObject)

          // ref for comparison
          // let customerDocsClone = _.cloneDeep(customerDocs)

          // debugger
          resolve({
            data: customerDocs
          })
        });

        // setCustomersData({
        //   ...customersData,
        //   data: [],
        //   // dataOrig: customerDocs,
        //   loaded: true,
        // })

        // return unsubscribe

      } catch (err) {
        console.error(err);
        debugger
        setCustomersData({
          ...customersData,
          error: err,
          loaded: true,
        })

        reject({
          error: err
        })

      }
    })
  }



  const createAdminCustomerDoc = async (cData, aUid) => {
    try {

      let res = {}

      let itemData = {
        businessname: '',
        enabled: false,
        locationid: '',
        token: '',
        userid: '',
        data: {
          //  multiple:
        }
      }

      let userId = ''
      if (aUid && aUid !== '') {
        userId = aUid
      }
      itemData.userid = userId


      let businessName = ''
      if (cData && cData.businessname) {
        businessName = cData.businessname
      }
      itemData.businessname = businessName

      let locationId = ''
      if (cData && cData.locationid) {
        locationId = cData.locationid
      }
      itemData.locationid = locationId
      itemData.token = locationId

      //
      // const ref = db.collection('customers').doc();
      // itemData.id = id

      const customersRef = collection(db, 'customers') // collectionRef
      const customerRef = doc(customersRef) // docRef
      const id = customerRef.id // a docRef has an id property
      const customerData = { id, ...itemData } // insert the id among the data
      res = await setDoc(customerRef, customerData) // create the document

      return { data: res }
    } catch (error) {
      return { error: error }
    }
  }

  const createAdminCustomerDocs = async (eCustomerData, fCustomerData, adminUid) => {

    try {

      const fCustomersCount = fCustomerData.length
      const eCustomersCount = eCustomerData.length

      let failed = []

      // add extra docs
      if (eCustomersCount > fCustomersCount) {
        for (var i = 0; i < eCustomerData.length; i++) {
          const eCustomer = eCustomerData[i]
          const eCustomerLocId = eCustomer.locationid

          // look in fCustomerData where locationid
          var foundItem = _.find(fCustomerData, { locationid: eCustomerLocId });
          if (!foundItem) {
            // console.log('item not found:', eCustomerLocId);

            // Create doc
            const res = await createAdminCustomerDoc(eCustomer, adminUid)
            if (res && res.error) {
              failed.push(eCustomer)
            }
          }
          else {
            // console.log('item found:', eCustomerLocId);
          }
        }


        if (failed.length === 0) {
          setCustomersDataSynced(true)
        }

      }

      else {
        setCustomersDataSynced(true)
      }



    } catch (err) {
      console.error(err);

      // setCustomersData({
      //   ...customersData,
      //   error: err,
      //   loaded: true,
      // })
    }
  }

  const getAdminPlanDoc = async (planid) => {
    try {
      let planDoc = null
      const q = query(collection(db, "plans"), where("planid", "==", planid));

      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        return planDoc
      }
      snapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        planDoc = doc.data()
      });

      return planDoc

    } catch (err) {
      console.error(err);
      debugger
      return {
        error: err
      }

    }
  }


  const requestEcomacyLocationsFromApi = async data => {
    let returnData = {
      status: 0,
      data: {},
      error: {},
    };
    try {
      const jwt = data.jwt;
      const payload = data.payload;

      let URL = 'https://api.ecomacy.com'
      // console.log('process.env.REACT_APP_ENV:', process.env.REACT_APP_ENV)
      if (process.env.REACT_APP_ENV === 'dev') {
        URL = 'http://localhost:8080'
      }

      if (URL) {
        const apiUrlPath = URL.concat('/acl/', payload.eapi);
        // const response = await axios.get(URL + '/apguser', payload, {
        const response = await axios.get(apiUrlPath, {
          headers: {
            Authorization: jwt,
          },
        });

        // console.log('response:', response);
        // cLog(response.data)
        if (response.status === 200) {
          returnData.status = response.status;
          returnData.data = response.data;
        } else {
          returnData.status = response.status;
          returnData.error = response.error;
        }
        // console.log('6 requestMfaFromApi URL', URL);

        return returnData;
      } else {
        returnData.status = 490;
        returnData.error = 'REACT_APP_API_URL url missing from env';
        return returnData;
      }
    } catch (err) {
      console.error('response error', err);
      returnData.status = 491;
      returnData.error = err;
      return returnData;
    }
  };


  const getEcomacyLocations = async (userData) => {
    let returnData = {
      status: 200,
      data: {},
      error: {},
    };
    try {
      if (userData && userData.eapi && userData.eapi !== '') {

        // call fn API to get data

        const userToken = await getUserToken();
        const jwt = 'Bearer ' + userToken;

        // console.log('4 getEcomacyLocations:', jwt);
        const data = {
          jwt: jwt,
          payload: {
            eapi: userData.eapi,
          },
        };
        // console.log('getEcomacyLocations data:', data);

        let result = await requestEcomacyLocationsFromApi(data);
        returnData.status = result.status

        if (result.status === 200 && result.data && result.data.data) {
          const apiData = result.data.data
          returnData.status = apiData.status
          if (apiData.data) {
            returnData.data = apiData.data
          }
          else if (apiData.error) {
            returnData.error = apiData.error
          }
        }
        else {
          returnData.error = result.error.code
        }
        // let result = {status: 'success'};
        return returnData;

      }
    } catch (err) {
      console.error(err);
      returnData.status = 501;
      returnData.error = {
        val: 'Ecomacy customers data fetch failed',
      };
      // return returnData;
    }
  }

  const delay = (milliseconds) => new Promise(resolve => setTimeout(resolve, milliseconds))

  return (
    <AdminAuthContext.Provider
      value={{
        // user,
        // setUser,
        testVal,
        setTestVal,

        tokenPrice,
        monthlyPriceMin,

        adminUserData,
        setAdminUserData,
        adminMembershipsData,
        setAdminMembershipsData,
        adminPlanData,
        setAdminPlanData,
        customersData,
        setCustomersData,
        customersEcData,
        setCustomersEcData,
        customersDataSynced,
        setCustomersDataSynced,

        customersDataOrig,
        setCustomersDataOrig,

        // customersDataOrigObj,
        // loginMfa: async (props: AuthProps) => {
        login: async (email, password) => {
          try {
            // const {email, password} = props;
            // console.log('props', props);
            // console.log('email', email);
            // console.log('password', password);
            // await auth().signInWithEmailAndPassword(email, password);
            await signInWithEmailAndPassword(auth, email, password);

          } catch (e) {
            console.log(e);
          }
        },
        logout: async () => {
          try {
            await signOut(auth);
          } catch (e) {
            console.error(e);
          }
        },
        authenticate: async (req, res, next) => {
          try {

            if (location_id === 'admin') {
              return
            }

            const user = auth.currentUser;
            if (user) {
              console.log('email:', email)
              console.log('location_id:', location_id)
              console.log('user:', user)
              return user
            } else {
              console.log('email:', email)
              console.log('location_id:', location_id)

              //
              // call cloud function - seems to have cors issues
              //
              const authorise = httpsCallable(functions, 'authorise');
              const token = await authorise({ loc: location_id })
              console.log('token:', token)

              // const newuser = await signInWithEmailAndPassword(auth, email, location_id)
              const newuser = await signInWithCustomToken(auth, token.data)

              console.log('newuser:', newuser)
              return newuser
            }
          } catch (err) {
            console.error(err);
          }
        },
        getAdminUserData: async () => {

          try {
            // let userDoc = {}
            if (user && user.uid) {
              // 1: Get UserDoc
              const userDoc = await getAdminUserDoc(user.uid)
              if (userDoc && userDoc.error === undefined) {


                // user doc found
                setAdminUserData({
                  ...adminUserData,
                  userDoc: userDoc,
                  loaded: true,
                  isAdmin: true,
                })


                // set membership data
                let memberships = []
                if (userDoc && userDoc.memberships) {
                  memberships = userDoc.memberships
                }
                if (memberships.length > 0) {
                  setAdminMembershipsData(memberships)
                }

                // 2: Call ecomacy api and get customers data
                const ecomacyResult = await getEcomacyLocations(userDoc)
                // find customers in firestore
                debugger
                if (ecomacyResult && ecomacyResult.status === 200) {
                  setCustomersEcData({
                    ...customersEcData,
                    data: ecomacyResult.data,
                    loaded: true,
                  })
                }
                else {
                  setCustomersEcData({
                    ...customersEcData,
                    error: ecomacyResult.error,
                    loaded: true,
                  })
                }

                // 3: if ecomacy customers exist then find firestore customers data
                if (ecomacyResult && ecomacyResult.data && ecomacyResult.data.length > 0) {
                  // get Customer docs and set snapshot state
                  const customersResult = await getAdminCustomerDocs(user.uid)


                  let customersDataOrigObject = {}
                  // if (customersResult && customersResult.data && customersResult.data.length > 0) {
                  //   // loop through
                  //   for (var i = 0; i < customersResult.data.length; i++) {
                  //     const customerResultData = customersResult.data[i]
                  //     let customerDocClone = _.cloneDeep(customerResultData)
                  //     // customersDataOrigObject[customerResultData.id] = customerResultData
                  //     customersDataOrigObject[customerResultData.id] = customerDocClone
                  //     if (customerResultData.id === '0PmBJQrhaR4GHno2mR5e') {
                  //       // debugger
                  //     }
                  //   }
                  // }

                  // setCustomersDataOrig(customersDataOrigObject)
                  // customersDataOrigObj.current = customersDataOrigObject



                  // NOTE: State is set in getAdminCustomerDocs
                  // setCustomersData({
                  //   ...customersData,
                  //   data: customerDocs,
                  //   loaded: true,
                  // })

                  // create
                  if (customersResult && customersResult.data) {
                    const createCustomersResult = await createAdminCustomerDocs(ecomacyResult.data, customersResult.data, user.uid)

                    // loop through customersResult
                    for (var i = 0; i < customersResult.data.length; i++) {
                      const customerResultData = customersResult.data[i]
                      let customerDocClone = _.cloneDeep(customerResultData)
                      // customersDataOrigObject[customerResultData.id] = customerResultData
                      customersDataOrigObject[customerResultData.id] = customerDocClone
                    }
                  }

                  setCustomersDataOrig(customersDataOrigObject)



                  // BT: need to refetch customer docs??

                }
                else {
                  // no ecomacy customers
                  // setCustomersData({
                  //   ...customersEcData,
                  //   error: ecomacyResult.error,
                  //   loaded: true,
                  // })
                }

              }
              else if (userDoc && userDoc.error) {
                //
                setAdminUserData({ ...adminUserData, loaded: true, error: userDoc.error })
              }
              else {
                // no doc found
                setAdminUserData({
                  ...adminUserData, loaded: true, error: {
                    val: 'no doc found'
                  }
                })
              }
            }
          } catch (err) {
            console.error(err);
            setAdminUserData({ ...adminUserData, loaded: true, error: err })
          }
        },
        getAdminPlanData: async () => {

          try {
            // let userDoc = {}
            if (adminUserData && adminUserData.userDoc && adminUserData.userDoc.planid) {
              // 1: Get UserDoc
              const planDoc = await getAdminPlanDoc(adminUserData.userDoc.planid)
              if (planDoc && planDoc.error === undefined) {
                // user doc found
                setAdminPlanData({
                  ...adminPlanData,
                  data: planDoc,
                  loaded: true,
                })
              }
              else {
                // user doc found
                setAdminPlanData({
                  ...adminPlanData,
                  error: planDoc.error,
                  loaded: true,
                })
              }

            }
            else {
              // no doc found
              setAdminPlanData({
                ...adminPlanData, loaded: true, error: {
                  val: 'no plan id found'
                }
              })
            }

          } catch (err) {
            console.error(err);
            setAdminPlanData({ ...adminPlanData, loaded: true, error: err })
          }
        },
        saveAdminCustomer: async (row) => {

          try {

            // wait X milliseconds
            await delay(1000)
            // const currentUser = Firebase.auth().currentUser

            // const customersRef = collection(db, 'customers') // collectionRef
            // const customerRef = doc(customersRef) // docRef
            // const id = customerRef.id // a docRef has an id property
            // const customerData = { id, ...itemData } // insert the id among the data
            // res = await setDoc(customerRef, customerData) // create the document

            let res = {}

            let id = ''
            if (row && row.id && row.id !== '') {
              id = row.id
            }

            if (id !== '') {
              const customerRef = doc(db, "customers", id);
              res = await setDoc(customerRef, row) // create the document

              // update ref
              //

              // let row = _.cloneDeep(customerResultData)

              // customersDataOrigObj.current = {customersDataOrigObject}
              // let customersDataOrigObject = _.cloneDeep(customersDataOrig)
              // customersDataOrigObject[id] = row

              // setCustomersDataOrig(customersDataOrigObject)
              // setCustomersDataOrig({
              //   ...customersDataOrig,
              //   [id]: row
              // })

              return {
                data: row
              }
            }
            else {
              return {
                error: 'no customer doc id found'
              }
            }

          } catch (err) {
            console.error(err);
            return {
              error: err
            }
          }
        },
        saveAdminUserMemberships: async (docId, data) => {

          try {

            // wait X milliseconds
            // await delay(1000)

            let res = {}
            // update user doc
            // const usersRef = collection(db, 'users') // collectionRef
            // const docRef = doc(db, "countries", "my.custom.id@gmail.com" );

            const userRef = doc(db, "users", docId) // docRef
            // const userRef = doc(db, "users", docId);
            const membershipData = { memberships: data }
            res = await updateDoc(userRef, membershipData)

            // res = await userRef.set({
            //   memberships: data,
            // })

            const returnData = {
              data: 'success'
            }
            return returnData

          } catch (err) {
            console.error(err);
            return {
              error: err
            }
          }
        },
        createMembershipId: (membershipDataUpdated) => {

          let uuid = '';
          let useId = false
          while (useId === false) {
            // generate id
            uuid = nanoid(10);
            // check if id exists in membershipDataUpdated
            const idFound = _.findIndex(membershipDataUpdated, { membershipid: uuid });

            if (idFound === -1) {
              useId = true
              break;
            }
          }

          return uuid;

        },
        getUserToken: async () => {
          try {
            // const currentUser = Firebase.auth().currentUser
            const currentUser = auth.currentUser;
            const token = await currentUser.getIdToken();

            return token
            // if (currentUser) {
            //     return currentUser
            // } else {
            //     const newuser = await signInWithEmailAndPassword(auth, email, location_id)
            //     return newuser
            // }
          } catch (err) {
            console.error(err);
          }
        },
      }}>
      {children}
    </AdminAuthContext.Provider >
  );
};

// export default AdminAuthProvider;
