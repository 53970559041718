import React, { createContext, useState, useRef } from 'react';

import { Link } from 'react-router-dom';

import {
  useTheme,
  styled
} from '@mui/material/styles';

// material-ui
import { Grid, Stack, Typography,  Box, Container } from '@mui/material';

// project import
// import useAuth from 'hooks/useAuth';
import AuthWrapper from '../sections/auth/AuthWrapper';

import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebase } from '../code/firebase';


import AuthLogin from '../sections/auth/auth-forms/AuthLogin';
import LoadingPane from './LoadingPane'


// ================================|| LOGIN ||================================ //

const auth = getAuth(firebase)


const AdminLoginContainer = () => {
  // const { isLoggedIn } = useAuth();

  const theme = useTheme();

  const [user, loadingUser, errorUser] = useAuthState(auth);

  if (loadingUser) {
    return (
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#fff',
            border: `1px solid ${theme.palette.divider}`,
            padding: 4

          }}
        >
          <LoadingPane
            text={'Loading...'}
            showSpinner={true}
            // progress={50}
          />
        </Box>
      </Container>
    )
  }
  else {
    debugger
    return (
      <AuthWrapper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
              <Typography variant="h3">Login</Typography>
              <Typography
                component={Link}
                to={user ? '/auth/register' : '/register'}
                variant="body1"
                sx={{ textDecoration: 'none' }}
                color="primary"
              >
                Don&apos;t have an account?
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <AuthLogin />
          </Grid>
        </Grid>
      </AuthWrapper>
    );
  }

};

export default AdminLoginContainer;
