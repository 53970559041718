import React, {
  useState,
  useEffect,
  useContext,
  // useCallback
} from 'react'
import PropTypes from 'prop-types';

import {
  // getAuth,
  //signInWithEmailAndPassword,
  // signOut
} from 'firebase/auth';
// import { useAuthState } from 'react-firebase-hooks/auth';

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebase } from '../code/firebase';
import { processKeywordsUserData } from '../code/ecomacy';

import { AiAuthContext } from '../helpers/AiAuthProvider';

import _ from 'lodash';

import {
  useTheme,
  // styled
} from '@mui/material/styles';

import {
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
  Grid,
  Paper,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import Container from '@mui/material/Container';
import GridKeywordTimelinePane from './GridKeywordTimelinePane'
import LoadingPane from './LoadingPane'
import AiSettingsPanel from './AiSettingsPanel'
import AiDescriptionPanel from './AiDescriptionPanel'
import AiHistoryPanel from './AiHistoryPanel'
import AiQuestionsPanel from './AiQuestionsPanel'
import AiChatPanel from './AiChatPanel'
import AiModelPanel from './AiModelPanel'
import { BorderRight } from '@mui/icons-material';
// import { debug } from 'firebase-functions/logger';

// import MainCard from './MainCard';
// import { getDfsLocationData } from '../code/ecomacy'



// import './GridKeywordsPane.css'
// const auth = getAuth(firebase)

// SF event for oauth redirect
const handleOnClick = (event) => {
  console.log('handleOnClick SF event for oauth redirect')
  const redirect = process.env.REACT_APP_OOATH_REDIRECT
  const clientid = process.env.REACT_APP_OOATH_CLIENTID
  const scopes = [
    'contacts.write',
    'contacts.readonly',
    'conversations/message.readonly',
    'conversations/message.write',
    'conversations.write',
    'conversations.readonly',
  ]
  const scope = scopes.join(' ')

  console.log('redirect', redirect)
  console.log('clientid', clientid)
  
  const url = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${redirect}/oauth/callback&client_id=${clientid}&scope=${scope}`
  window.location.href = url;
}


const AiPane = (props) => {
  const theme = useTheme();

  const { userData } = props

  const { testVal, setTestVal } = useContext(AiAuthContext);

  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);

  const { aiShowDescriptionPanel, setAiShowDescriptionPanel } = useContext(AiAuthContext);

  // console.log('AiPane aiSettingsData', aiSettingsData);

  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // }));

  return (
    <Container
      // maxWidth="lg"
      maxWidth={false}
      sx={{
        // mt: 4,
        backgroundColor: '#fff'
      }}
    >


      {/* START: Top Toolbar */}
      <Grid container spacing={0}
        sx={{
          pb: 3,
        }}
      >
        <Grid item xs={12}
          sx={{
            // backgroundColor: 'primary.main',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: 'grey.300',
          }}
        >
          <AiSettingsPanel
            userData={userData}
          />
        </Grid>
      </Grid>
      {/* END: Top Toolbar */}





      <Grid container spacing={3}
      // sx={{
      //   pb: 3,
      // }}
      >

        {aiShowDescriptionPanel && (
          <Grid item xs={3}
            sx={{
              // backgroundColor: 'primary.main',
              borderRightWidth: '1px',
              borderRightStyle: 'solid',
              borderRightColor: 'grey.300',
            }}
          >
            <AiDescriptionPanel
              userData={userData}
            />
          </Grid>
        )}



        <Grid item xs>

          {/* START: Main Grid */}
          <Grid container spacing={3} height="calc(100vh - 160px)">

            <Grid
              item
              xs
              //xs={1}
              maxHeight={'100%'}
            // sx={{backgroundColor: 'primary.main'}}
            >
              <AiHistoryPanel
                userData={userData}
              />
            </Grid>
            <Grid
              item
              xs={4}
              maxHeight={'100%'}
            // sx={{backgroundColor: 'primary.main'}}
            >
              <Typography>Questions</Typography>
              <AiQuestionsPanel
                userData={userData}
              />
            </Grid>

            <Grid item xs={4} maxHeight={'100%'}>
              <AiChatPanel
                userData={userData}
              />
            </Grid>
            <Grid
              item
              xs
              maxHeight={'100%'}
              // minWidth="200px"
              // maxWidth="200px"
              sx={{
                minWidth: '200px !important',
              }}
            >
              <AiModelPanel
                userData={userData}
              />
            </Grid>
          </Grid>
          {/* END: Main Grid */}




        </Grid>
      </Grid>














      {
        <Button
          sx={{
            mr: 2
          }}
          variant="contained"
          // sx={buttonSx}
          // disabled={loading}
          // size="small"
          onClick={handleOnClick}
          // disabled={isDisabled()}
          startIcon={<AddIcon />}
        >
          Click Me
        </Button>
      }


    </Container >
  );

};


export default React.memo(AiPane)