import React, { useEffect } from 'react';

// import ReactDOM from "react-dom/client";
import {
  // BrowserRouter,
  Router,
  Routes,
  Route,
  // useNavigate
} from "react-router-dom";

import {
  getAuth,
  //signInWithEmailAndPassword,
  // signOut
} from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebase } from '../code/firebase';


import MainAdminLayout from '../layout/MainAdminLayout'
import AdminContainer from '../components/AdminContainer'
import AdminLoginContainer from '../components/AdminLoginContainer'

import MainLayout from '../layout/MainLayout'
//import TestPane from '../components/TestPane'
import GridKeywordsContainer from '../components/GridKeywordsContainer'
import SettingsContainer from '../components/SettingsContainer'
// import GridKeywordsPane from '../components/GridKeywordsPane'

// import AdminAuthProviderLayout from '../helpers/AdminAuthProviderLayout';
// import { AdminAuthProvider } from '../helpers/AdminAuthProvider';
// import { getUrlParams } from '../code/ecomacy'

const auth = getAuth(firebase)


// ==============================|| ROUTING RENDER ||============================== //

export default function AdminRoutes(props) {

  const [user, loadingUser, errorUser] = useAuthState(auth);

  useEffect(() => {

    // const userDataParams = getUrlParams()

    // console.log('Routes userDataParams:', userDataParams);
    // if (
    //   userDataObj.userId !== ''
    //   && userDataObj.locationId !== ''
    //   // && userDataObj.email !== ''
    //   && userLoaded === false
    // ) {
    //   setUserData(userDataObj)
    //   setUserLoaded(true)
    // }
    // else if (loaded === true && userLoaded === false) {
    //   setUserLoaded(true)
    // }

  }, [])

  // const userData = props.userData
  // const userData = {}


  if (user) {
    return (
      <Routes>
        <Route path="/" element={<MainAdminLayout />}>
          <Route
            index
            element={<AdminContainer />}
          // element={<SettingsContainer props={userData} />}
          />
        </Route>
      </Routes>
    )
  }

  else {
    return (
      <Routes>
        <Route path="/" element={<MainAdminLayout hideHeader />}>
          <Route
            index
            element={<AdminLoginContainer />}
          // element={<SettingsContainer props={userData} />}
          />
        </Route>
      </Routes>
    )
  }





  // return (
  //     <Routes>
  //       <Route path="/" element={<MainAdminLayout />}>
  //         <Route element={<AdminAuthProviderLayout />}>
  //           <Route
  //             index
  //             element={<AdminLoginContainer />}
  //           // element={<SettingsContainer props={userData} />}
  //           />
  //         </Route>
  //       </Route>
  //     </Routes>
  // )

  // return (
  //   <Router>
  //   <Routes>
  //     <Route path="/" element={<MainAdminLayout />}>
  //       <Route element={<AdminAuthProviderLayout />}>
  //         <Route
  //           index
  //           element={<AdminLoginContainer />}
  //         // element={<SettingsContainer props={userData} />}
  //         />
  //       </Route>
  //     </Route>
  //   </Routes>
  //   </Router>
  // )
};



  // return (
  //   <Routes>
  //     <Route path="/" element={<MainLayout />}>
  //       <Route
  //         index
  //         element={<GridKeywordsContainer />}
  //       // element={<SettingsContainer props={userData} />}
  //       />
  //       <Route
  //         path="settings"
  //         // element={<GridKeywordsContainer props={userData} />}
  //         element={<SettingsContainer />}
  //       />
  //     </Route>
  //   </Routes>
  // )

  // return (
  //   <Routes>
  //     <Route path="/" element={<App />}>
  //       <Route index element={<Home />} />
  //       <Route path="teams" element={<Teams />}>
  //         <Route path=":teamId" element={<Team />} />
  //         <Route path="new" element={<NewTeamForm />} />
  //         <Route index element={<LeagueStandings />} />
  //       </Route>
  //     </Route>
  //   </Routes>
  // )

