import React, {
  useState,
  useEffect,
  useContext,
  // useCallback
} from 'react'
import PropTypes from 'prop-types';

import {
  // getAuth,
  //signInWithEmailAndPassword,
  // signOut
} from 'firebase/auth';
// import { useAuthState } from 'react-firebase-hooks/auth';

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebase } from '../code/firebase';
import { processKeywordsUserData } from '../code/ecomacy';

import { AiAuthContext } from '../helpers/AiAuthProvider';

import _ from 'lodash';

import {
  useTheme,
  // styled
} from '@mui/material/styles';

import {
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
  Grid,
  Paper,
  Slider,
  TextField,
} from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import AddIcon from '@mui/icons-material/Add';

import Container from '@mui/material/Container';
import GridKeywordTimelinePane from './GridKeywordTimelinePane'
import LoadingPane from './LoadingPane'

// import MainCard from './MainCard';
// import { getDfsLocationData } from '../code/ecomacy'



// import './GridKeywordsPane.css'
// const auth = getAuth(firebase)




const AiDescriptionPanel = (props) => {
  const theme = useTheme();

  const { testVal, setTestVal } = useContext(AiAuthContext);
  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);
  const { aiSettingsDataUpdated, setAiSettingsDataUpdated } = useContext(AiAuthContext);

  // const [init, setInit] = useState(false)


  if (aiSettingsDataUpdated) {
    return (
      <Box>
        {aiSettingsDataUpdated && aiSettingsDataUpdated.data && aiSettingsDataUpdated.data.description && (
          <Box>

            <Typography
              variant="h6"
              gutterBottom
            >
              Description
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
            >
              {aiSettingsDataUpdated.data.description}
            </Typography>
          </Box>
        )}
      </Box>
    )

  }

  return (
    <></>
  );

};


export default React.memo(AiDescriptionPanel)