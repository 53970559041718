import React, {
  useState,
  useEffect,
  useContext,
  // useCallback
} from 'react'
import PropTypes from 'prop-types';

import {
  // getAuth,
  //signInWithEmailAndPassword,
  // signOut
} from 'firebase/auth';
// import { useAuthState } from 'react-firebase-hooks/auth';

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebase } from '../code/firebase';
import { processKeywordsUserData } from '../code/ecomacy';

import { AiAuthContext } from '../helpers/AiAuthProvider';

import _ from 'lodash';

import {
  useTheme,
  // styled
} from '@mui/material/styles';

import {
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
  Grid,
  Paper,
  Slider,
} from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import AddIcon from '@mui/icons-material/Add';

import Container from '@mui/material/Container';
import GridKeywordTimelinePane from './GridKeywordTimelinePane'
import LoadingPane from './LoadingPane'

// import MainCard from './MainCard';
// import { getDfsLocationData } from '../code/ecomacy'



// import './GridKeywordsPane.css'
// const auth = getAuth(firebase)
// SF

const handleOnClick = (event) => {
  console.log('handleOnClick UNUSED')
  // const redirect = 'https://a5648aadd45f17fc.p50.rt3.io'
  const redirect = 'http://localhost:3000'
  // const redirect = 'https://127.0.0.1:443'
  const clientid = '641a0f0a5be946d51183564e-lfiowjim'
  const scopes = [
    'conversations/message.readonly',
    'conversations/message.write',
    'conversations.write',
    'conversations.readonly',
  ]
  const scope = scopes.join(' ')
  const url = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${redirect}/oauth/callback&client_id=${clientid}&scope=${scope}`
  window.location.href = url;
}



const modelList = [
  {
    key: 'gpt35turbo',
    model: 'gpt-3.5-turbo'
  },
  {
    key: 'gpt35turbo0301',
    model: 'gpt-3.5-turbo-0301'
  },
]

const strategyList = [
  {
    key: 'another_chat',
    strategy: 'another_chat'
  },
  {
    key: 'question_chat',
    strategy: 'question_chat'
  },
]

const AiModelPanel = (props) => {
  const theme = useTheme();

  const { testVal, setTestVal } = useContext(AiAuthContext);
  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);
  const { aiSettingsDataUpdated, setAiSettingsDataUpdated } = useContext(AiAuthContext);
  const { aiStrategySelected, setAiStrategySelected } = useContext(AiAuthContext);
  const { aiStrategySelectedRefresh, setAiStrategySelectedRefresh } = useContext(AiAuthContext);
  const { aiModelSelected, setAiModelSelected } = useContext(AiAuthContext);
  const { aiModelSelectedRefresh, setAiModelSelectedRefresh } = useContext(AiAuthContext);

  const [init, setInit] = useState(true)

  const [aiModel, setAiModel] = useState({})


  // console.log('AiModelPanel aiSettingsData', aiSettingsData);

  useEffect(() => {

    if (init) {
      // debugger
      // set menu
      // find aiSettingsDataUpdated
      let selectedModel = ''
      if (
        aiSettingsDataUpdated &&
        aiSettingsDataUpdated.data &&
        aiSettingsDataUpdated.data.model &&
        aiSettingsDataUpdated.data.model !== '') {

        selectedModel = aiSettingsDataUpdated.data.model

        var foundModelIdx = _.findIndex(modelList, { model: aiSettingsDataUpdated.data.model });
        if (foundModelIdx > -1) {
          const foundModel = modelList[foundModelIdx]
          setAiModelSelected(foundModel)
          setAiModel(aiSettingsDataUpdated[selectedModel])
        }
      }

      let selectedStrategy = ''
      if (
        aiSettingsDataUpdated &&
        aiSettingsDataUpdated.data &&
        aiSettingsDataUpdated.data.strategy &&
        aiSettingsDataUpdated.data.strategy !== '') {

          selectedStrategy = aiSettingsDataUpdated.data.strategy

        var foundStrategyIdx = _.findIndex(strategyList, { strategy: aiSettingsDataUpdated.data.strategy });
        if (foundStrategyIdx > -1) {
          const foundStrategy = strategyList[foundStrategyIdx]
          setAiStrategySelected(foundStrategy)
          // listen to aiStrategySelected in AiQuestionPanel useEffect
          // setAiStrategy(aiSettingsDataUpdated[selectedStrategy])
        }
      }

      setInit(false)
    }
    // if (map) {
    //   updateBounds(map)
    //   // handleRecenterMapReset()
    // }
  }, [init])


  useEffect(() => {
    // triggered from AiSettingsPanel fetchAiSettingsData
    if (aiModelSelectedRefresh === true) {
      // set menu
      // find aiSettingsDataUpdated
      let selectedModel = ''
      if (
        aiSettingsDataUpdated &&
        aiSettingsDataUpdated.data &&
        aiSettingsDataUpdated.data.model &&
        aiSettingsDataUpdated.data.model !== '') {

        selectedModel = aiSettingsDataUpdated.data.model

        var foundIdx = _.findIndex(modelList, { model: aiSettingsDataUpdated.data.model });
        if (foundIdx > -1) {
          const foundModel = modelList[foundIdx]
          setAiModelSelected(foundModel)
          setAiModel(aiSettingsDataUpdated[selectedModel])
        }
      }

      let selectedStrategy = ''
      if (
        aiSettingsDataUpdated &&
        aiSettingsDataUpdated.data &&
        aiSettingsDataUpdated.data.strategy &&
        aiSettingsDataUpdated.data.strategy !== '') {

          selectedStrategy = aiSettingsDataUpdated.data.strategy

        var foundStrategyIdx = _.findIndex(strategyList, { strategy: aiSettingsDataUpdated.data.strategy });
        if (foundStrategyIdx > -1) {
          const foundStrategy = strategyList[foundStrategyIdx]
          setAiStrategySelected(foundStrategy)
          // listen to aiStrategySelected in AiQuestionPanel useEffect
          // setAiStrategy(aiSettingsDataUpdated[selectedStrategy])
        }
      }
      setAiModelSelectedRefresh(false)
    }
  }, [aiModelSelectedRefresh])


  // NOT USED YET
  useEffect(() => {

    if (!init) {
      // const test1 = aiModelSelected
      // const test2 = aiModel
      // const test3 = aiSettingsDataUpdated
      // const test4 = aiSettingsData
      // compare aiSettingsDataUpdated to aiSettingsData
      // debugger
      // get selected models in xxx.data
      let aiSettingsDataModel = ''
      if (aiSettingsData && aiSettingsData.data && aiSettingsData.data.model && aiSettingsData.data.model !== '') {
        aiSettingsDataModel = aiSettingsData.data.model
      }
      let aiSettingsDataUpdatedModel = ''
      if (aiSettingsDataUpdated && aiSettingsDataUpdated.data && aiSettingsDataUpdated.data.model && aiSettingsDataUpdated.data.model !== '') {
        aiSettingsDataUpdatedModel = aiSettingsDataUpdated.data.model
      }

      // selected model matches aiSettingsData
      if (
        aiSettingsDataModel !== '' &&
        aiSettingsDataUpdatedModel !== '' &&
        aiSettingsDataUpdatedModel === aiSettingsDataModel
      ) {

        // compare aiSettingsDataUpdated data to aiSettingsData data

        // debugger
      }
    }


  }, [aiSettingsDataUpdated])


  const cleanModelString = (val) => {
    // var regexPattern = /[^A-Za-z0-9]/g;
    const cleanedString = val.replace(/[^0-9a-z]/gi, '')
    return cleanedString

  }

  const handleOnChangeStrategy = (event) => {
    const val = event.target.value;
    var foundIdx = _.findIndex(strategyList, { key: val });
    if (foundIdx > -1) {
      const foundStrategy = strategyList[foundIdx]
      setAiStrategySelected(foundStrategy)

      // listen to aiStrategySelected in AiQuestionPanel useEffect
      // let selected = {}
      // if (aiSettingsDataUpdated && aiSettingsDataUpdated[foundStrategy.strategy]) {
      //   selected = aiSettingsDataUpdated[foundStrategy.strategy]
      // }
      // setAiStrategy(selected)

    }
  }

  const getSelectedStrategyKey = () => {
    let selectedStrategy = ''
    if (
      aiStrategySelected &&
      aiStrategySelected.key &&
      aiStrategySelected.key !== '') {
      selectedStrategy = aiStrategySelected.key
    }

    return selectedStrategy
  }

  const handleOnChangeModel = (event) => {
    const val = event.target.value;
    var foundIdx = _.findIndex(modelList, { key: val });
    if (foundIdx > -1) {
      const foundModel = modelList[foundIdx]
      setAiModelSelected(foundModel)
      let selected = {}
      if (aiSettingsDataUpdated && aiSettingsDataUpdated[foundModel.model]) {
        selected = aiSettingsDataUpdated[foundModel.model]
      }
      setAiModel(selected)
    }
  }

  const getSelectedModelKey = () => {
    let selectedModel = ''
    if (
      aiModelSelected &&
      aiModelSelected.key &&
      aiModelSelected.key !== '') {
      selectedModel = aiModelSelected.key
    }

    return selectedModel
  }


  const valuetext = (value) => {
    // return `${value}°C`;
    return value;
  }


  const handleOnChangeCommitted = (modelType) => {
    // console.log('newValue:', newValue)
    // console.log('model:', model)
    // console.log('handleOnChangeCommitted:', aiSettingsDataUpdated)

    let key = ''
    if (modelType && modelType !== '') {
      key = modelType
    }

    if (key !== '') {
      // update aiSettingsDataUpdated model
      setAiSettingsDataUpdated({
        ...aiSettingsDataUpdated,
        [key]: aiModel
      })
    }
  }
  //  const handleOnChangeCommitted = (event, newValue, model, parameter) => {
  //    // console.log('newValue:', newValue)
  //    // console.log('model:', model)
  //    // console.log('parameter:', parameter)
  //
  //    let modelData = {}
  //    if (aiSettingsDataUpdated &&
  //      aiSettingsDataUpdated[model]
  //    ) {
  //      modelData = aiSettingsDataUpdated[model]
  //      // clone selected model data
  //      let modelDataClone = _.cloneDeep(modelData)
  //      // update clone model parameter
  //      modelDataClone[parameter] = newValue
  //      // update aiSettingsDataUpdated state
  //      setAiSettingsDataUpdated({
  //        ...aiSettingsDataUpdated,
  //        [model]: modelDataClone
  //      })
  //    }
  //  };

  const handleSliderChange = (event, newValue, parameter) => {
    // console.log('newValue:', newValue)
    // console.log('model:', model)
    // console.log('parameter:', parameter)

    if (aiModel && aiModel[parameter] !== undefined) {
      setAiModel({
        ...aiModel,
        [parameter]: newValue
      })
    }

  };

  const handleSliderChangeOld = (event, newValue, model, parameter) => {
    // console.log('newValue:', newValue)
    // console.log('model:', model)
    // console.log('parameter:', parameter)

    let modelData = {}
    if (aiSettingsDataUpdated &&
      aiSettingsDataUpdated[model]
    ) {
      modelData = aiSettingsDataUpdated[model]
      // clone selected model data
      let modelDataClone = _.cloneDeep(modelData)
      // update clone model parameter
      modelDataClone[parameter] = newValue
      // update aiSettingsDataUpdated state
      setAiSettingsDataUpdated({
        ...aiSettingsDataUpdated,
        [model]: modelDataClone
      })
    }

  };

  const getSliderValueOld = (model, parameter) => {
    // console.log('model:', model)
    // console.log('parameter:', parameter)
    // BT: might need to send default value as well in params
    let returnVal = 0;
    let modelData = {}

    if (aiSettingsDataUpdated &&
      aiSettingsDataUpdated[model]
    ) {
      modelData = aiSettingsDataUpdated[model]
      if (modelData && modelData[parameter]) {
        returnVal = modelData[parameter]
      }
    }
    return returnVal
  }

  const getSliderValue = (parameter) => {
    // console.log('aiModel:', aiModel)
    // console.log('parameter:', parameter)
    // BT: might need to send default value as well in params
    let returnVal = 0;
    let modelData = {}
    if (aiModel &&
      aiModel[parameter] !== undefined
    ) {
      returnVal = aiModel[parameter]
    }
    return returnVal
  }

  const handleOnClickShowState = () => {
    //console.log('aiSettingsDataUpdated:', aiSettingsDataUpdated);
    console.log('aiModel:', aiModel);
  }

  const showModelOptions = () => {

    let selectedModel = ''
    if (
      aiModelSelected &&
      aiModelSelected.model &&
      aiModelSelected.model !== ''
    ) {
      selectedModel = aiModelSelected.model
    }



    if (selectedModel === 'gpt-3.5-turbo') {

      // temperature: 0.7 - Defaults to 1
      // maxlength: 256
      // top_p: 1 - Defaults to 1
      // frequency_penalty: 0 - Defaults to 0
      // presence_penalty: 0 - Defaults to 0

      return (
        <Box sx={{ mt: 2 }}>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              // p: 4
            }}
          >
            <Box flexGrow={1}>
              <Typography>Temperature</Typography>
            </Box>
            <Box>
              {
                // <Typography>{getSliderValueOld('gpt-3.5-turbo', 'temperature')}</Typography>
              }
              <Typography>{getSliderValue('temperature')}</Typography>
            </Box>
          </Box>


          <Slider
            // sx={{ mt: 2.5 }}
            aria-label="Always visible"
            // defaultValue={1}
            // getAriaValueText={valuetext}
            getAriaValueText={valuetext}
            step={0.1}
            min={0}
            max={2}

            // value={getSliderValueOld('gpt-3.5-turbo', 'temperature')}
            // onChange={(event, newValue) => handleSliderChangeOld(event, newValue, 'gpt-3.5-turbo', 'temperature')}

            value={getSliderValue('temperature')}
            onChange={(event, newValue) => handleSliderChange(event, newValue, 'temperature')}


            onChangeCommitted={() => handleOnChangeCommitted('gpt-3.5-turbo')}
          // valueLabelDisplay="on"
          // valueLabelDisplay="auto"
          />


          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              // p: 4
            }}
          >
            <Box flexGrow={1}>
              <Typography>Max Length</Typography>
            </Box>
            <Box>

              {
                // <Typography>{getSliderValueOld('gpt-3.5-turbo', 'maxlength')}</Typography>
              }
              {
                <Typography>{getSliderValue('maxlength')}</Typography>
              }
            </Box>
          </Box>

          <Slider
            // sx={{ mt: 2.5 }}
            aria-label="Always visible"
            // defaultValue={256}
            getAriaValueText={valuetext}
            step={1}
            min={0}
            max={1000}

            // value={getSliderValueOld('gpt-3.5-turbo', 'maxlength')}
            // onChange={(event, newValue) => handleSliderChangeOld(event, newValue, 'gpt-3.5-turbo', 'maxlength')}

            value={getSliderValue('maxlength')}
            onChange={(event, newValue) => handleSliderChange(event, newValue, 'maxlength')}

            onChangeCommitted={() => handleOnChangeCommitted('gpt-3.5-turbo')}
          // onChangeCommitted={(event, newValue) => handleOnChangeCommitted(event, newValue, 'gpt-3.5-turbo', 'maxlength')}
          // valueLabelDisplay="on"
          // valueLabelDisplay="auto"
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              // p: 4
            }}
          >
            <Box flexGrow={1}>
              <Typography>Top P</Typography>
            </Box>
            <Box>
              {
                // <Typography>{getSliderValueOld('gpt-3.5-turbo', 'top_p')}</Typography>
              }
              <Typography>{getSliderValue('top_p')}</Typography>
            </Box>
          </Box>

          <Slider
            // sx={{ mt: 2.5 }}
            aria-label="Always visible"
            // defaultValue={1}
            // getAriaValueText={valuetext}
            getAriaValueText={valuetext}
            step={0.1}
            min={0}
            max={1}
            // value={getSliderValueOld('gpt-3.5-turbo', 'top_p')}
            // onChange={(event, newValue) => handleSliderChangeOld(event, newValue, 'gpt-3.5-turbo', 'top_p')}

            value={getSliderValue('top_p')}
            onChange={(event, newValue) => handleSliderChange(event, newValue, 'top_p')}

            onChangeCommitted={() => handleOnChangeCommitted('gpt-3.5-turbo')}
          // valueLabelDisplay="on"
          // valueLabelDisplay="auto"
          />


          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              // p: 4
            }}
          >
            <Box flexGrow={1}>
              <Typography>Frequency Penalty</Typography>
            </Box>
            <Box>
              {
                // <Typography>{getSliderValueOld('gpt-3.5-turbo', 'frequency_penalty')}</Typography>
              }
              <Typography>{getSliderValue('frequency_penalty')}</Typography>
            </Box>
          </Box>

          <Slider
            // sx={{ mt: 2.5 }}
            aria-label="Always visible"
            // defaultValue={0}
            // getAriaValueText={valuetext}
            getAriaValueText={valuetext}
            step={0.1}
            min={-2}
            max={2}

            // value={getSliderValueOld('gpt-3.5-turbo', 'frequency_penalty')}
            // onChange={(event, newValue) => handleSliderChangeOld(event, newValue, 'gpt-3.5-turbo', 'frequency_penalty')}

            value={getSliderValue('frequency_penalty')}
            onChange={(event, newValue) => handleSliderChange(event, newValue, 'frequency_penalty')}

            onChangeCommitted={() => handleOnChangeCommitted('gpt-3.5-turbo')}
          // valueLabelDisplay="on"
          // valueLabelDisplay="auto"
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              // p: 4
            }}
          >
            <Box flexGrow={1}>
              <Typography>Presence Penalty</Typography>
            </Box>
            <Box>
              {
                // <Typography>{getSliderValueOld('gpt-3.5-turbo', 'presence_penalty')}</Typography>
              }
              <Typography>{getSliderValue('presence_penalty')}</Typography>
            </Box>
          </Box>

          <Slider
            // sx={{ mt: 2.5 }}
            aria-label="Always visible"
            // defaultValue={0}
            // getAriaValueText={valuetext}
            getAriaValueText={valuetext}
            step={0.1}
            min={-2}
            max={2}

            // value={getSliderValue('gpt-3.5-turbo', 'presence_penalty')}
            // onChange={(event, newValue) => handleSliderChange(event, newValue, 'gpt-3.5-turbo', 'presence_penalty')}

            value={getSliderValue('presence_penalty')}
            onChange={(event, newValue) => handleSliderChange(event, newValue, 'presence_penalty')}

            onChangeCommitted={() => handleOnChangeCommitted('gpt-3.5-turbo')}
          // valueLabelDisplay="on"
          // valueLabelDisplay="auto"
          />

          <Button
            variant="contained"
            sx={{ mt: '4px' }}
            // disabled={loading}
            size="small"
            onClick={handleOnClickShowState}
          // disabled={isDisabled()}
          >
            Check State
          </Button>
        </Box>
      )
    }
    else if (selectedModel === 'gpt-3.5-turbo-0301') {
      return (
        <Typography>gpt-3.5-turbo-0301</Typography>
      )
    }
    else {
      return <></>
    }
    // return <Typography>Model Options</Typography>
    return <Typography>{selectedModel}</Typography>


  }
  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // }));

  return (
    <Box>

      <FormControl sx={{
        // m: 1,
        // minWidth: 120
        width: '100%'
      }}>
        <InputLabel>Strategy</InputLabel>
        <Select
          // id="demo-simple-select"
          // value={age}
          label="Strategy"
          // value={'gpt35turbo'}
          value={getSelectedStrategyKey()}

          // value={row.data && row.data.membership ? row.data.membership : ''}
          // value={getMembershipVal(row)}
          onChange={(event) => handleOnChangeStrategy(event)}
        >
          {
            strategyList.map((s, index) => {
              return (
                <MenuItem key={s.key} value={s.key}>{s.strategy}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>

      <FormControl sx={{
        mt: 3,
        // m: 1,
        // minWidth: 120
        width: '100%'
      }}>
        <InputLabel>Model</InputLabel>
        <Select
          // id="demo-simple-select"
          // value={age}
          label="Models"
          // value={'gpt35turbo'}
          value={getSelectedModelKey()}

          // value={row.data && row.data.membership ? row.data.membership : ''}
          // value={getMembershipVal(row)}
          onChange={(event) => handleOnChangeModel(event)}
        >
          {
            modelList.map((m, index) => {
              return (
                <MenuItem key={m.key} value={m.key}>{m.model}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>

      {showModelOptions()}

    </Box >
  );

};


export default React.memo(AiModelPanel)