import getSymbolFromCurrency from 'currency-symbol-map'

import _ from 'lodash';

const formatCurrencyNumber = (number, params) => {
  // let countryCode = 'en-US';
  // let currency = 'USD';
  let countryCode = 'en-GB';
  let currency = 'GBP';
  if (params) {
    if (params.countryCode) {
      countryCode = params.countryCode;
    }
    if (params.currency) {
      currency = params.currency;
    }
  }

  const formatter = new Intl.NumberFormat(countryCode, {
    style: 'currency',
    currency: currency,

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const val = formatter.format(number);

  return val;
};


const getCurrencySymbol = (currencyCode) => {
  // let countryCode = 'en-US';
  // let currency = 'USD';
  let currency = 'GBP';
  if (currencyCode && currencyCode !== '') {
    currency = currencyCode;
  }

  // getSymbolFromCurrency('GBP') //=> '£'
  // getSymbolFromCurrency('EUR') //=> '€'
  // getSymbolFromCurrency('USD') //=> '$'
  // getSymbolFromCurrency('NOT A VALID CODE') //=> undefined

  const val = getSymbolFromCurrency(currency)

  return val;
};





export {
  formatCurrencyNumber,
  getCurrencySymbol
}
