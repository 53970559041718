// import { firebase } from './code/firebase';
import React from 'react';
import ReactDOM from 'react-dom/client';
// import ReactDOM from 'react-dom';
import {
  //BrowserRouter
  MemoryRouter
} from 'react-router-dom';

// import CssBaseline from '@mui/material/CssBaseline';
// import { ThemeProvider } from '@mui/material/styles';
// import theme from './materialui/theme';

// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

console.log('create-react-app env:', process.env.NODE_ENV)
console.log('firefly project:', process.env.REACT_APP_ENV)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <MemoryRouter>
        <App />
    </MemoryRouter>
);


// root.render(
//   <React.StrictMode>
//     <MemoryRouter>
//         <App />
//     </MemoryRouter>
//   </React.StrictMode>
// );

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(const root = ReactDOM.createRoot(document.getElementById('root'));
//   <React.StrictMode>root.render(
//     <ThemeProvider theme={theme}>  <React.StrictMode>
//     {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}    <ThemeProvider theme={theme}>
//     <CssBaseline />    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
//     <App />    <CssBaseline />
//     </ThemeProvider>    <App />
//   </React.StrictMode>    </ThemeProvider>
// );  </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
