import PropTypes from 'prop-types';
import React from 'react';
// import _ from 'lodash';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Drawer,
  useMediaQuery,
} from '@mui/material';

import MainCard from '../components/MainCard';

function SettingsDrawerLoading(props) {
  const theme = useTheme();

  // const handleDrawerOpen = props.handleDrawerOpen
  const openSettingsDrawer = props.openSettingsDrawer
  const drawerWidth = props.drawerWidth

  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const drawerBG = theme.palette.mode === 'dark' ? 'dark.main' : 'white';

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        zIndex: { xs: 1100, lg: 0 },
        minHeight: `calc(100vh - 53px)`,
        height: `calc(100vh - 53px)`, // <- added to make scrollable
        overflow: 'scroll', // <- added to make scrollable
        '& .MuiDrawer-paper': {
          height: matchDownLG ? '100%' : 'auto',
          width: drawerWidth,
          boxSizing: 'border-box',
          position: 'relative',
          border: 'none'
        }
      }}
      // variant={matchDownLG ? 'temporary' : 'persistent'}
      variant={'persistent'}
      anchor="right"
      open={openSettingsDrawer}
      ModalProps={{ keepMounted: true }}
      // onClose={handleDrawerOpen}
    >
      {openSettingsDrawer && (
        <MainCard
          sx={{
            bgcolor: matchDownLG ? 'transparent' : drawerBG,
            borderRadius: '0px 0 0 0px',
            // borderRight: 'none'
            borderTop: 'none',
            borderBottom: 'none',
            minHeight: `calc(100vh - 53px)`,
            p: 2
          }}
          border={!matchDownLG}
          content={false}
        >

        </MainCard>
      )}
    </Drawer>
  );
}

SettingsDrawerLoading.propTypes = {
  // handleDrawerOpen: PropTypes.func,
  openSettingsDrawer: PropTypes.bool,
  drawerWidth: PropTypes.number
  // setUser: PropTypes.func
};

export default SettingsDrawerLoading;
