// import { LegendToggle } from '@mui/icons-material';
// import { letterSpacing } from '@mui/system';
import {
  doc,
  getDoc
} from 'firebase/firestore';

import {
  db
} from './firebase';
// import _ from 'lodash';

const getLocationData = async (userData, scanId) => {

  // let userId = ''
  // if (userData && userData.userId) {
  //   userId = userData.userId
  // }
  // let locationId = ''
  // if (userData && userData.locationId) {
  //   locationId = userData.locationId
  // }

  let returnData = {
    status: 200
  }

  try {
    if (scanId && scanId !== '') {

      const docRef = doc(db, 'locations', scanId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        returnData.data = docSnap.data()
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        returnData.status = 401
        returnData.error = 'No doc exists'
      }
    }

    return returnData

  } catch (err) {
    console.error(err);

    returnData.status = 400
    returnData.error = err

  }
}

const processGridItemScanDataForTable = (gridItemScanData, gridItemParams, userPlaceId, keywordsDocData) => {

  let returnData = []

  let userLocationFound = false

  const limit = 20

  // loop over gridItemScanData and build returnData
  //for (var i = 0; i < gridItemScanData.length; i++) {
  for (var i = 0; i < gridItemScanData.length; i++) {
    const item = gridItemScanData[i]
    const itemData = {}
    itemData.id = i + 1
    itemData.position = i + 1

    // Business Name
    let businessName = ''
    if (item.title) {
      businessName = item.title
    }
    itemData.businessName = businessName

    let place_id = ''
    if (item.place_id) {
      place_id = item.place_id
    }
    itemData.place_id = place_id

    let cid = ''
    if (item.cid) {
      cid = item.cid
    }
    itemData.cid = cid

    // lat
    let lat = null
    if (item.latitude) {
      lat = item.latitude
    }
    itemData.lat = lat

    // lng
    let lng = null
    if (item.latitude) {
      lng = item.longitude
    }
    itemData.lng = lng

    // Proximity
    let proximity = 0
    if (typeof itemData.lat === 'number' && typeof itemData.lng === 'number') {
      proximity = haversine_distance(itemData, gridItemParams.pos, gridItemParams)
    }
    itemData.proximity = proximity
    // itemData.proximity = parseInt(proximity)

    // Reviews (count)
    let reviews = 0
    if (item.rating && item.rating.votes_count) {
      reviews = item.rating.votes_count
    }
    itemData.reviews = reviews

    // Rating
    let rating = 0
    if (item.rating && item.rating.value) {
      rating = item.rating.value
    }
    itemData.rating = rating

    let userLocation = false
    if (place_id !== '' && userPlaceId === place_id) {
      userLocation = true
      userLocationFound = true
    }
    itemData.userLocation = userLocation

    if (i < limit || userLocation === true) {
      returnData.push(itemData)
    }
  }

  // add own location if not in 100 items
  if (userLocationFound === false) {
    // BT add location from passed in data, using keywordsDocData for until fetch from DataForSeo

    // let businessName = ''
    // if (item.title) {
      // businessName = item.title
    // }
    // itemData.businessName = businessName

    let userBusinessName = ''
    if (keywordsDocData.data && keywordsDocData.data.businessname) {
      userBusinessName = keywordsDocData.data.businessname
    }

    let userPlace_id = ''
    if (keywordsDocData.data && keywordsDocData.data.googleplaceid) {
      userPlace_id = keywordsDocData.data.googleplaceid
    }

    let userLat = null
    if (
      keywordsDocData &&
      keywordsDocData.data &&
      keywordsDocData.data.lat &&
      keywordsDocData.data.lat !== null
    ) {
      userLat = keywordsDocData.data.lat
    }

    // lng
    let userLng = null
    if (
      keywordsDocData &&
      keywordsDocData.data &&
      keywordsDocData.data.lng &&
      keywordsDocData.data.lng !== null
    ) {
      userLng = keywordsDocData.data.lng
    }

    let userProximity = 0
    if (userLat !== null && userLng !== null) {
      userProximity = haversine_distance(keywordsDocData.data, gridItemParams.pos, gridItemParams)
    }

    const ownData = {
      id: limit + 1,
      position: limit + 1,
      businessName: userBusinessName,
      place_id: userPlace_id,
      cid: 0,
      lat: userLat,
      lng: userLng,
      proximity: userProximity,
      reviews: 0,
      rating: 0,
      userLocation: true
    }

    returnData.push(ownData)

  }

  return returnData
}

const haversine_distance = (mk1, mk2, gridItemParams) => {
  var R = 3958.8; // Radius of the Earth in miles
  // var rlat1 = mk1.position.lat() * (Math.PI / 180); // Convert degrees to radians
  // var rlat2 = mk2.position.lat() * (Math.PI / 180); // Convert degrees to radians
  // var difflat = rlat2 - rlat1; // Radian difference (latitudes)
  // var difflon = (mk2.position.lng() - mk1.position.lng()) * (Math.PI / 180); // Radian difference (longitudes)

  var rlat1 = mk1.lat * (Math.PI / 180); // Convert degrees to radians
  var rlat2 = mk2.lat * (Math.PI / 180); // Convert degrees to radians
  var difflat = rlat2 - rlat1; // Radian difference (latitudes)
  var difflon = (mk2.lng - mk1.lng) * (Math.PI / 180); // Radian difference (longitudes)


  let d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));

  if (gridItemParams && gridItemParams.distanceunit && gridItemParams.distanceunit === 'meters') {
    d = d * 1.6
  }
  // var round = d.toFixed(2);

  return d;

}

export {
  getLocationData,
  processGridItemScanDataForTable
}
