import React, {
  useState,
  useEffect,
  // useCallback
  useContext
} from 'react'
import PropTypes from 'prop-types';

import {
  // getAuth,
  //signInWithEmailAndPassword,
  // signOut
} from 'firebase/auth';
// import { useAuthState } from 'react-firebase-hooks/auth';
import { AdminAuthContext } from '../helpers/AdminAuthProvider';

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebase } from '../code/firebase';
import { processKeywordsUserData } from '../code/ecomacy';

import _ from 'lodash';

import {
  useTheme,
  styled
} from '@mui/material/styles';

import {
  Box,
  Button,
  CircularProgress,
  Typography
  //Tab,
  // Tabs,
  // Grid
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


const AdminCustomersTab = (props) => {
  const theme = useTheme();

  const { adminUserData, setAdminUserData } = useContext(AdminAuthContext);
  const { adminMembershipsData, setAdminMembershipsData } = useContext(AdminAuthContext);
  const { customersData, setCustomersData } = useContext(AdminAuthContext);
  // const { customersEcData, setCustomersEcData } = useContext(AdminAuthContext);
  // const { customersDataSynced, setCustomersDataSynced } = useContext(AdminAuthContext);

  // const { customersDataOrigObj } = useContext(AdminAuthContext);
  const { customersDataOrig, setCustomersDataOrig } = useContext(AdminAuthContext);
  const { saveAdminCustomer } = useContext(AdminAuthContext);

  const [tabValue, setTabValue] = useState(1);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


  /*
    useEffect(() => {
      if (!_.isEmpty(keywordsDocData)) {
        fetchLocationData()
          // make sure to catch any error
          .catch(console.error);
      }

    }, [keywordsDocData])


    const fetchLocationData = useCallback(async () => {

      if (ecomacyUserData.loaded === false) {
        // ecomacy API location data
        // const returnData = await fetch('https://yourapi.com');
        const result = await getEcomacyLocationData(userData)

        if (result.status === 200) {
          ecomacyUserDataObj = {
            data: result.data,
            error: {},
            status: result.status,
            loaded: true
          }
          setEcomacyUserData(ecomacyUserDataObj)
        }
        else {
          console.log('Error: ', result.error)
          ecomacyUserDataObj = {
            data: {},
            error: result.error,
            status: result.status,
            loaded: true
          }
          setEcomacyUserData(ecomacyUserDataObj)
        }

      }
    }, [])


    useEffect(() => {
      if (ecomacyUserData.loaded === true) {
        // compare keywordsDocData with ecomacyUserData to make sure address matches etc
        const result = compareKeywordsEcomacyData(ecomacyUserData, keywordsDocData)

        // temp data
        // result.match = false

        setDataMatch(result)
        setDataMatchProcessed(true)
      }

    }, [ecomacyUserData])
  */


  //  if (error) {
  //    return (
  //      <LoadingPane
  //        text={'Error: ' + JSON.stringify(error)}
  //        showSpinner={false}
  //      />
  //    )
  //  }
  //  if (loading) {
  //    return (
  //      <LoadingPane
  //        text={'Loading Keywords...'}
  //        showSpinner={true}
  //        progress={50}
  //
  //      />
  //    )
  //  }





  function preventDefault(event) {
    event.preventDefault();
  }


  /*
  const handleEnabledSwitch = (item, index) => {

    let fullData = customersData.data
    console.log('fullData[index] before', fullData[index])

    // update item
    fullData[index].enabled = !item.enabled
    console.log('fullData[index] after', fullData[index])
    setCustomersData({
      ...customersData,
      data: fullData
    })

  }
*/

  const handleEnabledSwitch = (item, itemId) => {

    // let fullData = customersData.data
    // console.log('fullData[index] before', fullData[index])


    // let dataItem = _.cloneDeep(customersDataOrig[itemId])
    let dataItem = customersDataOrig[itemId]

    // update item
    dataItem.enabled = !item.enabled

    // console.log('fullData[index] after', fullData[index])

    setCustomersDataOrig({
      ...customersDataOrig,
      [itemId]: dataItem
    })

  }


  const getMembershipValOLD = (row) => {

    let returnVal = ''
    let membershipMonthly = null
    if (row && row.data && row.data.monthly) {
      membershipMonthly = row.data.monthly
    }

    let memberships = []
    if (adminUserData && adminUserData.userDoc && adminUserData.userDoc.memberships) {
      memberships = adminUserData.userDoc.memberships
    }

    if (memberships.length > 0 && membershipMonthly) {
      // find in array where membershipMonthly === monthly
      var found = _.findIndex(memberships, { monthly: membershipMonthly });
      if (found > -1) {
        returnVal = found
      }
    }

    return returnVal;
  }

  const getMembershipVal = (row) => {

    let returnVal = ''
    let membershipId = ''
    if (row && row.data && row.data.membershipid) {
      membershipId = row.data.membershipid
    }

    let memberships = []
    if (adminMembershipsData && adminMembershipsData.length > 0) {
      memberships = adminMembershipsData
    }

    if (memberships.length > 0 && membershipId !== '') {
      // find in array where membershipMonthly === monthly
      var found = _.findIndex(memberships, { membershipid: membershipId});
      if (found > -1) {
        returnVal = found
      }
    }

    return returnVal;
  }

  const handleOnChangeMembership = (event, item, itemId) => {

    console.log('event:', event.target.value)
    console.log('item:', item)
    console.log('index:', itemId)
    // let fullData = customersData.data

    let memberships = []
    if (adminMembershipsData && adminMembershipsData.length > 0) {
      memberships = adminMembershipsData
    }

    // update item
    const val = event.target.value

    let selectedMembership = null
    if (memberships && memberships[val]) {
      selectedMembership = memberships[val]
    }

    // update item
    // let dataItem = _.cloneDeep(customersDataOrig[itemId])
    let dataItem = customersDataOrig[itemId]


    // init data if it doesn't exist
    if (!dataItem.data) {
      dataItem.data = {}
    }

    // set val
    dataItem.data = {
      ...dataItem.data,
      ...selectedMembership
    }

    setCustomersDataOrig({
      ...customersDataOrig,
      [itemId]: dataItem
    })

  }

  const handleOnChangeMultiple = (event, item, itemId) => {

    console.log('event:', event.target.value)
    console.log('item:', item)
    console.log('index:', itemId)
    // let fullData = customersData.data

    // update item
    const val = event.target.value

    // update item
    // let dataItem = _.cloneDeep(customersDataOrig[itemId])
    let dataItem = customersDataOrig[itemId]


    // init data if it doesn't exist
    if (!dataItem.data) {
      dataItem.data = {}
    }

    // set val
    dataItem.data.multiple = event.target.value

    // setCustomersData({
    //   ...customersData,
    //   data: fullData
    // })

    setCustomersDataOrig({
      ...customersDataOrig,
      [itemId]: dataItem
    })

  }

  /*
    const handleOnChangeBusinessName = (event, item, index) => {

      console.log('event:', event.target.value)
      console.log('item:', item)
      console.log('index:', index)

      const val = event.target.value
      let fullData = _.cloneDeep(customersData.data)

      // update item
      let dataItem = fullData[index]

      // init data if it doesn't exist
      // if (!dataItem.data) {
      //   dataItem.data = {}
      // }

      // set val
      dataItem.businessname = val

      setCustomersData({
        ...customersData,
        data: fullData
      })

    }
  */

  const handleOnChangeBusinessName = (event, item, itemId) => {

    console.log('event:', event.target.value)
    console.log('item:', item)
    console.log('index:', itemId)

    const val = event.target.value
    // let fullData = _.cloneDeep(customersData.data)

    // update item
    // let dataItem = _.cloneDeep(customersDataOrig[itemId])
    let dataItem = customersDataOrig[itemId]

    // set val
    dataItem.businessname = val

    setCustomersDataOrig({
      ...customersDataOrig,
      [itemId]: dataItem
    })

  }

  const SaveCancelButtons = (saveCancelButtonProps) => {

    const { row, index, itemId } = saveCancelButtonProps

    // console.log('itemId:', itemId)

    const [saving, setSaving] = useState(false)

    const handleSave = async () => {

      try {
        setSaving(true)

        const res = await saveAdminCustomer(row)
        console.log('save index:', index)
        console.log('save row:', row)
        // update customersDataOrig.current[i] to customersData.data[i]
        setSaving(false)

      } catch (err) {
        console.error(err);
        setSaving(false)
        return {
          error: err
        }

      }

    }




    const handleShowRef = () => {
      console.log('showref itemId:', itemId)

      /*
            let fullData = customersData.data
            const fullDataItem = fullData[index]
            const fullDataItemId = fullDataItem.id
            // update item
            // let dataItem = fullData[index]

            // replace item

            // const test = fullData[index]
            // const test2 = customersDataOrig[index]

            // set customersData.data[i] to customersDataOrig[id]

            if (fullDataItemId && fullDataItemId !== '') {
              if (customersDataOrig[fullDataItemId]) {
                // fullData[index] = customersDataOrig[fullDataItemId]
                // debugger
                console.log('--------------------------------')
                console.log('XXX fullData:', customersData)
                console.log('XXX fullData[index]:', fullData[index])
                console.log('XXX customersDataOrig[fullDataItemId]:', customersDataOrig[fullDataItemId])
                console.log('--------------------------------')
              }
            }
      */


      if (customersData && customersData.data) {
        // look for itemId in customersData.data array
        const customersDataItem = _.find(customersData.data, { id: itemId });
        console.log('YYY customersData.data:', customersData.data)
        console.log('YYY customersDataItem:', customersDataItem)
        console.log('YYY customersDataOrig[itemId]:', customersDataOrig[itemId])

      }
    }

    /*
        const handleCancel = () => {
          console.log('cancel index:', index)

          let fullData = _.cloneDeep(customersData.data)
          const fullDataItem = fullData[index]
          const fullDataItemId = fullDataItem.id
          // update item
          // let dataItem = fullData[index]

          // replace item

          // const test = fullData[index]
          // const test2 = customersDataOrig[index]

          // set customersData.data[i] to customersDataOrig[id]

          if (fullDataItemId && fullDataItemId !== '') {
            if (customersDataOrig[fullDataItemId]) {
              const clone = _.cloneDeep(customersDataOrig[fullDataItemId])
              fullData[index] = clone
              // debugger
              console.log('--------------------------------')
              console.log('XXX customersDataOrig[fullDataItemId]:', customersDataOrig[fullDataItemId])
              console.log('XXX fullData[index]:', fullData[index])
              console.log('--------------------------------')
            }
          }

          setCustomersData({
            ...customersData,
            data: fullData
          })

        }
    */
    const handleCancel = () => {
      console.log('cancel index:', index)

      let fullData = _.cloneDeep(customersData.data)
      const fullDataItem = fullData[index]
      const fullDataItemId = fullDataItem.id
      // update item
      // let dataItem = fullData[index]

      // replace item

      // const test = fullData[index]
      // const test2 = customersDataOrig[index]

      // set customersData.data[i] to customersDataOrig[id]

      //      if (fullDataItemId && fullDataItemId !== '') {
      //        if (customersDataOrig[fullDataItemId]) {
      //          const clone = _.cloneDeep(customersDataOrig[fullDataItemId])
      //          fullData[index] = clone
      //          // debugger
      //          console.log('--------------------------------')
      //          console.log('XXX customersDataOrig[fullDataItemId]:', customersDataOrig[fullDataItemId])
      //          console.log('XXX fullData[index]:', fullData[index])
      //          console.log('--------------------------------')
      //        }
      //      }
      //
      //      setCustomersData({
      //        ...customersData,
      //        data: fullData
      //      })

      const rowId = row.id
      // if (index === 0) {
      //   debugger
      // }

      // find item in customersData
      if (customersData && customersData.data) {
        // look for itemId in customersData.data array
        const customersDataItem = _.find(customersData.data, { id: itemId });
        if (customersDataItem) {
          // clone item
          const cloneItem = _.cloneDeep(customersDataItem)
          // set state
          setCustomersDataOrig({
            ...customersDataOrig,
            [itemId]: cloneItem
          })
          // const isEqual = _.isEqual(row, customersDataItem)
          // disabled = isEqual
        }
      }

    }


    const isDisabled = () => {

      let disabled = true
      const test = row
      const test1 = customersDataOrig[itemId]

      const rowId = row.id
      // if (index === 0) {
      //   debugger
      // }

      // find item in customersData
      if (customersData && customersData.data) {
        // look for itemId in customersData.data array
        const customersDataItem = _.find(customersData.data, { id: itemId });
        if (customersDataItem) {
          const isEqual = _.isEqual(row, customersDataItem)
          disabled = isEqual
        }
      }

      if (saving) {
        disabled = true
      }

      return disabled

    }


    return (
      <Box
        sx={{
          display: 'flex',
          // flexDirection: 'row',
          justifyContent: 'flex-end',
          // alignItems: 'center',
        }}
      >
        {
          //          <Button
          //            // variant="contained"
          //            variant="outlined"
          //            color="secondary"
          //            size="small"
          //            onClick={handleShowRef}
          //            sx={{ mr: 2 }}
          //          >
          //            Show ref
          //          </Button>
        }



        <Button
          // variant="contained"
          variant="outlined"
          color="secondary"
          size="small"
          onClick={handleCancel}
          disabled={isDisabled()}
          sx={{ mr: 2 }}
        >
          Cancel
        </Button>



        <Box sx={{ position: 'relative' }}>
          <Button
            variant="contained"
            // sx={buttonSx}
            // disabled={loading}
            size="small"
            onClick={handleSave}
            disabled={isDisabled()}
          >
            Save
          </Button>
          {saving && (
            <CircularProgress
              size={20}
              sx={{
                // color: green[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-10px',
                marginLeft: '-10px',
              }}
            />
          )}
        </Box>

        {
          //  <Button
          //    variant="contained"
          //    // variant="outlined"
          //    color="primary"
          //    size="small"
          //    onClick={handleSave}
          //    disabled={isDisabled()}
          //  >
          //    Save
          //  </Button>
        }


      </Box >
    )

  }

  console.log('customersData.data:', customersData.data);
  console.log('customersDataOrig:', customersDataOrig);

  return (

    <React.Fragment>

      <Typography component="h2" variant="h2" color="primary" gutterBottom>
        Customers
      </Typography>

      <Table
      // size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell>Business</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Memberships</TableCell>
            <TableCell>Multiples</TableCell>
            <TableCell>Payment Method</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(customersDataOrig).map((itemId, i) => {
            const row = customersDataOrig[itemId]

            // customersData.data.map(function (row, i) {

            // build Row
            const businessName = row.businessname
            return (
              <TableRow key={i}>
                <TableCell>

                  <TextField
                    // margin="normal"
                    required
                    fullWidth
                    // id="email"
                    label="Business Name"
                    name="businessname"
                    autoComplete="businessname"
                    // autoFocus
                    value={row.businessname}
                    onChange={(text) => handleOnChangeBusinessName(text, row, itemId)}

                  />


                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }}
                      checked={row.enabled}
                      onChange={() => handleEnabledSwitch(row, itemId)}
                    // defaultChecked

                    />}
                    label=""
                  />
                </TableCell>
                <TableCell>
                  {
                    <FormControl sx={{
                      // m: 1,
                      minWidth: 120
                    }}>
                      <InputLabel>Membership</InputLabel>
                      <Select
                        // id="demo-simple-select"
                        // value={age}
                        label="Membership"
                        // onChange={handleChange}
                        // value={5}
                        // value={row.data && row.data.membership ? row.data.membership : ''}
                        value={getMembershipVal(row)}
                        onChange={(event) => handleOnChangeMembership(event, row, itemId)}
                      >

                        {adminMembershipsData && adminMembershipsData.map((m, index) => {
                          return (
                            <MenuItem key={'membership' + index} value={index}>{m.name}</MenuItem>
                          )
                        })}
                        {
                        // adminUserData && adminUserData.userDoc && adminUserData.userDoc.memberships && adminUserData.userDoc.memberships.map((m, index) => {
                        //   return (
                        //     <MenuItem key={'membership' + index} value={index}>{m.name}</MenuItem>
                        //   )
                        // })
                        }
                      </Select>
                    </FormControl>
                  }
                </TableCell>
                <TableCell>


                  {
                    <FormControl sx={{
                      // m: 1,
                      minWidth: 120
                    }}>
                      <InputLabel>Multiple</InputLabel>
                      <Select
                        // id="demo-simple-select"
                        // value={age}
                        label="Multiple"
                        // onChange={handleChange}
                        // value={5}
                        value={row.data && row.data.multiple ? row.data.multiple : ''}
                        onChange={(event) => handleOnChangeMultiple(event, row, itemId)}
                      >


                        <MenuItem value={1}>One</MenuItem>
                        <MenuItem value={5}>Five</MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>

                      </Select>
                    </FormControl>
                  }


                </TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell align="right">
                  <SaveCancelButtons
                    row={row}
                    index={i}
                    itemId={itemId}
                  />
                </TableCell>
              </TableRow>
            )
          })}


        </TableBody>
      </Table>

    </React.Fragment>

  );

};


export default React.memo(AdminCustomersTab)