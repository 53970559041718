import React, {
  useState,
  useEffect,
  useCallback
} from 'react'
// import PropTypes from 'prop-types';

import { useNavigate } from "react-router-dom";

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

import Backdrop from '@mui/material/Backdrop';
// import SaveIcon from '@mui/icons-material/Save';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import { useTheme } from '@mui/material/styles';
import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  // FormControlLabel,
  // InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,

} from '@mui/material';

import { getEcomacyLocationData } from '../code/ecomacy'
import { buildKeywordsData, saveSetupKeywordsData } from '../code/settings'

import {
  generateLatLng,
  // generateLatLngTest
} from '../code/googleMapsApi'
import KeywordsSetupGoogleMapPane from './KeywordsSetupGoogleMapPane'

// const style = {
//   // position: 'absolute' as 'absolute',
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: '100%',
//   maxWidth: 960,
//   // bgcolor: 'background.paper',
//   // border: '2px solid #000',
//   // boxShadow: 24,
//   pl: 4,
//   pr: 4
// };

// const innerStyle = {
//   width: '100%',
//   bgcolor: 'background.paper',
//   borderRadius: 1,
//   // border: '2px solid #000',
//   // boxShadow: 24,
//   p: 4,
// };

const KeywordsSetup = (props) => {

  const theme = useTheme();
  let navigate = useNavigate();


  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();
  const saveButtonSx = {
    ...(success && {
      bgcolor: theme.palette.success.main,
      '&:hover': {
        // bgcolor: theme.palette.success.dark,
        bgcolor: theme.palette.success.main,
      },
    }),
    // ml: 1,
    height: '40px',
    width: '100%',
    fontWeight: 700
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  /**
   * 'Enter your location'
   * yup.number Expected 0 arguments, but got 1 */
  const validationSchema = yup.object({
    location: yup.number().required('Location selection is required.')
  });

  const formik = useFormik({
    initialValues: {
      location: '-2'
    },
    validationSchema,
    handleChange: (value) => {
      console.log('XXX value ', value);
    },
    onSubmit: async (values) => {

      setSuccess(false);
      setLoading(true);

      let selectedLocation = {}
      if (markerData[formik.values.location]) {
        selectedLocation = markerData[formik.values.location]
      }

      const keywordsData = buildKeywordsData(userData, ecomacyUserData.data, selectedLocation)

      // eslint-disable-next-line no-unused-vars
      const result = await saveSetupKeywordsData(keywordsData)

      setSuccess(true);
      setLoading(false);

      if (result.status === 200) {
        // redirect to Settings
        navigate("../settings", { replace: true });
      }

      timer.current = window.setTimeout(() => {
        setSuccess(false);
      }, 3000);



      //
    }
  });



  let ecomacyUserDataObj = {
    data: {},
    status: 0,
    loaded: false,
  }
  const [ecomacyUserData, setEcomacyUserData] = useState(ecomacyUserDataObj);
  const [ecomacyLoaded, setEcomacyLoaded] = useState(false);

  const userData = props.userData
  const [markerData, setMarkerData] = useState([]);


  // const keywordsDataObj = {
  //   data: {
  //     lat: 51.289447,
  //     lng: -0.324479,
  //     website: "surreyhouseclearanceandremovals.co.uk",
  //     zoom: "21z"
  //   },
  //   eclocationid: "NG8AEc8nheLEMNYfo3dn",
  //   gridtemplateid: "CiQDgCfz9LkPniMjsrjG",
  //   id: "HwxnNn22f6hjIk7h2vbQ",
  //   keywords: [],
  //   schedule: {
  //     active: true,
  //     day: 1,
  //     hour: 10,
  //     interval: "weekly"
  //   },
  //   userid: "DzLUva5FNJWJ2iFJJjwoUknksu73"
  // }

  // const [keywordsData, setKeywordsData] = useState(keywordsDataObj);

  useEffect(() => {
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [])

  const fetchData = useCallback(async () => {

    if (ecomacyUserData.loaded === false) {
      // ecomacy API location data
      // const returnData = await fetch('https://yourapi.com');
      const result = await getEcomacyLocationData(userData)
      if (result.status === 200) {
        ecomacyUserDataObj = {
          data: result.data,
          error: {},
          status: result.status,
          loaded: true
        }
        setEcomacyUserData(ecomacyUserDataObj)
      }
      else {
        console.log('Error: ', result.error)
        ecomacyUserDataObj = {
          data: {},
          error: result.error,
          status: result.status,
          loaded: true
        }
        setEcomacyUserData(ecomacyUserDataObj)
      }

    }
  }, [])

  useEffect(() => {

    if (ecomacyUserData.status === 200 && ecomacyUserData.loaded === true) {
      fetchLatLngData()
        // make sure to catch any error
        .catch(console.error);
    }
  }, [ecomacyUserData])

  const fetchLatLngData = async () => {
    if (ecomacyUserData.status === 200 && ecomacyUserData.loaded === true) {
      // process data
      const data = ecomacyUserData.data
      // BT calculate lat lng
      const result = await generateLatLng(data)
      // const result = await generateLatLngTest(3)
      setMarkerData(result.data)

      // if no location is found then set location to -1
      if (result.data.length === 0) {
        formik.setFieldValue('location', -1)
      }

      // if only 1 location is found then set location to 0
      if (result.data.length === 1) {
        formik.setFieldValue('location', 0)
      }




      setEcomacyLoaded(true)

    }
  }




  const saveDisabled = () => {
    if (formik.values.location === '-2') {
      return true
    }
    else {
      if (loading) {
        return true
      }
      else {
        return false
      }
    }
  }

  // const [open, setOpen] = React.useState(true);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  // const handleClose = (event, reason) => {
  //   if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
  //     // Set 'open' to false, however you would do that with your particular code.
  //     setOpen(false);
  //   }
  // }
  // <Button onClick={handleOpen}>Open modal</Button>

  // if (ecomacyUserData.loaded === true) {
  //   console.log('ecomacyUserData.data', ecomacyUserData.data)
  // }



  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        // bgcolor: 'background.paper',
      }}
      open={true}
    // onClick={handleClose}
    >

      <Container
        maxWidth="xs"
        // maxWidth="sm"
        sx={{
          // color: theme.palette.primary.contrastText,
          borderRadius: 1,
          bgcolor: 'background.paper',
          // minHeight: `calc(100vh - 53px)`,
          // height: `calc(100vh - 53px)`,
          pt: 1,
          pb: 3,
          // minHeight: '800px',
          // height: '800px'
          minHeight: '663px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >

        {ecomacyLoaded === true && ecomacyUserData.status === 200 &&
          <form onSubmit={formik.handleSubmit}>

            <Grid container
              spacing={2}
              pt={2}
            // pl={1}
            // sx={{ pt: theme.spacing(4) }}
            >

              <Grid item xs={12} >
                <Stack spacing={1}>
                  <Typography
                    variant={'h4'}
                    align={'center'}
                  >
                    Confirm Your Business Location
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} >
                <Stack spacing={0}>
                  <Typography
                    variant={'h5'}
                    sx={{ fontWeight: 700 }}
                  >
                    Business Name:
                  </Typography>
                  <Typography>
                    {ecomacyUserData.data.name ? ecomacyUserData.data.name : ''}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} >
                <Stack spacing={0}>
                  <Typography
                    variant={'h5'}
                    sx={{ fontWeight: 700 }}
                  >
                    Street Address:
                  </Typography>
                  <Typography>
                    {ecomacyUserData.data.address ? ecomacyUserData.data.address : ''}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} >
                <Stack spacing={0}>
                  <Typography
                    variant={'h5'}
                    sx={{ fontWeight: 700 }}
                  >
                    City:
                  </Typography>
                  <Typography>
                    {ecomacyUserData.data.city ? ecomacyUserData.data.city : ''}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} >
                <Stack spacing={0}>
                  <Typography
                    variant={'h5'}
                    sx={{ fontWeight: 700 }}
                  >
                    State / Prov / Region:
                  </Typography>
                  <Typography>
                    {ecomacyUserData.data.state ? ecomacyUserData.data.state : ''}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} >
                <Stack spacing={0}>
                  <Typography
                    variant={'h5'}
                    sx={{ fontWeight: 700 }}
                  >
                    Zip / Postal code:
                  </Typography>
                  <Typography>
                    {ecomacyUserData.data.postalCode ? ecomacyUserData.data.postalCode : ''}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} >
                <Stack spacing={0}>
                  <Typography
                    variant={'h5'}
                    sx={{ fontWeight: 700 }}
                  >
                    Country:
                  </Typography>
                  <Typography>
                    {ecomacyUserData.data.country ? ecomacyUserData.data.country : ''}
                  </Typography>
                </Stack>
              </Grid>


              <Grid item xs={12} >
                <Stack spacing={0}>
                  <Typography
                    variant={'h5'}
                    sx={{ fontWeight: 700 }}
                  >
                    Website:
                  </Typography>
                  <Typography>
                    {ecomacyUserData.data.website ? ecomacyUserData.data.website : ''}
                  </Typography>
                </Stack>
              </Grid>


              <Grid item xs={12}>
                <Stack spacing={0}>
                  <Typography
                    variant={'h5'}
                    sx={{ fontWeight: 700 }}
                  >
                    Google Maps Location:
                  </Typography>
                  <FormControl sx={{ mt: 1, minWidth: 120 }}>
                    <Select
                      id="location"
                      name="location"
                      displayEmpty={false}
                      value={formik.values.location}
                      onChange={formik.handleChange}
                      disabled={markerData.length < 1 ? true : false}
                    >
                      {markerData.length > 1 && (
                        <MenuItem value="-2">
                          Select your location...
                        </MenuItem>
                      )}


                      {markerData.map((m, i) => {
                        return (
                          <MenuItem
                            key={"m" + i}
                            value={i}
                          >
                            {m.address}
                          </MenuItem>
                        )
                      }
                      )}

                    </Select>
                    {formik.errors.location && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {' '}
                        {formik.errors.location}{' '}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  sx={{
                    mt: 1
                  }}
                >


                  <Box id='saveButtonBox' sx={{
                    position: 'relative',
                    width: '100%'
                  }}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={saveButtonSx}
                      disabled={saveDisabled()}
                      // disabled={loading}
                      // onClick={handleSaveButtonClick}
                      startIcon={<ArrowCircleRightIcon />}
                    >
                      Get Started
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: theme.palette.success.main,
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    )}
                  </Box>

                </Stack>
              </Grid>



            </Grid>
          </form>
        }



        {ecomacyUserData.loaded === true && ecomacyUserData.status !== 200 &&
          <Box
            name={'ErrorBox'}
            sx={{
              display: 'flex',
              flexGrow: '1',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%'
            }}
          >

            {
              // <CircularProgress variant="determinate" value={100} />
            }
            <Box
              sx={{
                mt: 1,
                fontSize: '20px',
                fontWeight: 700,
                textAlign: 'center'
              }}
            >
              Error loading Location Data.
              <br />
              Please contact support.
            </Box>
          </Box>
        }

        {ecomacyUserData.loaded === false &&
          <Box
            name={'LoadingBox'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              flexGrow: '1'
            }}
          >

            <CircularProgress />

            <Box
              sx={{
                mt: 1,
                fontSize: '20px',
                fontWeight: 700
              }}
            >
              {'Loading Location Data...'}

              <KeywordsSetupGoogleMapPane
                showMap={false}
              // markerData={markerData}
              />
            </Box>
          </Box>
        }

      </Container>

    </Backdrop >
  )

  /*
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disableAutoFocus={true}
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: {
            backgroundColor: theme.palette.primary.dark,
            opacity: 0.8
          }
        }}
        disableEscapeKeyDown={true}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box sx={innerStyle}>
              <Typography id="transition-modal-title" variant="h6" component="h2">
                Text in a modal
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Modal>
    )
    */

}



export default React.memo(KeywordsSetup)