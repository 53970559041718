import React from 'react'
// import PropTypes from 'prop-types';
// import _ from 'lodash';
// import { useAuthState } from 'react-firebase-hooks/auth';

import {
  Box,
  Grid,
  useTheme,
  styled
} from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';

import SettingsDrawerLoading from './SettingsDrawerLoading'

const drawerWidth = 600;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shorter
  }),
  marginRight: `-${drawerWidth}px`,
  [theme.breakpoints.down('lg')]: {
    paddingRight: 0,
    marginRight: 0
  },
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shorter
    }),
    marginRight: 0
  })
}));


const SettingsPaneLoading = (props) => {
  const theme = useTheme();

  // const loading = props.loading
  const loadingProgress = props.loadingProgress
  const error = props.error

  return (
    <Box sx={{ display: 'flex' }}>
      <Main theme={theme} open={true}>
        <Grid container
          sx={{
            height: '100%',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >

          {error === '' ?
            <CircularProgress
              variant="determinate"
              value={loadingProgress}
            /> :
            <p>error</p>
          }

        </Grid>
      </Main>

      <SettingsDrawerLoading
        openSettingsDrawer={true}
        drawerWidth={drawerWidth}
      />
    </Box>
  )




};


export default React.memo(SettingsPaneLoading)