import React, {
  useState,
  useEffect,
  useContext,
  // useCallback
} from 'react'
import PropTypes from 'prop-types';

import {
  // getAuth,
  //signInWithEmailAndPassword,
  // signOut
} from 'firebase/auth';
// import { useAuthState } from 'react-firebase-hooks/auth';

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebase } from '../code/firebase';
import { processKeywordsUserData } from '../code/ecomacy';

import { AiAuthContext } from '../helpers/AiAuthProvider';

import _ from 'lodash';

import {
  useTheme,
  // styled
} from '@mui/material/styles';

import {
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
  Grid,
  Paper,
  Slider,
  TextField,
} from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import AddIcon from '@mui/icons-material/Add';

import Container from '@mui/material/Container';
import GridKeywordTimelinePane from './GridKeywordTimelinePane'
import LoadingPane from './LoadingPane'

// import MainCard from './MainCard';
// import { getDfsLocationData } from '../code/ecomacy'

// import './GridKeywordsPane.css'
// const auth = getAuth(firebase)

const modelList = [
  {
    key: 'gpt35turbo',
    model: 'gpt-3.5-turbo'
  },
  {
    key: 'gpt35turbo0301',
    model: 'gpt-3.5-turbo-0301'
  },
]

const AiQuestionsPanel = (props) => {
  const theme = useTheme();

  const { testVal, setTestVal } = useContext(AiAuthContext);
  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);
  const { aiSettingsDataUpdated, setAiSettingsDataUpdated } = useContext(AiAuthContext);
  const { aiModelSelected, setAiModelSelected } = useContext(AiAuthContext);
  const { aiStrategySelected, setAiStrategySelected } = useContext(AiAuthContext);
  const { aiStrategySelectedRefresh, setAiStrategySelectedRefresh } = useContext(AiAuthContext);

  const [init, setInit] = useState(false)

  const [strategy, setStrategy] = useState(null)

  // console.log('AiModelPanel aiSettingsData', aiSettingsData);

//  useEffect(() => {
//    if (init && aiStrategySelected) {
//      // set menu
//      // find aiSettingsDataUpdated
//      let selectedStrategyId = ''
//      if (aiStrategySelected.strategy && aiStrategySelected.strategy !== '') {
//        selectedStrategyId = aiStrategySelected.strategy
//      }
//
//      if (
//        selectedStrategyId !== '' &&
//        aiSettingsDataUpdated &&
//        aiSettingsDataUpdated[selectedStrategyId]
//      ) {
//
//        let strategyData = null
//        if (aiSettingsDataUpdated[selectedStrategyId]) {
//          strategyData = aiSettingsDataUpdated[selectedStrategyId]
//        }
//
//        if (strategyData) {
//          setStrategy(strategyData)
//        }
//      }
//      setInit(false)
//    }
//  }, [init, aiStrategySelected])



//  useEffect(() => {
//    if (!init && aiStrategySelected) {
//      //debugger
//      // set menu
//      // find aiSettingsDataUpdated
//      let selectedStrategyId = ''
//      if (aiStrategySelected.strategy && aiStrategySelected.strategy !== '') {
//        selectedStrategyId = aiStrategySelected.strategy
//      }
//      if (
//        selectedStrategyId !== ''
//      ) {
//        if (
//          aiSettingsDataUpdated &&
//          aiSettingsDataUpdated[selectedStrategyId]
//        ) {
//
//          let strategyData = null
//          if (aiSettingsDataUpdated[selectedStrategyId]) {
//            strategyData = aiSettingsDataUpdated[selectedStrategyId]
//          }
//
//          if (strategyData) {
//            setStrategy(strategyData)
//          }
//        }
//        else {
//          setStrategy(null)
//        }
//      }
//    }
//  }, [aiStrategySelected])

//  useEffect(() => {
//    // triggered from AiSettingsPanel fetchAiSettingsData
//    if (aiStrategySelectedRefresh === true) {
//      // set menu
//      // find aiSettingsDataUpdated
//      let selectedStrategyId = ''
//      if (aiStrategySelected.strategy && aiStrategySelected.strategy !== '') {
//        selectedStrategyId = aiStrategySelected.strategy
//      }
//
//      if (
//        selectedStrategyId !== '' &&
//        aiSettingsDataUpdated &&
//        aiSettingsDataUpdated[selectedStrategyId]
//      ) {
//
//        let strategyData = null
//        if (aiSettingsDataUpdated[selectedStrategyId]) {
//          strategyData = aiSettingsDataUpdated[selectedStrategyId]
//        }
//
//        if (strategyData) {
//          setStrategy(strategyData)
//        }
//      }
//
//      setAiStrategySelectedRefresh(false)
//    }
//  }, [aiStrategySelectedRefresh])



  // useEffect(() => {
  //   // compare strategy to aiSettingsDataUpdated[]
  //   if (!init) {
  //     let selectedStrategyId = ''
  //     if (aiStrategySelected.strategy && aiStrategySelected.strategy !== '') {
  //       selectedStrategyId = aiStrategySelected.strategy
  //     }
  //     debugger
  //   }
  // }, [strategy])

  const handleOnChangeCommitted = (modelType) => {
    // console.log('newValue:', newValue)
    // console.log('model:', model)
    // console.log('handleOnChangeCommitted:', aiSettingsDataUpdated)

    let key = ''
    if (modelType && modelType !== '') {
      key = modelType
    }

    // if (key !== '') {
    //   // update aiSettingsDataUpdated model
    //   setAiSettingsDataUpdated({
    //     ...aiSettingsDataUpdated,
    //     [key]: model
    //   })
    // }
  }


  const handleChange = (event, key) => {
    const val = event.target.value;

    let data = _.cloneDeep(aiSettingsDataUpdated[aiStrategySelected.strategy])
    data[key] = val


    console.log('handleChange val:', val)

    setAiSettingsDataUpdated({
      ...aiSettingsDataUpdated,
      [aiStrategySelected.strategy]: data
    })
    // setStrategy({
    //   ...strategy,
    //   [key]: val
    // })
  }


  if (
    aiSettingsDataUpdated &&
    aiStrategySelected &&
    aiStrategySelected.strategy &&
    aiStrategySelected.strategy !== '' &&
    aiSettingsDataUpdated[aiStrategySelected.strategy]) {
    return (
      <Box>
        <TextField
          id="objectives"
          sx={{
            mt: 2,
            '& .MuiOutlinedInput-input': {
              fontSize: '15px'
            }
          }}
          fullWidth
          label="Objectives"
          multiline
          rows={5}
          value={aiSettingsDataUpdated[aiStrategySelected.strategy].objectives}
          onChange={(event) => handleChange(event, 'objectives')}
        // defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text"
        />

        <TextField
          id="what"
          sx={{
            mt: 4,
            '& .MuiOutlinedInput-input': {
              fontSize: '15px'
            }
          }}
          fullWidth
          label="What"
          multiline
          rows={10}
          value={aiSettingsDataUpdated[aiStrategySelected.strategy].what}
          onChange={(event) => handleChange(event, 'what')}
        // defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text"
        />

        <TextField
          id="who"
          sx={{
            mt: 4,
            '& .MuiOutlinedInput-input': {
              fontSize: '15px'
            }
          }}
          fullWidth
          label="Who"
          multiline
          rows={10}
          value={aiSettingsDataUpdated[aiStrategySelected.strategy].who}
          onChange={(event) => handleChange(event, 'who')}
        // defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text"
        />
      </Box>
    )

  }

  if (init) {
    return (
      <LoadingPane />
    );
  }

  return (
    <></>
  );

};


export default React.memo(AiQuestionsPanel)