// import './App.css';
// import './GoogleMapPane.css';

import React, {
  useState,
  useEffect
} from 'react'
import _ from 'lodash';

// import { useTheme } from '@mui/material/styles';


// import {
//   getFirestore,
//   collection,
//   query,
//   where,
//   // orderBy
// } from 'firebase/firestore';
// import { useCollection } from 'react-firebase-hooks/firestore';
// import { firebase } from '../code/firebase';

// import {getLatLong, buildGridData} from '../code/googleMapsApi';

import {
  GoogleMap,
  // useGoogleMap,
  useJsApiLoader,
  MarkerF,
  // InfoWindow,
  // Circle,
  // OverlayView
} from '@react-google-maps/api';

// import { LatLng, computeOffset } from 'spherical-geometry-js';
import {
  Container,
  // Box
} from '@mui/material';
// import { useTheme } from '@mui/material/styles';

import moveArrows from '../assets/moveArrows.svg';
//import moveArrows from '../assets/moveArrows.svg';

// const googleMapGrids = require('../json/googleMapGrids.json')

// require('dotenv-flow').config();

const mapsApiKey = process.env.REACT_APP_MAPS_API

const containerStyle = {
  maxWidth: '100%',
  // height: '1200px'
  height: `calc(100vh - 53px)`,
};

// BT: need this passed in??


// const position = {
//   lat: 51.291727,
//   lng: -0.323387
// }

// 51.291727,-0.323387,12z

/*
const getLatLong = (centerPos, disUnit, col) => {

  const centerLatLong = new LatLng(centerPos.lat, centerPos.lng);

  let newLatLong = centerLatLong

  const ver = col.ver
  const verDirDisArray = ver.split('-')
  const verDir = verDirDisArray[0]
  const verMultiplier = parseInt(verDirDisArray[1])

  let verDegrees = 0
  let verCalc = false

  if ('n' === verDir) {
    verDegrees = 0
    verCalc = true
  }
  else if ('s' === verDir) {
    verDegrees = 180
    verCalc = true
  }

  // process ver
  if (verCalc) {
    const verDistance = disUnit * verMultiplier
    const verComputed = computeOffset(newLatLong, verDistance, verDegrees);
    newLatLong = verComputed
  }


  const hor = col.hor
  const horDirDisArray = hor.split('-')
  const horDir = horDirDisArray[0]
  const horMultiplier = parseInt(horDirDisArray[1])


  let horDegrees = 0
  let horCalc = false

  if ('e' === horDir) {
    horDegrees = 90
    horCalc = true
  }
  else if ('w' === horDir) {
    horDegrees = -90
    horCalc = true
  }

  // process ver
  if (horCalc) {
    const horDistance = disUnit * horMultiplier
    const horComputed = computeOffset(newLatLong, horDistance, horDegrees);
    newLatLong = horComputed
  }

  return newLatLong


}



const buildGridData = (data) => {

  const distanceUnit = data.distance
  const grid = data.gridTemplate

  const centerPos = {
    lat: data.lat,
    lng: data.lng
  };
  // centerPos = center

  console.log('centerPos:', centerPos)

  const returnData = []
  // const gridId = 'g' + gridNum

  let rowNum = -1
  let colNum = -1

  // const grid = grids[gridId]
  if (grid) {
    // loop over rows
    Object.keys(grid).forEach((keyName, keyIndex) => {

      const row = grid[keyName]
      rowNum = keyIndex + 1

      // loop over columns
      Object.keys(row).forEach((keyName, keyIndex) => {
        const col = row[keyName]
        colNum = keyIndex + 1

        const newLatLong = getLatLong(centerPos, distanceUnit, col)

        // const lat = getLat(centerPos, distanceUnit, col.hor)
        // const lng = getLong(centerPos, distanceUnit, col.ver)

        const data = {
          id: col.id,
          col: colNum,
          row: rowNum,
          lat: newLatLong.latitude,
          lng: newLatLong.longitude
        }

        // console.log(col.id + ' old lat:' + centerPos.lat)
        // console.log(col.id + ' new lat:' + lat)

        returnData.push(data)

      })

    })

  }



  // console.log(JSON.stringify(returnData));

  return returnData

}
*/

const SettingsGoogleMapPane = (props) => {
  // const theme = useTheme();

  const moveGridPosition = props.moveGridPosition
  const updateGridLatLng = props.updateGridLatLng
  const gridTemplateData = props.gridTemplateData
  // const gridData = buildGridData(gridTemplateData)
  // const gridData = gridTemplateData.grid

  // START: added this to make it the same as other map
  const gridDataObj = gridTemplateData.grid
  const [gridData, setGridData] = useState(gridDataObj);

  useEffect(() => {
    const gridDataObjLength = _.size(gridDataObj)
    if (gridDataObjLength > 0) {
      setGridData(gridDataObj)
    }
  }, [gridDataObj])
  // END: added this to make it the same as other map




  let middleNum = 0
  const gridDataLength = _.size(gridData)
  if (gridDataLength > 0) {
    middleNum = Math.floor(gridDataLength / 2) + 1
  }

  // let userData = {}
  // if (props.userData) {
  //   userData = props.userData
  // }

  const handleRecenterMapReset = props.handleRecenterMapReset

  // BT: Use lat/lng from user instead of gridtemplate??
  /*
  let lat = null
  if (userData.lat) {
    lat = userData.lat
  }

  let lng = null
  if (userData.lng) {
    lng = userData.lng
  }

  let center = {
    lat: null,
    lng: null
  };

  if (lat && lng) {
    center = {
      lat: lat,
      lng: lng
    };
  }
  */
  let center = {
    lat: null,
    lng: null
  };

  // use gridTemplateData as lat/lng is always up-to-date
  if (gridTemplateData.lat && gridTemplateData.lng) {
    center = {
      lat: gridTemplateData.lat,
      lng: gridTemplateData.lng
    };
  }

  let recenterMap = 0
  if (props.recenterMap) {
    recenterMap = props.recenterMap
  }

  useEffect(() => {
    const gridDataLengthCheck = _.size(gridData)
    if (gridDataLengthCheck > 0) {
      if (map) {
        updateBounds(map)
      }
    }

  }, [gridData])

  useEffect(() => {
    if (recenterMap > 0) {
      if (map) {
        updateBounds(map)
        handleRecenterMapReset()
      }
    }

  }, [recenterMap])


  // Add event listener
  const handleWindowResize = () => {
    //console.log('window resize')
    if (map) {
      updateBounds(map)
    }
  }
  window.addEventListener("resize", handleWindowResize);


  const updateBounds = (mapObj) => {

    const gridDataLengthCheck = _.size(gridData)
    if (gridDataLengthCheck > 0) {
      // const bounds = new window.google.maps.LatLngBounds(center);
      const bounds = new window.google.maps.LatLngBounds();
      for (var i = 0; i < gridDataLengthCheck; i++) {
        // if (markers[i].getVisible()) {
        const item = gridData[i]
        const pos = {
          lat: item.lat,
          lng: item.lng
        }
        bounds.extend(pos);
        // }
      }
      mapObj.fitBounds(bounds);

      setMap(mapObj)
    }
  }


  // const [markers, setMarkers] = React.useState([])

  const [dragMarker, setDragMarker] = useState(center);
  // const [showMarker, setShowMarker] = useState(true);

  // const [activeMarker, setActiveMarker] = useState(null);
  // const handleActiveMarker = (marker) => {
  //   if (marker === activeMarker) {
  //     return;
  //   }
  //   setActiveMarker(marker);
  // };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: mapsApiKey
  })

  // START: is this needed?

  // const mapExists = () => {
  //   if (map) {
  //     return map
  //   }
  //   else {
  //     return null
  //   }
  // }

  // const [map, setMap] = React.useState(mapExists())
  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(
    function callback(gData, mapObj) {

      const gDataLength = _.size(gData)
      if (gDataLength > 0) {
        updateBounds(mapObj, gData)
        // const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);
        // setMap(map)
      }
      else {
        setMap(mapObj)
      }
    }, []
  )


  // const onLoad = React.useCallback(
  //   function callback(mapObj) {
  //   const gridDataLengthCheck = _.size(gridData)
  //   if (gridDataLengthCheck > 0) {
  //     updateBounds(mapObj)
  //   }
  //   else {
  //     setMap(mapObj)
  //   }
  // }, [])


  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  // END: is this needed?

  const handleOnDragStart = (t) => {
    // console.log('latLng:', t.latLng.lat())
    // hide grid
    // setShowMarker(false)
  }

  const handleOnDrag = (t) => {
    // console.log('latLng:', t.latLng.lat())
  }

  const handleOnDragEnd = (t) => {
    // console.log('latLng:', t.latLng.lat())
    const newCenter = {
      lat: t.latLng.lat(),
      lng: t.latLng.lng()
    };
    setDragMarker(newCenter)
    // show grid
    // setShowMarker(true)

    // update lat lng in parent
    updateGridLatLng(newCenter)
  }

  const mapOptions = {
    // disableDefaultUI: true
    mapTypeControl: false,
    scaleControl: true,
    zoomControl: true,
    fullscreenControl: true,
    streetViewControl: false,
    keyboardShortcutsControl: false
  }


  if (gridDataLength > 0) {
    // gridDataArr = value.something??
    // var markerArr = []
    // console.log('========================:')


    return isLoaded ? (
      <Container id='SettingsGoogleMap' disableGutters maxWidth={false}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={12}
          // onLoad={onLoad}
          onLoad={onLoad.bind(this, gridData)}

          onUnmount={onUnmount}
          clickableIcons={false}
          options={mapOptions}
          mapTypeIds={'ROADMAP'}
        >
          {Object.keys(gridData).map((itemId, index) => {

            const item = gridData[itemId]

            const id = item.id
            const idInt = parseInt(id)

            let pos = {
              lat: item.lat,
              lng: item.lng
            }

            const labelParams = {
              // text: 'XX',
              text: ' ',
              color: 'white',
              fontSize: '18px',
              fontWeight: "bold"
            }

            // const image = "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";
            // const overlayOffset = {
            //   x: 200,
            //   y: 150
            // }

            if (moveGridPosition && idInt === middleNum) {
              return null
            } else {
              // console.log('pos:', pos)
              // console.log('MarkerId' , id)

              return (
                <MarkerF
                  key={id}
                  id={id}
                  position={pos}
                  label={labelParams}
                  // onMouseOver={() => handleActiveMarker(id)}
                  // onMouseOut={() => setActiveMarker(null)}
                  // icon={image}
                  icon={{
                    // path: "M10, 35 a 25, 25 0 1,0 50,0 a 25,25 0 1,0 -50,0",
                    path: "M-20,0 a 20,20 0 1,0 40,0 a 20,20 0 1,0 -40,0",
                    // fillColor: fillColor,
                    fillColor: 'rgba(128, 128, 128, 0.5)',
                    fillOpacity: 1,
                    scale: 1,
                    strokeWeight: 0,
                    zIndex: 999999999
                  }}
                >
                </MarkerF>
              )
            }

          })}

          {moveGridPosition &&
            <MarkerF
              position={dragMarker}
              draggable
              // label={labelParams}
              // onMouseOver={() => handleActiveMarker(id)}
              // onMouseOut={() => setActiveMarker(null)}
              onDragStart={(t) => handleOnDragStart(t)}
              onDrag={(t) => handleOnDrag(t)}
              onDragEnd={(t) => handleOnDragEnd(t)}
              // icon={moveArrows}
              icon={{
                // path: "M10, 35 a 25, 25 0 1,0 50,0 a 25,25 0 1,0 -50,0",
                // path: "M-20,0 a 20,20 0 1,0 40,0 a 20,20 0 1,0 -40,0",
                url: moveArrows,
                // fillColor: fillColor,
                //fillColor: theme.palette.primary.main,
                //fillOpacity: 0.5,
                // scale: 1,
                anchor: new window.google.maps.Point(20, 20),
                scaledSize: new window.google.maps.Size(40, 40),
                strokeWeight: 0,
                zIndex: 99999999
              }}
            >
            </MarkerF>

          }


        </GoogleMap>
      </Container>
    ) : <Container disableGutters maxWidth={false}></Container>



  }
  else {
    return (
      <Container maxWidth={false}>No grid data...</Container>
    )
  }





}

export default React.memo(SettingsGoogleMapPane)