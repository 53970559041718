import React, { createContext, useState, useRef } from 'react';
// import auth from '@react-native-firebase/auth';
import { getUserToken } from '../code/firebase'

import {
  getAuth,
  connectAuthEmulator,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut
} from "firebase/auth"

import {
  // collection,
  doc,
  setDoc,
  onSnapshot,
  // getDoc,
  getDocs,
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  limit
} from 'firebase/firestore'

import _ from 'lodash';

import { getFunctions, httpsCallable } from "firebase/functions"
import { useAuthState } from 'react-firebase-hooks/auth';

import { getUrlParams } from '../code/ecomacy'
import { firebase } from '../code/firebase';

const axios = require('axios')

const userData = getUrlParams()

let location_id = ''
if (userData.location_id !== '') {
  location_id = userData.location_id
}
let email = ''
if (userData.email !== '') {
  email = userData.email
}

const auth = getAuth(firebase)
const functions = getFunctions(firebase, 'europe-west1')
const db = getFirestore(firebase)

/**
 * This provider is created
 * to access user in admin area
 */

const initialState = {
  // login: () => {},
  // logout: () => {},
  // authenticate: () => {},
  // getUserToken: () => {},
};


export const CustomerAuthContext = createContext(initialState);
// export const AuthContext = createContext({} as ContextState);
// const Context = createContext({} as ContextState);

export const CustomerAuthProvider = ({ children }) => {
  // const [user, setUser] = useState(null);
  const [testVal, setTestVal] = useState('');


  const [customerUserData, setCustomerUserData] = useState({
    data: null,
    error: null,
    loaded: false,
  });

  const [customersData, setCustomersData] = useState({
    data: null,
    // dataOrig: null,
    error: null,
    loaded: false,
    // synced: false
  });

  const [user, loadingUser, errorUser] = useAuthState(auth);

  const [rawData, setRawData] = useState({
    customerData: null,
    accountStartTs: null,
    billingStartTs: null,
    tokensIncluded: null,
    tokensExtra: null,
    monthly: null,
    multiple: null,
    tokenPrice: null,
    runningTotal: null,
    tokensLeftThisMonth: null,
    allCustomerData: false,

    saveKeywords: null,
    keywordsCount: null,
    schedule: null,
    scheduleDay: null,
    scheduleHour: null,
    scheduleInterval: null,
    allKeywordsData: false,

    // saveGridTemplates: null,
    gridCount: null,
    allGridData: false
  })

  const rawDataRef = useRef({});

  const [tokenData, setTokenData] = useState({
    iterationsMonthly: null,
    tokensLeftThisMonth: null,
    tokensNeededSingle: null,
    totalSingle: null,
    tokensNeededMonthly: null,
    totalMonthly: null,
    tokensLeftAfterDeduction: null,
  })


  const getCustomerUserDoc = async (uid) => {
    try {
      let customerDoc = null
      let customerLogSearchDoc = null

      const qc = query(collection(db, "customers"), where("locationid", "==", uid));

      const snapshotCustomer = await getDocs(qc);

      if (snapshotCustomer.empty) {
        const err = {
          val: 'no User Doc found'
        }
        return {
          error: err
        }
      }

      snapshotCustomer.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        customerDoc = doc.data()
      });

      if (customerDoc) {
        if (!customerDoc.data) {
          customerDoc.data = {}
        }
        const qls = query(collection(
          db, "logsearches"),
          where("eclocationid", "==", uid),
          orderBy("created", "desc"),
          limit(1)
        );

        const snapshotLogSearch = await getDocs(qls);

        // if (snapshotLogSearch.empty) {
        // }

        snapshotLogSearch.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          customerLogSearchDoc = doc.data();
        });
      }

      if (customerLogSearchDoc && customerLogSearchDoc.runningtotal) {
        customerDoc.data.runningtotal = customerLogSearchDoc.runningtotal
      }

      return customerDoc

    } catch (err) {
      console.error(err);
      return {
        error: err
      }

    }
  }


  /*
  const getAdminCustomerDocs = async (uid) => {
    return new Promise((resolve, reject) => {

      try {

        let customersDataOrigObject = {}

        const q = query(collection(db, "customers"), where("userid", "==", uid));
        // let customerDocs = []
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let customerDocs = []
          // const cities = [];
          querySnapshot.forEach((doc) => {
            // customerDocs.push(doc.data().name);
            const docData = doc.data()
            customerDocs.push(doc.data());

            // let customerDocClone = _.cloneDeep(docData)

            // customersDataOrigObject[docData.id] = docData
            // if (docData.id === '0PmBJQrhaR4GHno2mR5e') {
            // debugger
            // }
            // customersDataOrigObject[docData.id] = customerDocClone
          });
          // console.log("Current cities in CA: ", cities.join(", "));

          setCustomersData({
            ...customersData,
            data: customerDocs,
            // dataOrig: customerDocs,
            loaded: true,
          })

          // create customersDataOrig
          //// setCustomersDataOrig(customersDataOrigObject)

          // ref for comparison
          // let customerDocsClone = _.cloneDeep(customerDocs)

          // debugger
          resolve({
            data: customerDocs
          })
        });

        // return unsubscribe

      } catch (err) {
        console.error(err);

        setCustomersData({
          ...customersData,
          error: err,
          loaded: true,
        })

        reject({
          error: err
        })

      }
    })
  }
  */

  const delay = (milliseconds) => new Promise(resolve => setTimeout(resolve, milliseconds))

  return (
    <CustomerAuthContext.Provider
      value={{
        testVal,
        setTestVal,
        customerUserData,
        setCustomerUserData,
        getCustomerUserData: async () => {

          try {
            let userDoc = {}
            if (user && user.uid) {
              // 1: Get UserDoc
              const userDoc = await getCustomerUserDoc(user.uid)
              if (userDoc && userDoc.error === undefined) {
                // user doc found
                setCustomerUserData({
                  ...customerUserData,
                  data: userDoc,
                  loaded: true,
                })

              }
              else if (userDoc && userDoc.error) {
                //
                setCustomerUserData({ ...customerUserData, loaded: true, error: userDoc.error })
              }
              else {
                // no doc found
                setCustomerUserData({
                  ...customerUserData, loaded: true, error: {
                    val: 'no doc found'
                  }
                })
              }
            }
          } catch (err) {
            console.error(err);
            // setAdminUserData({ ...adminUserData, loaded: true, error: err })
          }
        },
        rawData,
        setRawData,
        rawDataRef,
        tokenData,
        setTokenData,
      }}>
      {children}
    </CustomerAuthContext.Provider>
  );
};

// export default AdminAuthProvider;
