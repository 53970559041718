import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useTheme, alpha } from '@mui/material/styles';
// import { number } from 'yup';

const containerStyle = {
  width: '100%',
  height: '700px'
};

const mapsApiKey = process.env.REACT_APP_MAPS_API




const GridItemGoogleMap = (props) => {
  const theme = useTheme();

  const { tableData, gridItemParams } = props

  const center = gridItemParams.pos

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: mapsApiKey
  })

  // eslint-disable-next-line
  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    // setMap(map)
    const bounds = new window.google.maps.LatLngBounds();
    for (var i = 0; i < tableData.length; i++) {
      // if (markers[i].getVisible()) {
      const item = tableData[i]


      if (typeof item.lat === 'number' && typeof item.lng === 'number') {
        const pos = {
          lat: item.lat,
          lng: item.lng
        }
        bounds.extend(pos);
      }

      // }
    }
    map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])


  //  const updateBounds = (mapObj, gData = null) => {
  //    let gridDataObj = gridData
  //    if (gData) {
  //      gridDataObj = gData
  //    }
  //
  //    const gridDataObjLength = _.size(gridDataObj)
  //    if (gridDataObjLength > 0 && mapObj) {
  //      // const bounds = new window.google.maps.LatLngBounds(center);
  //      const bounds = new window.google.maps.LatLngBounds();
  //      for (var i = 0; i < gridDataObjLength; i++) {
  //        // if (markers[i].getVisible()) {
  //        const item = gridDataObj[i]
  //        const pos = {
  //          lat: item.lat,
  //          lng: item.lng
  //        }
  //        bounds.extend(pos);
  //        // }
  //      }
  //      mapObj.fitBounds(bounds);
  //
  //      setMap(mapObj)
  //    }
  //  }

  const mapOptions = {
    // disableDefaultUI: true
    mapTypeControl: false,
    scaleControl: true,
    zoomControl: true,
    fullscreenControl: true,
    streetViewControl: false,
    keyboardShortcutsControl: false,
    scrollwheel: false
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      clickableIcons={false}
      options={mapOptions}
      mapTypeIds={'ROADMAP'}

    >

      {tableData.map((item, index) => {
        // const item = tableData[itemId]
        const id = item.id

        let pos = {
          lat: item.lat,
          lng: item.lng
        }



        // process label
        const labelText = '' + id

        // grey 8c8c8c
        // const fillColor = 'rgba(140, 140, 140, 0.9)'
        let fillColor = alpha(theme.palette.primary.main, 0.9)
        if (item.userLocation === true) {
          fillColor = alpha(theme.palette.success.main, 0.9)
        }

        const labelParams = {
          text: labelText,
          color: 'white',
          fontSize: '14px',
          fontWeight: "bold"
        }

        if (typeof item.lat === 'number' && typeof item.lng === 'number') {
          return (
            <Marker
              key={id}
              id={'marker' + id}
              position={pos}
              label={labelParams}
              // icon={image}
              icon={{
                // path: "M-20,0 a 20,20 0 1,0 40,0 a 20,20 0 1,0 -40,0",
                path: "M-10,0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0",
                fillColor: fillColor,
                // fillColor: '#cc0000',
                fillOpacity: 1,
                scale: 1,
                strokeWeight: 0,
                zIndex: 99999999
              }}

            >

            </Marker>
          )
        }
        else {
          return null
        }

      })}
    </GoogleMap>
  ) : <></>
}

export default React.memo(GridItemGoogleMap)